<template>
  <Alert v-if="isFirstVerification">
    Vehicle activation is in progress. Please
    <router-link :to="routeProps">enter the current mileage</router-link> of your car to complete
    activation.
  </Alert>

  <Alert v-else>
    Mileage value needs to be updated in order to check you are in possession of the car. Please
    <router-link :to="routeProps">enter the current mileage value</router-link>.
  </Alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Alert } from '@/components/base';
import { RouteNames } from '@/router';

export default defineComponent({
  components: {
    Alert,
  },
  props: {
    isFirstVerification: {
      type: Boolean,
      default: true,
    },
    vehicleId: {
      type: String,
      required: true,
    },
  },
  computed: {
    routeProps() {
      return {
        name: RouteNames.VEHICLE_VERIFICATION,
        params: {
          vehicleId: this.vehicleId,
        },
      };
    },
  },
});
</script>
