<template>
  <header
    :class="{
      [$style.background]: hasBackground,
      [$style.header]: true,
    }"
    aria-role="banner"
  >
    <Container :class="$style['header-container']">
      <div>
        <div v-if="route.meta?.backButton" :class="$style['back-button-wrapper']">
          <BackButton :to="route.meta.backButton.getLink(route)" />
          <span>{{ route.meta.backButton.label }}</span>
        </div>

        <router-link v-else :to="{ name: dashboardRoute }" :class="$style.badge">
          <HMBadge />
        </router-link>
      </div>

      <template v-if="route.meta.carSelector !== false">
        <CarSelectorWrapper
          :buildPath="(vehicleId: string) => `/dashboard/${vehicleId}/apps`"
          :selected-vehicle-id="selectedVehicleId"
          :vehicles="vehicles.vehicles"
        />
      </template>

      <AccountDropdown
        :buildPath="(vehicleId: string) => `/dashboard/${vehicleId}/apps`"
        accountPath="/account"
        :class="$style.avatar"
        :name="users.user ? users.user.fullName : ''"
        :selected-vehicle-id="selectedVehicleId"
        :vehicles="vehicles.vehicles"
        @dropdown-visible="toggleScroll"
        @signout="signOut"
      />
    </Container>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router';

import { AccountDropdown, BackButton, Container, HMBadge } from '@/components/base';
import { RouteNames } from '@/router';
import { useEvents } from '@/components/composables';
import { useUserStore, useVehiclesStore } from '@/store';

import CarSelectorWrapper from './CarSelectorWrapper.vue';

export default defineComponent({
  components: {
    AccountDropdown,
    BackButton,
    CarSelectorWrapper,
    Container,
    HMBadge,
  },
  setup() {
    const hasBackground = ref(false);

    const toggleBackground = () => {
      const showBackground = window.scrollY > 0;
      if (showBackground !== hasBackground.value) {
        hasBackground.value = showBackground;
      }
    };

    useEvents(['scroll'])(toggleBackground);

    const users = useUserStore();
    const vehicles = useVehiclesStore();
    const route = useRoute() as RouteLocationNormalized & {
      meta: {
        backButton?: {
          label: string;
          getLink: (route: RouteLocationNormalized) => string;
        };
        carSelector?: boolean;
      };
    };
    const router = useRouter();

    return {
      dashboardRoute: RouteNames.DASHBOARD,
      hasBackground,
      toggleBackground,
      users,
      vehicles,
      route,
      router,
    };
  },
  computed: {
    selectedVehicleId(): string | undefined {
      return this.route.params.vehicleId as string | undefined;
    },
  },
  methods: {
    async signOut() {
      await this.users.logOut();
      this.router.push({ name: RouteNames.SIGN_IN });
    },
    toggleScroll(on: boolean) {
      document.body.classList[on ? 'add' : 'remove']('no-scroll');
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.header {
  display: flex;

  @include mixins.media(xs, max) {
    margin-bottom: vars.$header-margin-xs;

    &.no-avatar {
      .avatar {
        display: none;
      }
    }
  }

  @include mixins.media(sm) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: vars.$z-index;
    height: vars.$header-height;
    padding: 20px 0;

    &.background {
      background: rgba(61, 64, 69, 0.9);
    }
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mixins.media(xs, max) {
    z-index: vars.$z-index;
    padding: grid.$xs-grid-gap;
    padding-top: 17px;
  }

  @include mixins.media(sm) {
    .avatar {
      margin-left: 20px;
    }

    .badge {
      margin-right: 20px;
    }
  }

  > *:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  > *:nth-child(2):not(:last-child) {
    flex-basis: 100%;
    max-width: 530px;
    white-space: nowrap;
  }

  > *:nth-child(1),
  > *:nth-child(3) {
    flex-basis: 50%;
  }

  > *:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
}

.back-button-wrapper {
  display: flex;
  align-items: center;

  span {
    display: none;
    font-weight: 700;
    margin-left: 20px;

    @include mixins.media(lg) {
      display: inline;
    }
  }
}
</style>
