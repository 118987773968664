import axios from 'axios';
import { defineStore } from 'pinia';

import { camelizeKeys } from '@/utils';
import { User } from '@/types';

import { useToastsStore } from './toasts';

export interface UserState {
  user?: User;
}

export const useUserStore = defineStore('users', {
  state: (): UserState => {
    return {
      user: undefined,
    };
  },
  actions: {
    async activateUser(name: string, password: string, token: string) {
      return await axios.post('/api/users/activate', {
        full_name: name,
        password: password,
        token: token,
      });
    },

    async changePassword(current: string, next: string) {
      const toasts = useToastsStore();

      try {
        await axios.post('/api/users/change-password', {
          current_password: current,
          new_password: next,
        });

        toasts.showToast({
          id: 'change-password-success',
          message: 'Your password has been changed.',
          type: 'success',
        });
      } catch (e) {
        toasts.showToast({
          id: 'change-password-error',
          message: 'Password change failed, please try again later.',
          type: 'error',
        });
      }
    },

    async confirmEmail(token: string) {
      return await axios.post('/api/users/confirm-email', {
        token,
      });
    },

    async forgotPassword(email: string) {
      const toasts = useToastsStore();

      try {
        await axios.post('/api/users/forgot-password', { email });
      } catch (e) {
        toasts.showToast({
          id: 'forgot-password-error',
          message: 'Something went wrong!',
          type: 'error',
        });

        throw e;
      }
    },

    async logIn(email: string, password: string) {
      try {
        const { data } = await axios({
          method: 'post',
          url: `/api/users/login`,
          data: {
            email,
            password,
          },
        });

        this.setUser(camelizeKeys(data) as User);
      } catch (e) {
        const invalidField = (e as any)?.response?.data?.errors?.[0]?.source;
        const error = invalidField
          ? 'Failed to login: email or password is invalid.'
          : 'Failed to login.';

        throw new Error(error);
      }
    },

    async logOut() {
      try {
        await axios.post('/api/users/logout');
      } catch (e) {}
    },

    async resetActivationToken(token: string, email: string) {
      return await axios.post('/api/users/reset-activation-token', {
        token,
        email,
      });
    },

    async resetPassword(token: string, password: string) {
      return await axios.post('/api/users/reset-password', {
        token,
        password,
      });
    },

    async updateProfile(name: string, email: string) {
      const toasts = useToastsStore();

      try {
        const { data } = await axios.post('/api/users/update', {
          full_name: name,
          email,
        });

        if (this.user?.email !== email) {
          toasts.showToast({
            id: 'change-email-success',
            message: 'Confirmation email sent.',
            type: 'success',
          });
        } else {
          toasts.showToast({
            id: 'change-name-success',
            message: 'Your name has been changed.',
            type: 'success',
          });
        }

        this.setUser(camelizeKeys(data) as User);
      } catch (e) {
        const hasInvalidEmailError =
          axios.isAxiosError(e) &&
          e.response &&
          e.response.data &&
          Array.isArray(e.response.data.errors) &&
          e.response.data.errors.some(
            (error: any) => 'source' in error && error['source'] === 'email'
          );

        toasts.showToast({
          id: 'change-name-error',
          message: hasInvalidEmailError
            ? 'Email is already taken.'
            : 'Update failed, please try again later.',
          type: 'error',
        });
      }
    },

    setUser(user?: User) {
      this.user = user;
    },
  },
});
