<template>
  <Card :class="$style['permissions-card']">
    <div :class="$style.title">Permission</div>
    <Tag :class="$style.tag" size="small" :text="name" />
    <TextBody :class="$style.text">
      {{ description }} <strong>{{ sortedPermissions.join(', ') }}</strong>
    </TextBody>

    <div v-if="type === 'app'" :class="$style['footer']">
      {{ getLastTelematicsUsageLabel(consents?.[0]?.latestTelematicsUsageTimestamp) }}
    </div>

    <div v-if="type === 'vehicle'" :class="$style['footer']">
      Shared with <strong>{{ consentedApps.apps }}</strong>
      <template v-if="consentedApps.last">
        and <strong>{{ consentedApps.last }}</strong>
      </template>
    </div>
  </Card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Capability, Consents } from '@/types';
import { getLastTelematicsUsageLabel } from '@/utils';

import Card from '../../atoms/card/Card.vue';
import Tag from '../../atoms/tag/Tag.vue';
import TextBody from '../../atoms/text-body/TextBody.vue';

type PermissionCardType = Capability['type'];

const descriptions: Record<PermissionCardType, string> = {
  ['app']: 'The application will have access to',
  ['vehicle']: 'Access is shared to',
};

export default defineComponent({
  components: {
    Card,
    Tag,
    TextBody,
  },
  setup() {
    return {
      getLastTelematicsUsageLabel,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    permissions: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    type: {
      type: String as PropType<PermissionCardType>,
      required: true,
    },
    consents: {
      type: Array as PropType<Consents>,
      default: () => [] as Consents,
    },
  },
  computed: {
    consentedApps(): { apps: string; last: string | null } {
      const applicationNames = this.consents.map((consents) => consents.application.name);
      const apps =
        this.consents.length <= 1
          ? this.consents?.[0]?.application.name
          : applicationNames.slice(0, -1).join(', ');
      const last = this.consents.length <= 1 ? null : applicationNames.slice(-1).join(', ');

      return {
        apps,
        last,
      };
    },
    description(): string {
      return descriptions[this.type];
    },
    sortedPermissions(): string[] {
      return [...this.permissions].sort();
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.permissions-card {
  display: flex;
  flex-direction: column;

  @include mixins.media(xs, max) {
    padding: 20px 20px 30px;
  }

  .title {
    color: vars.$blue;
    font-size: 22px;
    font-weight: 500;
  }
}

.tag {
  align-self: flex-start;
  margin-bottom: 20px;
  margin-top: 16px;
}

.text {
  @include mixins.media(sm) {
    margin-bottom: 10px;
  }
}

.text {
  color: vars.$gray-text;
}

.footer {
  text-align: center;
  color: #98989e;
  font-size: 14px;
  margin-top: 25px;

  strong {
    color: vars.$gray-text;
  }
}
</style>
