import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.alert, _ctx.$style[_ctx.type]])
  }, [
    _createElementVNode("img", { src: _ctx.image }, null, 8, _hoisted_1),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.content)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}