<template>
  <div :class="$style['delete-account']">
    <Card :class="$style.card">
      <TextTitle size="small" :class="$style.title"> Delete account </TextTitle>
      <p :class="$style.text">
        In order to delete your account, please contact us at
        <u>
          <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a
          >.
        </u>
      </p>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Card, TextTitle } from '@/components/base';
import { DRIVER_SUPPORT_EMAIL } from '@/constants';

export default defineComponent({
  components: { Card, TextTitle },
  setup() {
    return {
      supportEmail: DRIVER_SUPPORT_EMAIL,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.delete-account {
  display: flex;
  justify-content: center;
  flex-direction: column;

  [role='dialog'] {
    @include mixins.media(xs, max) {
      padding: 30px 20px 45px;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 42px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.text {
  @include typo.text-medium;
  color: #fff;
}

.title {
  color: vars.$blue;
  margin-bottom: 20px;
}
</style>
