<template>
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6 8.1001C17.1523 8.1001 17.6 7.65238 17.6 7.1001C17.6 6.54781 17.1523 6.1001 16.6 6.1001L16.6 8.1001ZM0.999977 6.1001C0.447693 6.1001 -2.29365e-05 6.54781 -2.28882e-05 7.1001C-2.28399e-05 7.65238 0.447693 8.1001 0.999977 8.1001L0.999977 6.1001ZM16.6 6.1001L0.999977 6.1001L0.999977 8.1001L16.6 8.1001L16.6 6.1001Z"
      fill="currentColor"
    />
    <path
      d="M6.39874 13.813C6.79247 14.2003 7.42562 14.1951 7.8129 13.8013C8.20019 13.4076 8.19495 12.7745 7.80121 12.3872L6.39874 13.813ZM1.70122 6.38717C1.30748 5.99989 0.674334 6.00512 0.28705 6.39886C-0.100233 6.7926 -0.0950008 7.42574 0.298738 7.81302L1.70122 6.38717ZM7.80121 12.3872L1.70122 6.38717L0.298738 7.81302L6.39874 13.813L7.80121 12.3872Z"
      fill="currentColor"
    />
    <path
      d="M0.337245 6.39886C-0.0500389 6.7926 -0.0448065 7.42574 0.348932 7.81302C0.74267 8.20031 1.37581 8.19508 1.7631 7.80134L0.337245 6.39886ZM7.7631 1.70134C8.15038 1.3076 8.14515 0.674456 7.75141 0.287173C7.35767 -0.100111 6.72453 -0.0948784 6.33724 0.29886L7.7631 1.70134ZM1.7631 7.80134L7.7631 1.70134L6.33724 0.29886L0.337245 6.39886L1.7631 7.80134Z"
      fill="currentColor"
    />
  </svg>
</template>
