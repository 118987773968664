import { createRouter, createWebHistory } from 'vue-router';

import { beforeEachGuard } from './beforeEachGuard';
import { routes } from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => savedPosition || { left: 0, top: 0 },
});

router.beforeEach(beforeEachGuard);

export { router };
