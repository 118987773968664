<template>
  <section aria-role="region" :class="$style.section"><slot /></section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.section {
  display: flex;
  flex-direction: column;
  background: vars.$gray-darker;
  border-radius: 12px;
  position: relative;

  @include mixins.media(xs, max) {
    flex: 1;
    padding: 50px 0;
  }

  @include mixins.media(sm) {
    padding: 30px 65px;
  }

  @include mixins.media(md) {
    padding: 30px 35px;
  }

  @include mixins.media(xl) {
    padding: 80px 100px;
  }
}
</style>
