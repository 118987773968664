<template>
  <CardSection :class="$style['reset-password']" aria-label="Change password">
    <div :class="$style.container">
      <HMTitle>Change password</HMTitle>

      <div v-if="step === 'reset-password'">
        <FormError v-if="apiError" :class="$style.error">{{ apiError }}</FormError>

        <CustomForm
          :class="$style.form"
          :errorMessages="errorMessages"
          :setErrors="setErrors"
          @submit="submit"
        >
          <PasswordInput
            v-for="({ name, placeholder, ...attrs }, index) in fields"
            v-bind="attrs"
            :class="{ [$style.input]: applyItemClass(index) }"
            :name="name"
            :error="errors[name]"
            :placeholder="placeholder"
            :value="values[name]"
            :key="name"
            autocomplete="new-password"
            required
            @update:value="setInputValue"
          />

          <div :class="$style.cta">
            <TextButton variant="secondary" type="submit" :disabled="submitButtonDisabled">
              Change password
            </TextButton>
          </div>
        </CustomForm>
      </div>

      <div v-if="step === 'password-changed'" :class="$style.cta">
        <p :class="$style.text">Your password has been changed successfully.</p>
        <router-link :to="signInRoute">
          <TextButton>Go to sign in</TextButton>
        </router-link>
      </div>

      <Spinner v-if="step === 'loading'" />
    </div>
  </CardSection>
</template>

<script lang="ts">
import axios, { AxiosError } from 'axios';
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ApiErrorResponse } from '@/types';
import { CardSection } from '@/components/page';
import {
  CustomForm,
  FormError,
  FormErrorType,
  HMTitle,
  PasswordInput,
  Spinner,
  TextButton,
  useFormErrors,
} from '@/components/base';
import { FAKE_LOADER_TIMEOUT, PASSWORD_MIN_LENGTH, PASSWORD_PATTERN } from '@/constants';
import { getQueryParam, validateIfPasswordsMatch, validatePassword } from '@/utils';
import { RouteNames } from '@/router';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';

type ResetPasswordStep = 'reset-password' | 'loading' | 'password-changed';

const fields = [
  {
    name: 'passwordA' as const,
    placeholder: 'Enter a new password',
    minlength: PASSWORD_MIN_LENGTH,
    pattern: PASSWORD_PATTERN.source,
  },
  {
    name: 'passwordB' as const,
    placeholder: 'Confirm your password',
    minlength: PASSWORD_MIN_LENGTH,
    pattern: PASSWORD_PATTERN.source,
  },
];

const hasInvalidPasswordError = (e: unknown): e is Required<AxiosError<ApiErrorResponse>> =>
  axios.isAxiosError(e) &&
  e.response &&
  e.response.data &&
  Array.isArray(e.response.data.errors) &&
  e.response.data.errors.some((error: any) => 'source' in error && error['source'] === 'password');

export default defineComponent({
  components: {
    CardSection,
    CustomForm,
    FormError,
    HMTitle,
    PasswordInput,
    Spinner,
    TextButton,
  },
  setup() {
    const apiError = ref<string | undefined>(undefined);
    const step = ref<ResetPasswordStep>('reset-password');

    const { errors, errorMessages, setErrors } = useFormErrors({
      passwordA: {
        [FormErrorType.Valid]: validatePassword,
      },
      passwordB: {
        [FormErrorType.Valid]: validatePassword,
      },
    });

    const route = useRoute();
    const router = useRouter();
    const token = getQueryParam(route, 'token');

    const users = useUserStore();
    const [resetPassword] = useAsyncFunction(users.resetPassword, FAKE_LOADER_TIMEOUT);

    const values = ref({
      passwordA: '',
      passwordB: '',
    });

    return {
      apiError,
      errors,
      errorMessages,
      fields,
      resetPassword,
      router,
      setErrors,
      step,
      token,
      users,
      values,
    };
  },
  methods: {
    applyItemClass(index: number) {
      return index < this.fields.length - 1;
    },
    clearAllErrors() {
      this.apiError = undefined;
      this.setErrors({});
    },
    setInputValue(value: string, name: (typeof fields)[number]['name']) {
      this.values[name] = value;
    },
    async submit() {
      try {
        this.clearAllErrors();

        const error = validateIfPasswordsMatch(this.values.passwordA, this.values.passwordB);
        if (error) {
          this.setErrors({ passwordB: error });
          this.step = 'reset-password';
          return;
        }

        this.step = 'loading';
        await this.resetPassword(this.token || '', this.values.passwordB);
        this.step = 'password-changed';
      } catch (e) {
        if (hasInvalidPasswordError(e)) {
          this.apiError = e.response.data.message;
          this.step = 'reset-password';
          return;
        }

        this.router.push({
          name: RouteNames.FORGOT_PASSWORD,
          query: { expired: 'true' },
        });
      }
    },
  },
  computed: {
    signInRoute() {
      return {
        name: RouteNames.SIGN_IN,
      };
    },
    submitButtonDisabled(): boolean {
      return this.fields.some(({ name }) => !this.values[name].length);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

:global([data-page='reset-password']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.reset-password {
  min-height: 485px;
  @include mixins.media(sm) {
    padding: 80px;
  }

  .form {
    margin-top: 40px;
  }

  .input {
    margin-bottom: 10px;
  }

  .text {
    margin-bottom: 40px;
    text-align: center;
  }

  .cta {
    margin-top: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;

  @include mixins.media(xs, max) {
    width: 100%;
    max-width: 346px;
    margin: 0 auto;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  a,
  button {
    width: 200px;
    text-transform: uppercase;
  }
}

.error {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
</style>
