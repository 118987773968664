import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['hm-title'])
  }, [
    _createVNode(_component_Icon, {
      name: "hm",
      class: _normalizeClass(_ctx.iconClass)
    }, null, 8, ["class"]),
    _createElementVNode("h2", {
      class: _normalizeClass([_ctx.$style.title, _ctx.headingClass])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}