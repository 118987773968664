import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActivationAlert = _resolveComponent("ActivationAlert")!
  const _component_PSAVerificationAlert = _resolveComponent("PSAVerificationAlert")!
  const _component_PSAActivationError = _resolveComponent("PSAActivationError")!
  const _component_DashboardInfo = _resolveComponent("DashboardInfo")!
  const _component_AppsGrid = _resolveComponent("AppsGrid")!
  const _component_NoData = _resolveComponent("NoData")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.apps)
  }, [
    (_ctx.isVehicleActivationPending)
      ? (_openBlock(), _createBlock(_component_ActivationAlert, {
          key: 0,
          provider: _ctx.provider.type
        }, null, 8, ["provider"]))
      : _createCommentVNode("", true),
    (_ctx.verification && _ctx.isVehicleVerificationPending)
      ? (_openBlock(), _createBlock(_component_PSAVerificationAlert, {
          key: 1,
          vehicleId: _ctx.vehicle.id,
          isFirstVerification: !_ctx.verification.previouslyVerified
        }, null, 8, ["vehicleId", "isFirstVerification"]))
      : _createCommentVNode("", true),
    (_ctx.isVehicleVerificationFailed)
      ? (_openBlock(), _createBlock(_component_PSAActivationError, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_DashboardInfo),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['apps-list'])
    }, [
      (_ctx.consents && _ctx.consents.length > 0)
        ? (_openBlock(), _createBlock(_component_AppsGrid, {
            key: 0,
            consents: _ctx.consents,
            vehicleId: _ctx.vehicle.id
          }, null, 8, ["consents", "vehicleId"]))
        : (_openBlock(), _createBlock(_component_NoData, {
            key: 1,
            title: "Nothing here"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" There are currently no apps connected to this car. Applications will appear here once you have linked them via your Driver account. ")
            ]),
            _: 1
          }))
    ], 2)
  ], 2))
}