import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(_ctx.$style['bottom-text'])
  }, [
    _createTextVNode(" In case it was not you who registered or if you want to change your email address, you can contact us at "),
    _createElementVNode("u", null, [
      _createElementVNode("a", {
        href: `mailto:${_ctx.supportEmail}`
      }, _toDisplayString(_ctx.supportEmail), 9, _hoisted_1),
      _createTextVNode(". ")
    ])
  ], 2))
}