<template>
  <Alert type="error">
    You have entered the mileage value incorrectly, and vehicle reactivation was denied. Please get
    in touch with our <a :href="`mailto:${supportEmail}`">Support</a>, so we can resolve the issue.
  </Alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Alert } from '@/components/base';
import { DRIVER_SUPPORT_EMAIL } from '@/constants';

export default defineComponent({
  components: {
    Alert,
  },
  setup() {
    const supportEmail = DRIVER_SUPPORT_EMAIL;

    return {
      supportEmail,
    };
  },
});
</script>
