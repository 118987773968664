import { onMounted, onUnmounted } from 'vue';

export const useEvents =
  (event: string | string[], target?: EventTarget) => (method: () => any) => {
    let listeners: (() => any)[];

    const events = Array.isArray(event) ? event : [event];
    const subscribeToEvent = (event: string, listener: () => any, target: EventTarget = window) => {
      target.addEventListener(event, listener);
      return () => target.removeEventListener(event, listener);
    };

    onMounted(() => {
      listeners = events.map((event) => subscribeToEvent(event, method, target));
    });
    onUnmounted(() => {
      listeners.map((unsubscribe) => unsubscribe());
    });
  };
