<template>
  <div :class="$style.dashboard">
    <NoData v-if="!vehicles.vehicles.length" title="There are currently no cars in your account">
      You will be able to add your car once you have connected it to any third party application.
    </NoData>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { RouteNames } from '@/router';
import { useVehiclesStore } from '@/store';

import NoData from './NoData.vue';

export default defineComponent({
  components: {
    NoData,
  },
  setup() {
    const vehicles = useVehiclesStore();
    const route = useRoute();
    const router = useRouter();

    watch(
      () => route.params.vehicleId as string,
      (vehicleId: string) => {
        if (!vehicleId) {
          if (vehicles.vehicles.length) {
            const [vehicle] = vehicles.vehicles;
            router.replace(`/${RouteNames.DASHBOARD}/${vehicle.id}/${RouteNames.APPS}`);
          }
        }
      },
      {
        immediate: true,
      }
    );

    return {
      vehicles,
    };
  },
});
</script>

<style lang="scss" module>
.dashboard {
  display: flex;
  flex-direction: column;
}
</style>
