import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonalInformation = _resolveComponent("PersonalInformation")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_DeleteAccount = _resolveComponent("DeleteAccount")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createVNode(_component_PersonalInformation, {
          name: _ctx.users.user!.fullName,
          email: _ctx.users.user!.email
        }, null, 8, ["name", "email"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_ChangePassword)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_DeleteAccount)
      ])
    ])
  ]))
}