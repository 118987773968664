<template>
  <p :class="[$style.text, $style[size]]"><slot /></p>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type TextType = 'medium' | 'large' | 'xlarge';

export default defineComponent({
  props: {
    size: {
      type: String as PropType<TextType>,
      default: 'medium',
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/typography' as typo;

.text {
  &.medium {
    @include typo.text-medium;
  }

  &.large {
    @include typo.text-large;
  }

  &.xlarge {
    @include typo.text-xlarge;
  }
}
</style>
