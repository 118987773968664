<template>
  <div :class="$style['change-password']">
    <Card :class="$style.card">
      <div v-if="changePasswordStatus === 'pending'" :class="$style.loading">
        <Spinner />
      </div>

      <TextTitle size="small" :class="$style.title"> Change password </TextTitle>

      <CustomForm
        :class="$style.form"
        :errorMessages="errorMessages"
        :setErrors="setErrors"
        @submit="submit"
      >
        <PasswordInput
          v-for="({ name, placeholder, ...attrs }, index) in fields"
          required
          v-bind="attrs"
          :class="{ [$style.input]: applyItemClass(index) }"
          :error="errors[name]"
          :name="name"
          :placeholder="placeholder"
          :value="values[name]"
          :key="name"
          @update:value="setInputValue"
        />

        <TextButton :class="$style.button" :disabled="submitButtonDisabled" type="submit">
          Save changes
        </TextButton>
      </CustomForm>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import {
  Card,
  CustomForm,
  FormErrorType,
  PasswordInput,
  Spinner,
  TextButton,
  TextTitle,
  useFormErrors,
} from '@/components/base';
import {
  FAKE_LOADER_TIMEOUT,
  PASSWORD_MIN_LENGTH,
  PASSWORD_PATTERN,
  PASSWORD_TOO_SHORT_ERROR_MESSAGE,
} from '@/constants';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';
import { validateIfPasswordIsUnused, validateIfPasswordsMatch, validatePassword } from '@/utils';

const fields = [
  {
    name: 'password' as const,
    autocomplete: 'off',
    placeholder: 'Enter your current password',
    minlength: PASSWORD_MIN_LENGTH,
  },
  {
    name: 'passwordA' as const,
    autocomplete: 'new-password',
    placeholder: 'Enter your new password',
    pattern: PASSWORD_PATTERN.source,
    minlength: PASSWORD_MIN_LENGTH,
  },
  {
    name: 'passwordB' as const,
    autocomplete: 'new-password',
    placeholder: 'Please repeat your password',
    pattern: PASSWORD_PATTERN.source,
    minlength: PASSWORD_MIN_LENGTH,
  },
];

export default defineComponent({
  components: { Card, CustomForm, PasswordInput, Spinner, TextButton, TextTitle },
  setup() {
    const { errors, errorMessages, setErrors } = useFormErrors({
      password: {
        [FormErrorType.TooShort]: PASSWORD_TOO_SHORT_ERROR_MESSAGE,
      },
      passwordA: {
        [FormErrorType.Valid]: validatePassword,
      },
      passwordB: {
        [FormErrorType.Valid]: validatePassword,
      },
    });

    const values = ref({
      password: '',
      passwordA: '',
      passwordB: '',
    });

    const users = useUserStore();
    const [changePassword, changePasswordStatus] = useAsyncFunction(
      users.changePassword,
      FAKE_LOADER_TIMEOUT
    );

    return {
      changePassword,
      changePasswordStatus,
      fields,
      errors,
      errorMessages,
      setErrors,
      values,
    };
  },
  methods: {
    applyItemClass(index: number) {
      return index < this.fields.length - 1;
    },
    async submit() {
      this.setErrors({});

      if (!this.validatePassword()) {
        return;
      }

      await this.changePassword(this.values.password, this.values.passwordB);
    },
    setInputValue(value: string, name: (typeof fields)[number]['name']) {
      this.values[name] = value;
    },
    validatePassword() {
      const passwordsDoNotMatchError = validateIfPasswordsMatch(
        this.values.passwordA,
        this.values.passwordB
      );
      if (passwordsDoNotMatchError) {
        this.setErrors({ passwordB: passwordsDoNotMatchError });
        return false;
      }

      const passwordMustBeUniqueError = validateIfPasswordIsUnused(
        this.values.password,
        this.values.passwordA
      );
      if (passwordMustBeUniqueError) {
        this.setErrors({ passwordA: passwordMustBeUniqueError });
        return false;
      }

      return true;
    },
  },
  computed: {
    submitButtonDisabled(): boolean {
      return this.fields.some(({ name }) => !this.values[name].length);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.change-password {
  display: flex;
  flex-direction: column;

  .title {
    color: vars.$blue;
    margin-bottom: 20px;
  }
}

.card {
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;

  @include mixins.media(sm) {
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: 10px;

  @include mixins.media(sm) {
    margin-top: 20px;
  }
}

.button {
  align-self: flex-start;
  margin-top: 28px;

  @include mixins.media(xs, max) {
    margin-top: 20px;
  }
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: vars.$overlay;
}
</style>
