import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createBlock(_component_Alert, { type: "error" }, {
    default: _withCtx(() => [
      _createTextVNode(" You have entered the mileage value incorrectly, and vehicle reactivation was denied. Please get in touch with our "),
      _createElementVNode("a", {
        href: `mailto:${_ctx.supportEmail}`
      }, "Support", 8, _hoisted_1),
      _createTextVNode(", so we can resolve the issue. ")
    ]),
    _: 1
  }))
}