import { ref } from 'vue';

export function useAsyncFunction<T, R>(fn: (..._: T[]) => Promise<R>, delay?: number) {
  const status = ref<'pending' | 'fulfilled'>('fulfilled');

  const wrapper = async (...args: T[]) => {
    status.value = 'pending';
    try {
      if (delay) {
        return await new Promise<R>((resolve, reject) => {
          setTimeout(() => {
            fn(...args)
              .then(resolve)
              .catch(reject);
          }, delay);
        });
      } else {
        return await fn(...args);
      }
    } finally {
      status.value = 'fulfilled';
    }
  };

  return [wrapper, status] as const;
}
