<template>
  <div :class="$style.permissions">
    <TextTitle :class="[$style.title, 'hide-on-desktop']" size="medium"> Shared data </TextTitle>
    <PermissionsGrid :capabilities="capabilities" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Consent } from '@/types';
import { getAppSharedCapabilities } from '@/utils';
import { PermissionsGrid, TextTitle } from '@/components/base';

export default defineComponent({
  components: { PermissionsGrid, TextTitle },
  props: {
    consent: {
      type: Object as PropType<Consent>,
      required: true,
    },
  },
  computed: {
    capabilities() {
      return getAppSharedCapabilities(this.consent);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;

.permissions {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 10px;

  @include mixins.media(lg) {
    :global(.hide-on-desktop) {
      display: none;
    }
  }
}
</style>
