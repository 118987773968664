import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextTitle = _resolveComponent("TextTitle")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['vehicle-details'])
  }, [
    _createVNode(_component_TextTitle, {
      class: _normalizeClass([_ctx.$style['card-title'], _ctx.$style['hide-on-desktop']]),
      size: "medium"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Details ")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_Card, {
      class: _normalizeClass(_ctx.$style.card)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TextTitle, {
          size: "small",
          class: _normalizeClass(_ctx.$style['section-title'])
        }, {
          default: _withCtx(() => [
            _createTextVNode(" VIN ")
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.row)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.left)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.vin)
            }, _toDisplayString(_ctx.vehicle.vin), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.right)
          }, "The unique identification number of your car.", 2)
        ], 2),
        _createVNode(_component_TextTitle, {
          size: "small",
          class: _normalizeClass(_ctx.$style['section-title'])
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Active region ")
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.row)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.left)
          }, [
            _createElementVNode("img", {
              src: _ctx.detailsImage,
              alt: "European Economic Region"
            }, null, 8, _hoisted_1)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.right)
          }, " Your vehicle can share data in the European Economic Region. The following countries are supported: Austria, Belgium, Bulgaria, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, Switzerland, and the United Kingdom. ", 2)
        ], 2)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}