<template>
  <component :is="as" :class="$style['app-list-item']">
    <div :class="[$style.header, $style.text]">
      <ApplicationThumbnail :class="$style.thumbnail" :size="46" :appName="name" :src="iconUrl" />
      <div :class="$style.details">
        <span :class="$style.name">{{ capitalize(name) }}</span>
      </div>
    </div>
    <Card :class="$style.card">
      <div :class="$style['card-header']">
        <span :class="[$style.text, $style['card-header-title']]">Permissions</span>
        <Icon name="arrow-right" />
      </div>
      <div>
        Your car shares the following information with <strong>{{ name }}</strong
        >.
      </div>
      <div :class="$style.permissions">
        <Tag
          v-for="(permission, index) in capabilityPermissions"
          :text="permission"
          :key="`${permission}_${index}`"
        />
      </div>
      <span :class="$style['time-label']" :title="timeLabelText">
        {{ timeLabelText }}
      </span>
    </Card>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { capitalize, getLastTelematicsUsageLabel } from '@/utils';

import ApplicationThumbnail from '../../atoms/application-thumbnail/ApplicationThumbnail.vue';
import Card from '../../atoms/card/Card.vue';
import Icon from '../../atoms/icon/Icon.vue';
import Tag from '../../atoms/tag/Tag.vue';

export default defineComponent({
  components: { ApplicationThumbnail, Card, Icon, Tag },
  setup() {
    return {
      capitalize,
    };
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
    iconUrl: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    capabilityPermissions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    revoked: {
      type: Boolean,
      default: false,
    },
    lastFetchedAt: {
      type: String,
      required: false,
    },
  },
  computed: {
    timeLabelText() {
      return getLastTelematicsUsageLabel(this.lastFetchedAt, this.revoked);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.app-list-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:active,
  &:hover {
    cursor: pointer;

    .details {
      color: #fff;
    }

    .card {
      background: vars.$gray-darker;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .thumbnail {
    margin-right: 10px;
  }

  .details {
    @include mixins.text-ellipsis;
    flex: 1;
    margin-right: 12px;
    color: #98989e;

    .name {
      color: #fff;
      font-weight: bold;
    }
  }

  a {
    margin-left: auto;
  }
}

.text {
  @include typo.text-xlarge;

  @include mixins.media(sm) {
    @include typo.text-xxlarge;
  }
}

.card {
  flex-direction: column;
  padding: 20px;
  background-color: vars.$gray;
  transition: background-color 150ms;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: vars.$blue;

  &,
  > * {
    display: flex;
  }

  .card-header-title {
    margin-right: 10px;
    font-weight: 500;
  }

  svg {
    color: vars.$gray-icon;
  }
}

.permissions {
  @include mixins.gap(4px);
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  padding-top: 35px;
  border-top: 1px solid vars.$gray-icon;
  text-transform: capitalize;
}

.time-label {
  @include mixins.text-ellipsis;
  @include typo.text-medium-alt;
  margin-top: 40px;
  color: #98989e;
  text-align: center;
}
</style>
