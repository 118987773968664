<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    color="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4723 0H12.6372C5.65928 0 0 5.65934 0 12.5824V27.4176C0 34.3407 5.65928 40 12.5823 40H27.4173C34.3403 40 39.9996 34.3407 39.9996 27.4176V12.5824C40.0546 5.65934 34.3953 0 27.4723 0ZM18.0218 21.4835H11.9779V26.978C11.9779 27.6923 11.3735 28.2967 10.6592 28.2967C9.94496 28.2967 9.34057 27.6923 9.34057 26.978V13.0769C9.34057 12.3626 9.94496 11.7582 10.6592 11.7582C11.3735 11.7582 11.9779 12.3626 11.9779 13.0769V18.8462H18.0218C18.7361 18.8462 19.3405 19.4506 19.3405 20.1648C19.3405 20.8791 18.7361 21.4835 18.0218 21.4835ZM30.714 26.978C30.714 27.6923 30.1096 28.2967 29.3953 28.2967C28.681 28.2967 28.0766 27.6923 28.0766 26.978V16.044L23.6261 20.0549C23.3514 20.2747 23.0767 20.3846 22.747 20.3846C22.3624 20.3846 22.0327 20.2198 21.758 19.9451C21.2635 19.3956 21.3185 18.5714 21.8679 18.0769L28.5711 12.0879L28.6261 12.033C28.681 11.978 28.681 11.978 28.736 11.9231C28.7909 11.9231 28.7909 11.8681 28.8459 11.8681C28.9008 11.8681 28.9008 11.8132 28.9558 11.8132C29.0107 11.8132 29.0656 11.7582 29.0656 11.7582C29.1206 11.7582 29.1206 11.7582 29.1755 11.7033C29.2305 11.7033 29.2854 11.7033 29.3404 11.7033C29.3953 11.7033 29.3953 11.7033 29.4503 11.7033C29.4503 11.7033 29.4503 11.7033 29.5052 11.7033C29.5601 11.7033 29.6151 11.7033 29.67 11.7033C29.725 11.7033 29.725 11.7033 29.7799 11.7033C29.8349 11.7033 29.8898 11.7033 29.8898 11.7582C29.9448 11.7582 29.9997 11.7582 29.9997 11.8132C30.0546 11.8132 30.0546 11.8681 30.1096 11.8681C30.1645 11.8681 30.1645 11.9231 30.2195 11.9231C30.2744 11.9231 30.2744 11.978 30.3294 11.978C30.3843 12.033 30.3843 12.033 30.4393 12.0879L30.4942 12.1429C30.5491 12.1978 30.5491 12.1978 30.6041 12.2527C30.6041 12.3077 30.659 12.3077 30.659 12.3626C30.659 12.4176 30.714 12.4176 30.714 12.4725C30.714 12.5275 30.7689 12.5824 30.7689 12.5824C30.7689 12.6374 30.7689 12.6374 30.8239 12.6923C30.8239 12.7473 30.8239 12.8022 30.8239 12.8571C30.8239 12.9121 30.8239 12.9121 30.8239 12.9121V26.978H30.714Z"
      fill="currentColor"
    />
  </svg>
</template>
