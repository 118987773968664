import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": _ctx.enterActiveClass || _ctx.$style['enter-active'],
    "leave-active-class": _ctx.leaveActiveClass || _ctx.$style['leave-active'],
    "enter-from-class": _ctx.enterFromClass || _ctx.$style['enter-fade'],
    "enter-to-class": _ctx.enterToClass || _ctx.$style['enter-to-fade'],
    "leave-from-class": _ctx.leaveFromClass || _ctx.$style['leave-fade'],
    "leave-to-class": _ctx.leaveToClass || _ctx.$style['leave-to-fade']
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["enter-active-class", "leave-active-class", "enter-from-class", "enter-to-class", "leave-from-class", "leave-to-class"]))
}