import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_text_input = _resolveComponent("text-input")!

  return (_openBlock(), _createBlock(_component_text_input, _mergeProps(_ctx.$attrs, {
    disabled: _ctx.disabled,
    error: _ctx.error,
    focused: _ctx.focused,
    placeholder: _ctx.placeholder,
    value: _ctx.value,
    type: _ctx.currentType,
    onBlur: _ctx.onBlur,
    onFocus: _ctx.onFocus,
    "onUpdate:value": _ctx.onInput,
    ref: "textInput"
  }), {
    default: _withCtx(() => [
      (_ctx.showIcon)
        ? (_openBlock(), _createBlock(_component_icon, {
            key: 0,
            name: _ctx.iconName,
            class: _normalizeClass(_ctx.$style.icon),
            onMousedown: _ctx.toggleType
          }, null, 8, ["name", "class", "onMousedown"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["disabled", "error", "focused", "placeholder", "value", "type", "onBlur", "onFocus", "onUpdate:value"]))
}