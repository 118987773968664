import camelCase from 'lodash-es/camelCase';

// eslint-disable-next-line
export function camelizeKeys(obj: any): Object {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (!!obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }

  return obj;
}
