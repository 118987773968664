import { RouteLocationNormalized } from 'vue-router';

export const getQueryParam = (route: RouteLocationNormalized, name: string): string | null => {
  const param = route.query[name];
  if (Array.isArray(param)) {
    return param[0] || null;
  }

  return param || null;
};
