<template>
  <div>
    <div :class="[$style.row, $style['header-row']]">
      <div :class="[$style['page-title']]" size="large">
        My&nbsp;<strong>{{ vehicle.provider.name }}</strong>
      </div>

      <TextButton
        v-if="false"
        :class="$style['hide-on-mobile']"
        variant="danger"
        @click="toggleForgetVehicleModal"
      >
        Forget vehicle
      </TextButton>
    </div>

    <div :class="$style.row">
      <div :class="$style.left">
        <TextLabel v-if="activationStatus.connectedOn">
          {{ activationStatus.connectedOn }}
        </TextLabel>
        <TextLabel v-if="activationStatus.isPending" type="warning">
          Connection is pending
        </TextLabel>
        <TextLabel v-if="activationStatus.isRejected" type="error">
          Connection is denied
        </TextLabel>
      </div>
    </div>

    <div v-if="isVehicleActivationPending" :class="[$style.row, $style.alerts]">
      <ActivationAlert provider="vehicle.provider.type" />
    </div>

    <div :class="[$style.row, $style['content-row']]">
      <div :class="[$style.left, $style['hide-on-mobile']]">
        <TabButton
          as="router-link"
          :to="getRoute('vehicle-details')"
          :isActive="isRoute('vehicle-details')"
        >
          Details
        </TabButton>

        <TabButton
          as="router-link"
          :to="getRoute('vehicle-data')"
          :isActive="isRoute('vehicle-data')"
        >
          Shared data
        </TabButton>

        <TabButton
          v-if="hasVerificationState"
          as="router-link"
          :class="{
            [$style['with-notification']]: isVehicleVerificationPending,
          }"
          :to="getRoute('vehicle-verification')"
          :isActive="isRoute('vehicle-verification')"
        >
          Mileage verification

          <img v-if="isVehicleVerificationPending" :src="icons.alert" />
        </TabButton>
      </div>

      <div :class="$style.right">
        <VehicleDetails
          v-if="isRoute('vehicle-details') || isMobileBreakpoint"
          :vehicle="vehicle"
        />
        <VehicleData v-if="isRoute('vehicle-data') || isMobileBreakpoint" :vehicle="vehicle" />

        <MileageVerification
          v-if="(isRoute('vehicle-verification') || isMobileBreakpoint) && hasVerificationState"
          :class="{
            [$style.first]: isVehicleVerificationPending || isVehicleVerificationFailed,
          }"
          :vehicle="vehicle"
        />
      </div>
    </div>

    <ForgetVehicleModal
      v-if="forgetVehicleModalVisible"
      @delete="toggleForgetVehicleModal"
      @hide="toggleForgetVehicleModal"
      visible
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { format } from 'date-fns/format';
import { useRoute } from 'vue-router';

import { BREAKPOINT_LG } from '@/constants';
import { ForgetVehicleModal, TabButton, TextButton, Label as TextLabel } from '@/components/base';
import {
  getIsVehicleActivationPending,
  getIsVehicleVerificationFailed,
  getIsVehicleVerificationPending,
  getIsVehicleVerified,
  getVehicleActivationStatus,
} from '@/utils';
import { RouteNames } from '@/router';
import { useEvents } from '@/components/composables';
import { useVehiclesStore } from '@/store';
import { Vehicle } from '@/types';

import AlertIcon from '@/assets/images/icons/ico-alert.svg';

import { ActivationAlert } from '../common';

import MileageVerification from './MileageVerification.vue';
import VehicleData from './VehicleData.vue';
import VehicleDetails from './VehicleDetails.vue';

export default defineComponent({
  components: {
    ActivationAlert,
    ForgetVehicleModal,
    MileageVerification,
    TextButton,
    TabButton,
    TextLabel,
    VehicleData,
    VehicleDetails,
  },
  setup() {
    const route = useRoute();
    const vehicles = useVehiclesStore();

    const forgetVehicleModalVisible = ref(false);

    const getIsMobileBreakpoint = () => window.innerWidth < BREAKPOINT_LG;
    const isMobileBreakpoint = ref(getIsMobileBreakpoint());

    const updateIsMobileBreakpoint = () => {
      isMobileBreakpoint.value = getIsMobileBreakpoint();
    };
    useEvents(['resize', 'orientationchange'])(updateIsMobileBreakpoint);

    return {
      forgetVehicleModalVisible,
      icons: {
        alert: AlertIcon,
      },
      isMobileBreakpoint,
      route,
      vehicles,
    };
  },
  computed: {
    activationStatus() {
      const { isActivated, isPending, isRejected } = getVehicleActivationStatus(this.vehicle);
      const connectedOn =
        isPending || isRejected
          ? false
          : isActivated
          ? `Connected on ${format(new Date(isActivated), 'dd.MM.yyyy')}`
          : 'Not connected';

      return {
        isActivated,
        isPending,
        isRejected,
        connectedOn,
      };
    },
    isVehicleActivationPending() {
      return getIsVehicleActivationPending(this.vehicle);
    },
    isVehicleVerificationPending() {
      return getIsVehicleVerificationPending(this.verification);
    },
    isVehicleVerificationFailed() {
      return getIsVehicleVerificationFailed(this.verification);
    },
    isVehicleVerified() {
      return getIsVehicleVerified(this.verification);
    },
    hasVerificationState() {
      return (
        this.isVehicleVerificationPending ||
        this.isVehicleVerified ||
        this.isVehicleVerificationFailed
      );
    },
    verification() {
      return this.vehicle.verification;
    },
    vehicle(): Vehicle {
      return this.vehicles.vehicles.find(
        (vehicle: Vehicle) => vehicle.id === (this.route.params.vehicleId as string)
      ) as Vehicle;
    },
  },
  methods: {
    getRoute(name: RouteNames) {
      return {
        name,
        params: {
          vehicleId: this.route.params.vehicleId,
        },
      };
    },
    isRoute(name: RouteNames) {
      return this.route.name === name;
    },
    toggleForgetVehicleModal() {
      this.forgetVehicleModalVisible = !this.forgetVehicleModalVisible;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;

.page-title {
  color: #e5e5e5;
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 700;

  @include mixins.media(lg) {
    font-size: 36px;
  }

  strong {
    font-weight: 700;
    color: #fff;
  }
}

.left a {
  img {
    margin-left: auto;
  }
}

.alerts {
  margin-top: 20px;
}

@include mixins.media(lg) {
  .row {
    display: flex;
  }

  .left {
    width: 255px;
    flex-shrink: 0;
    margin-right: 30px;

    .with-notification {
      height: 50px;
      padding: 5px 15px 5px 25px;
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-row {
    margin-top: 55px;
  }

  .right {
    flex-grow: 1;
  }

  .hide-on-desktop {
    display: none;
  }
}

@include mixins.media(md, max) {
  .hide-on-mobile {
    display: none;
  }

  .right {
    display: flex;
    flex-direction: column;

    .first {
      display: flex;
      order: -1;
      margin-bottom: 28px;
    }

    > div:last-child:not(.first) {
      margin-bottom: 40px;
    }
  }
}

.content-row {
  margin-top: 25px;
}
</style>
