import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextTitle = _resolveComponent("TextTitle")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_TextLabel = _resolveComponent("TextLabel")!
  const _component_TabButton = _resolveComponent("TabButton")!
  const _component_AboutCard = _resolveComponent("AboutCard")!
  const _component_Permissions = _resolveComponent("Permissions")!
  const _component_RevokeAccessModal = _resolveComponent("RevokeAccessModal")!

  return (_ctx.consent)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['apps-details'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.row, _ctx.$style['header-row']])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.left)
          }, [
            _createVNode(_component_TextTitle, {
              class: _normalizeClass(_ctx.$style.title),
              size: "large"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.consent.application.name), 1)
              ]),
              _: 1
            }, 8, ["class"])
          ], 2),
          (_ctx.canRevoke)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([_ctx.$style.right, _ctx.$style['hide-on-mobile']])
              }, [
                _createVNode(_component_TextButton, {
                  variant: "danger",
                  class: _normalizeClass(_ctx.$style['revoke-button']),
                  onClick: _ctx.showRevokeModal
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Revoke access ")
                  ]),
                  _: 1
                }, 8, ["class", "onClick"])
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.row)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.left)
          }, [
            (_ctx.activationStatus && _ctx.activationStatus.connectedOn)
              ? (_openBlock(), _createBlock(_component_TextLabel, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.activationStatus.connectedOn), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.activationStatus && _ctx.activationStatus.isPending)
              ? (_openBlock(), _createBlock(_component_TextLabel, {
                  key: 1,
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Connection is pending ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.activationStatus && _ctx.activationStatus.isRejected)
              ? (_openBlock(), _createBlock(_component_TextLabel, {
                  key: 2,
                  type: "error"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Connection is denied ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.right, _ctx.$style['hide-on-mobile']])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['active-car'])
            }, _toDisplayString(_ctx.vehicle?.provider?.type), 3)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.row, _ctx.$style['content-row']])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style.left, _ctx.$style['hide-on-mobile']])
          }, [
            _createVNode(_component_TabButton, {
              class: _normalizeClass(_ctx.$style['tab-button']),
              isActive: _ctx.mobileTab === 'details',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setMobileTab('details')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Details ")
              ]),
              _: 1
            }, 8, ["class", "isActive"]),
            _createVNode(_component_TabButton, {
              class: _normalizeClass(_ctx.$style['tab-button']),
              isActive: _ctx.mobileTab === 'shared-data',
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setMobileTab('shared-data')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Shared data ")
              ]),
              _: 1
            }, 8, ["class", "isActive"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.right)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({
            [_ctx.$style.details]: true,
            [_ctx.$style['hide-on-desktop']]: _ctx.mobileTab !== 'details',
          })
            }, [
              _createVNode(_component_AboutCard, {
                appName: _ctx.consent.application.name,
                image: _ctx.consent.application.iconUrl
              }, null, 8, ["appName", "image"])
            ], 2),
            _createVNode(_component_Permissions, {
              class: _normalizeClass({ [_ctx.$style['hide-on-desktop']]: _ctx.mobileTab !== 'shared-data' }),
              consent: _ctx.consent
            }, null, 8, ["class", "consent"]),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.$style['revoke-section'], _ctx.$style['hide-on-desktop']])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['active-car'])
              }, _toDisplayString(_ctx.vehicle?.provider?.type), 3),
              (_ctx.canRevoke)
                ? (_openBlock(), _createBlock(_component_TextButton, {
                    key: 0,
                    variant: "danger",
                    class: _normalizeClass(_ctx.$style['revoke-button']),
                    onClick: _ctx.showRevokeModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Revoke access ")
                    ]),
                    _: 1
                  }, 8, ["class", "onClick"]))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ], 2),
        _createVNode(_component_RevokeAccessModal, {
          pending: _ctx.revokeConsentStatus === 'pending',
          visible: _ctx.revokeModalVisible,
          onHide: _ctx.hideRevokeModal,
          onRevoke: _ctx.onRevokeConsent
        }, null, 8, ["pending", "visible", "onHide", "onRevoke"])
      ], 2))
    : _createCommentVNode("", true)
}