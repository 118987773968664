import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.path ? 'router-link' : 'div'), _mergeProps({
    class: [_ctx.$style['car-vin'], _ctx.selector ? _ctx.$style.selector : _ctx.$style['list-item']]
  }, _ctx.path ? { to: _ctx.path } : {}, { onClick: _ctx.onSelect }), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['details-wrapper'])
      }, [
        _createVNode(_component_Icon, { name: "car" }),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.details)
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.brand)
          }, _toDisplayString(_ctx.brand), 3),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.vin)
          }, [
            _createTextVNode(" VIN "),
            _createElementVNode("span", null, _toDisplayString(_ctx.vin), 1)
          ], 2)
        ], 2)
      ], 2),
      (_ctx.selector)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            name: "arrow-right"
          }))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: `/dashboard/${_ctx.vehicleId}`,
            class: _normalizeClass(_ctx.$style['select-label'])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, { name: "pen" })
            ]),
            _: 1
          }, 8, ["to", "class"]))
    ]),
    _: 1
  }, 16, ["class", "onClick"]))
}