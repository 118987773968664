export const LOCAL_STORAGE_NOTIFICATIONS_KEY = 'notifications';

export enum Notification {
  COOKIES = 'COOKIES',
  DASHBOARD_FIRST_USE = 'DASHBOARD_FIRST_USE',
}

export function setNotificationAsSeen(name: Notification): void {
  const seenNotifications = getSeenNotifications();
  if (Array.isArray(seenNotifications) && !seenNotifications.includes(name)) {
    seenNotifications.push(name);
    localStorage.setItem(LOCAL_STORAGE_NOTIFICATIONS_KEY, JSON.stringify(seenNotifications));
  }
}

export function getSeenNotifications(): Notification[] {
  const seenNotifications = localStorage.getItem(LOCAL_STORAGE_NOTIFICATIONS_KEY) || '[]';
  return JSON.parse(seenNotifications);
}

export function isNotificationSeen(notification: Notification): boolean {
  return !!getSeenNotifications().includes(notification);
}
