import { formatDistance } from 'date-fns';

export function getLastTelematicsUsageLabel(timestamp?: string | null, revoked = false) {
  if (timestamp) {
    const daysSinceFetch = formatDistance(new Date(timestamp), new Date(), {
      addSuffix: true,
    });

    return `${revoked ? 'Revoked' : 'Last updated'} ${daysSinceFetch}`;
  }

  return 'Waiting for first data update';
}
