<template>
  <div :class="$style['car-selector']" :aria-expanded="dropdownVisible ? 'true' : 'false'">
    <CarVin
      v-if="selectedVehicle"
      :class="$style.selector"
      :brand="selectedVehicle?.provider.name"
      :vehicleId="selectedVehicle?.id"
      :vin="selectedVehicle?.vin"
      selector
      @click="toggleDropdown"
    />

    <div v-if="dropdownVisible" :class="$style.dropdown" role="menu">
      <div :class="$style['dropdown-header']">
        <div :class="$style['dropdown-title']">
          My vehicles
          <span>
            <strong>{{ sortedVehicles.length }}</strong> connected
          </span>
        </div>
        <Icon name="cross" :class="$style.caret" @click="toggleDropdown" />
      </div>

      <simplebar>
        <div :class="$style['dropdown-inner']">
          <div :class="$style['vehicle-list']">
            <CarVin
              v-for="{ id, provider, vin } in sortedVehicles"
              :class="{
                [$style['car-vin']]: true,
                selected: selectedVehicle && selectedVehicle.id === id,
              }"
              :brand="provider.name"
              :vehicleId="id"
              :vin="vin"
              :path="getPath(id)"
              :key="vin"
              @select="onSelectVehicle"
            />
          </div>
        </div>
      </simplebar>
    </div>

    <Fade v-if="dropdownVisible">
      <Backdrop @click="toggleDropdown" />
    </Fade>

    <UnlinkVehicleModal
      v-if="modalVisible"
      :class="$style.modal"
      @cancel="toggleModal"
      @hide="toggleModal"
      @unlink="onUnlinkVehicle"
      visible
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import Simplebar from 'simplebar-vue';

import { Vehicle, Vehicles } from '@/types';

import Backdrop from '../../atoms/backdrop/Backdrop.vue';
import CarVin from '../../molecules/car-vin/CarVin.vue';
import Fade from '../../atoms/transitions/Fade.vue';
import Icon from '../../atoms/icon/Icon.vue';

import UnlinkVehicleModal from './UnlinkVehicleModal.vue';

export default defineComponent({
  emits: ['dropdown-visible', 'select', 'unlink'],
  components: {
    Backdrop,
    CarVin,
    Fade,
    Icon,
    Simplebar,
    UnlinkVehicleModal,
  },
  setup() {
    const dropdownVisible = ref(false);
    const modalVisible = ref(false);

    return {
      dropdownVisible,
      modalVisible,
    };
  },
  props: {
    buildPath: {
      type: Function as PropType<(id: string) => string>,
    },
    vehicles: {
      type: Array as PropType<Vehicles>,
      default: () => [],
    },
    selectedVehicleId: {
      type: String,
      required: false,
    },
  },
  computed: {
    otherVehicles(): Vehicles {
      if (this.selectedVehicleId) {
        return this.sortedVehicles.filter((vehicle) => vehicle.id !== this.selectedVehicleId);
      }
      return this.sortedVehicles;
    },
    selectedVehicle(): Vehicle | undefined {
      if (this.sortedVehicles.length) {
        if (this.selectedVehicleId) {
          return this.sortedVehicles.find((vehicle) => vehicle.id === this.selectedVehicleId);
        }

        return this.sortedVehicles[0];
      }
      return undefined;
    },
    sortedVehicles(): Vehicles {
      if (!this.vehicles) return [];
      return [...this.vehicles].sort();
    },
  },
  methods: {
    getPath(id: string): string | undefined {
      const { buildPath } = this;
      if (buildPath) {
        return buildPath(id);
      }
    },
    onSelectVehicle(vin: string) {
      this.$emit('select', vin);
      this.toggleDropdown();
    },
    onUnlinkVehicle() {
      this.toggleModal();
      this.toggleDropdown();
      this.$emit('unlink', this.selectedVehicle?.vin);
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
      this.$emit('dropdown-visible', this.dropdownVisible);
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.car-selector {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 0;
}

.dropdown {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: vars.$z-index-backdrop-above;
  width: 530px;
  max-width: 100%;
  padding: 0 20px 20px;
  overflow: hidden;
  background: #676c73;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);

  .caret {
    cursor: pointer;

    @include mixins.media(sm) {
      right: 19px;
    }

    svg {
      color: vars.$gray-darker;
      transform: rotateZ(-90deg);
    }
  }

  > div {
    width: 100%;
  }

  [data-simplebar='init'] {
    overflow: auto;
  }

  [data-simplebar='init'],
  :global(.simplebar-wrapper) {
    display: flex;
    flex-direction: column;
  }
}

.modal {
  z-index: vars.$z-index-backdrop-above + 1;

  @include mixins.media(xs, max) {
    [role='dialog'] {
      padding: 30px 33px 40px;
    }
  }
}

.dropdown-inner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.active-vehicle {
  &:not(:only-child):not(:last-child) {
    margin-bottom: 32px;

    @include mixins.media(sm) {
      margin-bottom: 40px;
    }
  }
}

.vehicle-list {
  > :not(:last-child) {
    margin-bottom: 5px;
  }

  .car-vin {
    border: initial;
  }

  :global(.selected) {
    background-color: vars.$gray;

    &:hover {
      background-color: #45484c;
    }
  }
}

.dropdown-header {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  margin-bottom: 20px;

  .dropdown-title {
    font-size: 20px;
    font-weight: 500;

    span {
      margin-left: 1.5em;
      font-size: 14px;
      font-weight: 400;
      color: #a6acb2;
    }
  }
}
</style>
