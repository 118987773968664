import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass({ [_ctx.$style['vehicle-card']]: true, [_ctx.$style['revoked']]: _ctx.vehicleRevoked })
  }, {
    default: _withCtx(() => [
      _createElementVNode("header", {
        class: _normalizeClass(_ctx.$style['header'])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['title'])
          }, _toDisplayString(_ctx.vehicle.provider.name), 3),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['vin'])
          }, [
            _createTextVNode(" VIN "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.vehicle.vin), 1)
          ], 2)
        ]),
        (!_ctx.vehicleRevoked)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.vehicleDetailsRoute,
              class: _normalizeClass(_ctx.$style['edit-button'])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, { name: "pen" })
              ]),
              _: 1
            }, 8, ["to", "class"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("footer", {
        class: _normalizeClass(_ctx.$style['footer'])
      }, [
        _createElementVNode("div", null, [
          _createTextVNode(" Shared with "),
          _createElementVNode("strong", null, _toDisplayString(_ctx.applications), 1)
        ]),
        (_ctx.vehicleRevoked)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style['tag'])
            }, "Revoked", 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}