import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoData = _resolveComponent("NoData")!
  const _component_VehicleCard = _resolveComponent("VehicleCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.vehicles)
  }, [
    _createElementVNode("div", null, [
      (_ctx.vehicles.vehicles.length === 0)
        ? (_openBlock(), _createBlock(_component_NoData, {
            key: 0,
            title: "There are currently no cars in your account"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" You will be able to add your car once you have connected it to any third party application. ")
            ]),
            _: 1
          }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.vehicles.vehicles, (vehicle) => {
            return (_openBlock(), _createBlock(_component_VehicleCard, {
              class: _normalizeClass(_ctx.$style['vehicle-card']),
              vehicle: vehicle,
              key: vehicle.id
            }, null, 8, ["class", "vehicle"]))
          }), 128))
    ])
  ], 2))
}