import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_TextTitle = _resolveComponent("TextTitle")!
  const _component_Backdrop = _resolveComponent("Backdrop")!
  const _component_Fade = _resolveComponent("Fade")!

  return (_openBlock(), _createBlock(_component_Fade, { appear: "" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createBlock(_component_Backdrop, {
            key: 0,
            class: _normalizeClass(_ctx.$style['modal-container']),
            onClick: _ctx.hide
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['modal-wrapper'])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.modal),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                  role: "dialog"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['modal-inner'])
                  }, [
                    (_ctx.closable)
                      ? (_openBlock(), _createBlock(_component_CloseButton, {
                          key: 0,
                          class: _normalizeClass(_ctx.$style['close-button']),
                          onClick: _ctx.hide
                        }, null, 8, ["class", "onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.hasTitle)
                      ? (_openBlock(), _createBlock(_component_TextTitle, {
                          key: 1,
                          size: "small",
                          class: _normalizeClass(_ctx.$style.title)
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "title")
                          ]),
                          _: 3
                        }, 8, ["class"]))
                      : _createCommentVNode("", true),
                    (_ctx.hasBody)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasTextBody ? 'TextBody' : 'div'), {
                          key: 2,
                          class: _normalizeClass(_ctx.$style[_ctx.hasTextBody ? 'text' : 'body'])
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "default")
                          ]),
                          _: 3
                        }, 8, ["class"]))
                      : _createCommentVNode("", true),
                    (_ctx.hasFooter)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 3,
                          class: _normalizeClass(_ctx.$style.footer)
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['footer-inner'])
                          }, [
                            _renderSlot(_ctx.$slots, "footer")
                          ], 2)
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 2)
                ], 2)
              ], 2)
            ]),
            _: 3
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}