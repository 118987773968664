<template>
  <div
    :class="[$style['application-thumbnail'], className]"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <img v-if="src && imageLoaded" :src="src" :alt="appName" :style="imageStyles" />
    <span v-else>{{ appName[0] }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  setup() {
    const image = ref<HTMLImageElement | undefined>(undefined);
    const imageLoaded = ref(false);
    const imageStyles = ref({});

    return {
      image,
      imageLoaded,
      imageStyles,
    };
  },
  props: {
    appName: {
      type: String,
      required: true,
    },
    src: {
      type: String,
    },
    size: {
      type: Number,
      default: 60,
    },
    className: {
      type: String,
      default: '',
    },
  },
  created() {
    this.src && this.loadImage(this.src);
  },
  watch: {
    src(next?: string, prev?: string) {
      /* istanbul ignore else */
      if (prev !== next && next) {
        this.loadImage(next);
      }
    },
  },
  methods: {
    loadImage(src: string) {
      this.imageLoaded = false;

      this.image = new Image();
      this.image.onload = () => {
        this.imageLoaded = true;
        this.imageStyles = {
          objectFit: this.image?.naturalWidth === this.image?.naturalHeight ? 'cover' : 'contain',
        };
      };
      this.image.src = src;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.application-thumbnail {
  align-self: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #cdced0;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    background-color: vars.$gray-3;
  }
}
</style>
