export interface AccessGrant {
  id: string;
  scopes: string[];
  status: 'approved' | 'pending' | 'revoked' | 'rejected' | 'timed_out';
}

export type AccessGrants = AccessGrant[];

export interface ApiErrorResponse {
  errors: {
    source: string;
    title: string;
  }[];
  message: string;
  slug: string;
}

export interface Application {
  id: string;
  iconUrl: string;
  name: string;
}

export type ApplicationVersion = Pick<AccessGrant, 'id' | 'scopes'>;

export interface Capability {
  name: string;
  permissions: string[];
  type: 'app' | 'vehicle';
  consents?: Consents;
}

export type Capabilities = Capability[];

export type Consent = {
  accessGrants: AccessGrants;
  application: Application;
  applicationVersion: ApplicationVersion;
  createdAt: string;
  id: string;
  latestTelematicsUsageTimestamp: string | null;
  updatedAt: string;
};

export type Consents = Consent[];

export type OEMProviderFeature =
  | 'login'
  | 'oauth2'
  | 'require_account_verification'
  | 'require_consent_flow_control_measure'
  | 'scopes'
  | 'vin_list';

export interface OEMProvider {
  name: string;
  type: OEMProviderType;
  features: OEMProviderFeature[];
}

export enum OEMProviderType {
  BMW = 'bmw',
  Citroen = 'citroen',
  Ford = 'ford',
  MercedesBenz = 'daimler',
  MINI = 'mini',
  Opel = 'opel',
  Peugeot = 'peugeot',
  Porsche = 'porsche',
  Vauxhall = 'vauxhall',
  VolvoCars = 'volvo_cars',
}

export interface ToastData {
  id: string;
  message: string;
  type: 'error' | 'success';
}

export type User = {
  id: string;
  email: string;
  fullName: string;
  countryCode: string;
  electronicallyIdentifiedAt: string | null;
};

export interface VehicleActivationStatus {
  isActivated: string | false;
  isPending: boolean;
  isRejected: boolean;
}

export type Vehicle = {
  id: string;
  consents: Consents;
  provider: OEMProvider;
  serialNumber: string;
  verification?: VehicleVerificationState;
  vin: string;
};

export type Vehicles = Vehicle[];

export interface VehicleVerificationState {
  id: string;
  attempts: number;
  consentId: string;
  previouslyVerified: boolean;
  status: 'created' | 'pending' | 'verified' | 'failed' | 'timed_out' | 'expired';
  verifiedAt: string | null;
}
