import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['dashboard-info'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.message)
        }, " The applications and your selected car appear here. Applications connected to your car display the permissions they have access to. ", 2),
        _createVNode(_component_Icon, {
          name: "cross",
          class: _normalizeClass(_ctx.$style.close),
          onClick: _ctx.onAccept
        }, null, 8, ["class", "onClick"])
      ], 2))
    : _createCommentVNode("", true)
}