import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HMTitle = _resolveComponent("HMTitle")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_CustomForm = _resolveComponent("CustomForm")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CardSection = _resolveComponent("CardSection")!

  return (_openBlock(), _createBlock(_component_CardSection, {
    class: _normalizeClass(_ctx.$style['reset-password']),
    "aria-label": "Change password"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.container)
      }, [
        _createVNode(_component_HMTitle, null, {
          default: _withCtx(() => [
            _createTextVNode("Change password")
          ]),
          _: 1
        }),
        (_ctx.step === 'reset-password')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.apiError)
                ? (_openBlock(), _createBlock(_component_FormError, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.error)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.apiError), 1)
                    ]),
                    _: 1
                  }, 8, ["class"]))
                : _createCommentVNode("", true),
              _createVNode(_component_CustomForm, {
                class: _normalizeClass(_ctx.$style.form),
                errorMessages: _ctx.errorMessages,
                setErrors: _ctx.setErrors,
                onSubmit: _ctx.submit
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, ({ name, placeholder, ...attrs }, index) => {
                    return (_openBlock(), _createBlock(_component_PasswordInput, _mergeProps(attrs, {
                      class: { [_ctx.$style.input]: _ctx.applyItemClass(index) },
                      name: name,
                      error: _ctx.errors[name],
                      placeholder: placeholder,
                      value: _ctx.values[name],
                      key: name,
                      autocomplete: "new-password",
                      required: "",
                      "onUpdate:value": _ctx.setInputValue
                    }), null, 16, ["class", "name", "error", "placeholder", "value", "onUpdate:value"]))
                  }), 128)),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.cta)
                  }, [
                    _createVNode(_component_TextButton, {
                      variant: "secondary",
                      type: "submit",
                      disabled: _ctx.submitButtonDisabled
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Change password ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ], 2)
                ]),
                _: 1
              }, 8, ["class", "errorMessages", "setErrors", "onSubmit"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.step === 'password-changed')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style.cta)
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.text)
              }, "Your password has been changed successfully.", 2),
              _createVNode(_component_router_link, { to: _ctx.signInRoute }, {
                default: _withCtx(() => [
                  _createVNode(_component_TextButton, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Go to sign in")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.step === 'loading')
          ? (_openBlock(), _createBlock(_component_Spinner, { key: 2 }))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}