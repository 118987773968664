import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Router_view = _resolveComponent("Router-view")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_Container = _resolveComponent("Container")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, { margins: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_Container, {
        as: "main",
        class: _normalizeClass(_ctx.$style.container),
        "aria-role": "main"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.$style['card-layout'], _ctx.route.meta.staticFooter ? _ctx.$style['static-footer'] : ''])
          }, [
            _createVNode(_component_Router_view),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['footer-wrapper'])
            }, [
              _createVNode(_component_PageFooter)
            ], 2)
          ], 2)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}