<template>
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    color="#fff"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.75L12 12.25L21.5 2.75"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
