<template>
  <div :class="[$style.icon, `icon-${name}`]">
    <component :is="component" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { components } from './constants';
import { IconName } from './types';

export default defineComponent({
  props: {
    name: {
      type: String as PropType<IconName>,
      default: 'hm',
    },
  },
  computed: {
    component() {
      return components[this.name];
    },
  },
});
</script>

<style lang="scss" module>
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

:global(.icon-arrow-down),
:global(.icon-arrow-right) {
  svg {
    stroke-width: 2;
  }
}
</style>
