<template>
  <div :class="[$style.label, $style[this.type]]"><slot /></div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type LabelType = 'error' | 'info' | 'warning';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<LabelType>,
      default: 'info',
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.label {
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 5px 6px;
}

.error {
  background-color: vars.$red;
  color: #fff;
}

.info {
  background-color: vars.$gray;
  color: vars.$gray-2;
}

.warning {
  background-color: #ffc122;
  color: vars.$gray;
}
</style>
