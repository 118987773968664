import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style.menu, _ctx.navigation ? _ctx.$style['no-marker'] : ''])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ as, asProps, label }, index) => {
      return (_openBlock(), _createBlock(_component_MenuItem, {
        as: as,
        asProps: asProps,
        label: label,
        selected: _ctx.isSelected(index),
        key: `${index}${label}`,
        onClick: (e) => _ctx.selectItem(index, e)
      }, null, 8, ["as", "asProps", "label", "selected", "onClick"]))
    }), 128))
  ], 2))
}