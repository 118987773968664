<template>
  <CardSection :class="$style.section" aria-label="Sign in">
    <Spinner v-if="loading" />

    <div :class="$style['sign-in']" v-else>
      <HMTitle
        :class="$style.title"
        :headingClass="$style.heading"
        :iconClass="$style['title-icon']"
      >
        <span class="light">Sign in to</span> Driver
      </HMTitle>

      <CustomForm
        :class="$style.form"
        :errorMessages="errorMessages"
        :setErrors="setErrors"
        @submit="submit"
      >
        <component
          v-for="{ component, name, placeholder, ...attrs } in fields"
          :is="component"
          v-bind="attrs"
          required
          :error="errors[name]"
          :name="name"
          :value="values[name]"
          :placeholder="placeholder"
          :key="name"
          @update:value="setInputValue"
        />

        <FormError v-if="error" :class="$style['form-error']">{{ error }}</FormError>

        <div :class="$style.cta">
          <TextButton :disabled="submitButtonDisabled" type="submit">Sign In</TextButton>
        </div>
      </CustomForm>

      <div :class="$style['forgot-password']">
        <TextBody>
          <router-link :to="forgotPasswordRoute"> Forgot password? </router-link>
        </TextBody>
      </div>
    </div>
  </CardSection>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { CardSection } from '@/components/page';
import {
  CustomForm,
  FormError,
  FormErrorType,
  HMTitle,
  PasswordInput,
  Spinner,
  TextBody,
  TextButton,
  TextInput,
  useFormErrors,
} from '@/components/base';
import {
  EMAIL_PATTERN,
  INVALID_EMAIL_ERROR_MESSAGE,
  PASSWORD_MIN_LENGTH,
  PASSWORD_TOO_SHORT_ERROR_MESSAGE,
} from '@/constants';
import { RouteNames } from '@/router';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';

const fields = [
  {
    component: TextInput,
    name: 'email' as const,
    placeholder: 'Your email here',
    pattern: EMAIL_PATTERN.source,
  },
  {
    component: PasswordInput,
    name: 'password' as const,
    placeholder: 'Your password here',
    minlength: PASSWORD_MIN_LENGTH,
  },
];

export default defineComponent({
  components: {
    CardSection,
    CustomForm,
    FormError,
    HMTitle,
    PasswordInput,
    Spinner,
    TextBody,
    TextButton,
    TextInput,
  },
  setup() {
    const { errors, errorMessages, setErrors } = useFormErrors({
      email: {
        [FormErrorType.Valid]: INVALID_EMAIL_ERROR_MESSAGE,
      },
      password: {
        [FormErrorType.TooShort]: PASSWORD_TOO_SHORT_ERROR_MESSAGE,
      },
    });

    const values = ref({
      email: '',
      password: '',
    });
    const error = ref('');
    const loading = ref(false);

    const users = useUserStore();
    const [signIn] = useAsyncFunction(users.logIn);

    return {
      fields,
      error,
      errors,
      errorMessages,
      loading,
      setErrors,
      signIn,
      values,
    };
  },
  methods: {
    setInputValue(value: string, name: (typeof fields)[number]['name']) {
      this.values[name] = value;
    },
    async submit() {
      const {
        values: { email, password },
      } = this;

      this.error = '';
      this.loading = true;

      try {
        await this.signIn(email, password);

        const redirect = this.$route.params['redirect'] as string | undefined;
        if (redirect) {
          window.location.assign(redirect);
        } else {
          window.location.assign(`/${RouteNames.DASHBOARD}`);
        }
      } catch (e) {
        this.error = (e as Error).message;
        this.loading = false;
      }
    },
  },
  computed: {
    forgotPasswordRoute() {
      return {
        name: RouteNames.FORGOT_PASSWORD,
      };
    },
    submitButtonDisabled(): boolean {
      return this.fields.some(({ name }) => !this.values[name].length);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

:global([data-page='sign-in']) body {
  background: url('../../../assets/images/background/sign-in.png') bottom right no-repeat;
  background-size: cover;

  @include mixins.media(sm) {
    background-image: url('../../../assets/images/background/sign-in-sm.jpg');
  }
}

.section {
  justify-content: center;
  background: transparent;
  min-height: 590px;

  @include mixins.media(sm) {
    background: vars.$gray-darker;
  }
}

.sign-in {
  display: flex;
  flex-direction: column;

  @include mixins.media(sm) {
    margin: auto 0;

    h2 {
      margin-top: 20px;
    }
  }

  .title {
    margin-bottom: 40px;
  }

  .heading {
    margin-top: 20px;
    font-size: 21px;
  }

  .title-icon {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    @include mixins.media(xs, max) {
      width: 100%;
      max-width: 356px;
      margin: 0 auto;
    }

    > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .form-error {
    margin-top: 10px;

    @include mixins.media(sm) {
      margin-top: 20px;
    }
  }

  .cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    button {
      width: 200px;
    }
  }

  .forgot-password {
    margin-top: 40px;
    text-align: center;
    cursor: pointer;

    a {
      display: inline-block;
      margin-top: 1.5px;
      color: vars.$gray-lighter;
      border-bottom: 1px solid vars.$gray-lighter;

      &:hover,
      &:active {
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
</style>
