import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppListItem = _resolveComponent("AppListItem")!
  const _component_ColumnGrid = _resolveComponent("ColumnGrid")!

  return (_openBlock(), _createBlock(_component_ColumnGrid, {
    columnClass: _ctx.$style['apps-column'],
    items: _ctx.consents
  }, {
    default: _withCtx(({
        key,
        index,
        application: { iconUrl, name },
        applicationVersion: { id: applicationId, scopes },
        latestTelematicsUsageTimestamp: lastFetchedAt,
      }) => [
      (_openBlock(), _createBlock(_component_AppListItem, {
        as: "router-link",
        to: _ctx.getApplicationRoute(applicationId),
        name: name,
        iconUrl: iconUrl,
        lastFetchedAt: lastFetchedAt,
        capabilityPermissions: _ctx.getPermissions(scopes),
        key: key,
        index: index
      }, null, 8, ["to", "name", "iconUrl", "lastFetchedAt", "capabilityPermissions", "index"]))
    ]),
    _: 1
  }, 8, ["columnClass", "items"]))
}