import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HMTitle = _resolveComponent("HMTitle")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CardSection = _resolveComponent("CardSection")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CardSection, {
      class: _normalizeClass([_ctx.$style.section, _ctx.$style[_ctx.step]]),
      "aria-label": "Confirm Email"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_HMTitle, null, {
          default: _withCtx(() => [
            _createTextVNode("Email confirmation")
          ]),
          _: 1
        }),
        (_ctx.step === 'loading')
          ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.step === 'link-expired')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style['confirm-email'])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.text)
              }, "The confirmation link has expired. Please request a new link.", 2)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.step === 'invalid-token')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(_ctx.$style['confirm-email'])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.text)
              }, " The token used for confirmation is invalid. Please ensure you're using the correct link. ", 2)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.step === 'error')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(_ctx.$style['confirm-email'])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.text)
              }, " An error occurred during the email confirmation process. Please try again later. ", 2)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.step === 'success')
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              class: _normalizeClass(_ctx.$style['confirm-email'])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.text)
              }, "Your email address has been successfully updated.", 2)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['hide-on-mobile'])
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style['bottom-text'])
      }, [
        _createTextVNode(" In case it was not you who requested the email change, you can contact us at "),
        _createElementVNode("u", null, [
          _createElementVNode("a", {
            href: `mailto:${_ctx.supportEmail}`
          }, _toDisplayString(_ctx.supportEmail), 9, _hoisted_1),
          _createTextVNode(". ")
        ])
      ], 2)
    ], 2)
  ]))
}