import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['no-data'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.title)
    }, _toDisplayString(_ctx.title), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.text)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}