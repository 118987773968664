<template>
  <Alert>
    Vehicle activation is pending. An email from BMW was sent to your email address to complete the
    activation. Check your spam folder.
  </Alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Alert } from '@/components/base';

export default defineComponent({
  components: {
    Alert,
  },
});
</script>
