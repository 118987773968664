<template>
  <component :is="as" :class="$style[fluid ? 'container-fluid' : 'container']"> <slot /></component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    as: {
      type: String,
      default: 'div',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.container,
.container-fluid {
  width: 100%;
  min-width: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint, $max-width in vars.$container-widths {
    @include mixins.media($breakpoint) {
      max-width: $max-width;
    }
  }
}
</style>
