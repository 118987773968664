<template>
  <p :class="$style['bottom-text']">
    In case it was not you who registered or if you want to change your email address, you can
    contact us at
    <u>
      <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a
      >.
    </u>
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { DRIVER_SUPPORT_EMAIL } from '@/constants';

export default defineComponent({
  setup() {
    return {
      supportEmail: DRIVER_SUPPORT_EMAIL,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.bottom-text {
  @include typo.text-small;
  text-align: center;
  color: vars.$gray-lighter;

  @include mixins.media(sm) {
    margin: 20px 0;
  }

  a {
    white-space: nowrap;
  }
}
</style>
