<template>
  <div>
    <component :is="route.meta.layout || 'div'">
      <router-view />
    </component>
    <cookies-bar :show="showCookiesBar" @accept="acceptCookies" />
  </div>
</template>

<script lang="ts">
import { defineComponent, VueElementConstructor } from 'vue';
import { RouteLocationNormalized, useRoute } from 'vue-router';

import { CookiesBar } from '@/components/base';

import { isNotificationSeen, Notification, setNotificationAsSeen } from './utils';

export default defineComponent({
  components: {
    CookiesBar,
  },
  setup() {
    const route = useRoute() as RouteLocationNormalized & {
      meta: {
        layout?: VueElementConstructor | string;
      };
    };

    return {
      route,
    };
  },
  computed: {
    showCookiesBar() {
      return !isNotificationSeen(Notification.COOKIES);
    },
  },
  methods: {
    acceptCookies() {
      setNotificationAsSeen(Notification.COOKIES);
    },
  },
});
</script>
