<template>
  <Modal :visible="visible" :closable="!pending" @hide="onCancel">
    <template #title> Revoke access </template>

    <TextBody size="large">
      <strong>
        Are you sure you want to revoke access to this application? <br />
        If you continue, this application will no longer have access to your car.
      </strong>
    </TextBody>

    <template #footer>
      <TextButton variant="dark" @click="onCancel" :disabled="pending"> Cancel </TextButton>
      <TextButton variant="danger" @click="onRevoke" :disabled="pending">
        {{ pending ? 'Revoking...' : 'Revoke access' }}
      </TextButton>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Modal, TextBody, TextButton } from '@/components/base';

export default defineComponent({
  emits: ['hide', 'revoke'],
  components: { Modal, TextBody, TextButton },
  props: {
    pending: {
      default: false,
      type: Boolean,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onCancel() {
      this.$emit('hide');
    },
    onRevoke() {
      this.$emit('revoke');
    },
  },
});
</script>
