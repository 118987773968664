import { ErrorMessage, ErrorMessageProducer } from '@/components/base';
import {
  PASSWORD_MATCH_ERROR_MESSAGE,
  PASSWORD_MIN_LENGTH,
  PASSWORD_PATTERN_ERROR_MESSAGE_LOWERCASE,
  PASSWORD_PATTERN_ERROR_MESSAGE_NO_SPACES,
  PASSWORD_PATTERN_ERROR_MESSAGE_NUMBER,
  PASSWORD_PATTERN_ERROR_MESSAGE_UPPERCASE,
  PASSWORD_PATTERN_LOWERCASE,
  PASSWORD_PATTERN_NO_SPACES,
  PASSWORD_PATTERN_NUMBERS,
  PASSWORD_PATTERN_UPPERCASE,
  PASSWORD_REUSED_ERROR_MESSAGE,
  PASSWORD_TOO_SHORT_ERROR_MESSAGE,
} from '@/constants';

export const validateIfPasswordsMatch = (passwordA: string, passwordB: string): ErrorMessage => {
  if (passwordA !== passwordB) {
    return PASSWORD_MATCH_ERROR_MESSAGE;
  }
};

export const validateIfPasswordIsUnused = (
  oldPassword: string,
  newPassword: string,
): ErrorMessage => {
  if (oldPassword === newPassword) {
    return PASSWORD_REUSED_ERROR_MESSAGE;
  }
};

export const validatePassword = (password: string): ErrorMessage =>
  validatePasswordLength(password) || validatePasswordPattern(password);

export const validatePasswordLength = (password: string): ErrorMessage => {
  if (password.length < PASSWORD_MIN_LENGTH) {
    return PASSWORD_TOO_SHORT_ERROR_MESSAGE;
  }
};

/**
 * Returns fine grained error message based on testing parts of the common RegExp.
 * @param value String
 * @returns String Fine grained error or undefined
 */
export const validatePasswordPattern: ErrorMessageProducer = (value: string) => {
  if (!PASSWORD_PATTERN_LOWERCASE.test(value)) {
    return PASSWORD_PATTERN_ERROR_MESSAGE_LOWERCASE;
  } else if (!PASSWORD_PATTERN_UPPERCASE.test(value)) {
    return PASSWORD_PATTERN_ERROR_MESSAGE_UPPERCASE;
  } else if (!PASSWORD_PATTERN_NUMBERS.test(value)) {
    return PASSWORD_PATTERN_ERROR_MESSAGE_NUMBER;
  } else if (!PASSWORD_PATTERN_NO_SPACES.test(value)) {
    return PASSWORD_PATTERN_ERROR_MESSAGE_NO_SPACES;
  }
};
