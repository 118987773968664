<template>
  <div :class="$style.vehicles">
    <div>
      <NoData
        v-if="vehicles.vehicles.length === 0"
        title="There are currently no cars in your account"
      >
        You will be able to add your car once you have connected it to any third party application.
      </NoData>

      <template v-else>
        <VehicleCard
          v-for="vehicle in vehicles.vehicles"
          :class="$style['vehicle-card']"
          :vehicle="vehicle"
          :key="vehicle.id"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useVehiclesStore } from '@/store';

import NoData from '../dashboard/NoData.vue';

import VehicleCard from './VehicleCard.vue';

export default defineComponent({
  components: {
    NoData,
    VehicleCard,
  },
  setup() {
    const vehicles = useVehiclesStore();

    return {
      vehicles,
    };
  },
});
</script>

<style lang="scss" module>
.vehicle-card {
  width: 100%;

  + .vehicle-card {
    margin-top: 2px;
  }
}
</style>
