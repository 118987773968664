<template>
  <div :class="[$style.alert, $style[type]]">
    <img :src="image" />
    <span :class="$style.content"><slot /></span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import AlertDotError from '@/assets/images/icons/ico-alert-dot-error.svg';
import AlertDotWarning from '@/assets/images/icons/ico-alert-dot-warning.svg';

type AlertType = 'error' | 'warning';

export default defineComponent({
  props: {
    type: {
      default: 'warning',
      type: String as PropType<AlertType>,
    },
  },
  computed: {
    image(): string {
      return this.type === 'warning' ? AlertDotWarning : AlertDotError;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.alert {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 10px;
}

.content {
  margin-top: 4px;
  margin-left: 6px;

  a {
    text-decoration: underline;
  }
}

.error {
  background-color: #fff2f2;
  color: #c83a3a;
}

.warning {
  background-color: #fff7e0;
  color: vars.$gray;
}
</style>
