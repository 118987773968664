import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style['application-thumbnail'], _ctx.className]),
    style: _normalizeStyle({ width: `${_ctx.size}px`, height: `${_ctx.size}px` })
  }, [
    (_ctx.src && _ctx.imageLoaded)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src,
          alt: _ctx.appName,
          style: _normalizeStyle(_ctx.imageStyles)
        }, null, 12, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.appName[0]), 1))
  ], 6))
}