<template>
  <div v-if="consent" :class="$style['apps-details']">
    <div :class="[$style.row, $style['header-row']]">
      <div :class="$style.left">
        <TextTitle :class="$style.title" size="large">
          {{ consent.application.name }}
        </TextTitle>
      </div>

      <div v-if="canRevoke" :class="[$style.right, $style['hide-on-mobile']]">
        <TextButton variant="danger" :class="$style['revoke-button']" @click="showRevokeModal">
          Revoke access
        </TextButton>
      </div>
    </div>

    <div :class="$style.row">
      <div :class="$style.left">
        <TextLabel v-if="activationStatus && activationStatus.connectedOn">
          {{ activationStatus.connectedOn }}
        </TextLabel>
        <TextLabel v-if="activationStatus && activationStatus.isPending" type="warning">
          Connection is pending
        </TextLabel>
        <TextLabel v-if="activationStatus && activationStatus.isRejected" type="error">
          Connection is denied
        </TextLabel>
      </div>
      <div :class="[$style.right, $style['hide-on-mobile']]">
        <div :class="$style['active-car']">{{ vehicle?.provider?.type }}</div>
      </div>
    </div>

    <div :class="[$style.row, $style['content-row']]">
      <div :class="[$style.left, $style['hide-on-mobile']]">
        <TabButton
          :class="$style['tab-button']"
          :isActive="mobileTab === 'details'"
          @click="setMobileTab('details')"
        >
          Details
        </TabButton>

        <TabButton
          :class="$style['tab-button']"
          :isActive="mobileTab === 'shared-data'"
          @click="setMobileTab('shared-data')"
        >
          Shared data
        </TabButton>
      </div>

      <div :class="$style.right">
        <div
          :class="{
            [$style.details]: true,
            [$style['hide-on-desktop']]: mobileTab !== 'details',
          }"
        >
          <AboutCard :appName="consent.application.name" :image="consent.application.iconUrl" />
        </div>
        <Permissions
          :class="{ [$style['hide-on-desktop']]: mobileTab !== 'shared-data' }"
          :consent="consent"
        />

        <div :class="[$style['revoke-section'], $style['hide-on-desktop']]">
          <div :class="$style['active-car']">{{ vehicle?.provider?.type }}</div>
          <TextButton
            v-if="canRevoke"
            variant="danger"
            :class="$style['revoke-button']"
            @click="showRevokeModal"
          >
            Revoke access
          </TextButton>
        </div>
      </div>
    </div>

    <RevokeAccessModal
      :pending="revokeConsentStatus === 'pending'"
      :visible="revokeModalVisible"
      @hide="hideRevokeModal"
      @revoke="onRevokeConsent"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { format } from 'date-fns/format';
import { useRoute, useRouter } from 'vue-router';

import { Consent, OEMProviderType, Vehicle } from '@/types';
import { getVehicleActivationStatusForApp } from '@/utils';
import { RouteNames } from '@/router';
import { TabButton, TextButton, Label as TextLabel, TextTitle } from '@/components/base';
import { useAsyncFunction } from '@/components/composables';
import { useVehiclesStore } from '@/store';

import AboutCard from './AboutCard.vue';
import Permissions from './Permissions.vue';
import RevokeAccessModal from './RevokeAccessModal.vue';

export default defineComponent({
  components: {
    AboutCard,
    Permissions,
    RevokeAccessModal,
    TabButton,
    TextLabel,
    TextButton,
    TextTitle,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const vehicles = useVehiclesStore();

    const redirectToApps = () => {
      router.replace({
        path: `/${RouteNames.DASHBOARD}/${route.params.vehicleId}/${RouteNames.APPS}`,
      });
    };

    const mobileTab = ref<'details' | 'shared-data'>('details');

    const [revokeConsent, revokeConsentStatus] = useAsyncFunction(vehicles.revokeConsent);
    const revokeModalVisible = ref(false);

    return {
      mobileTab,
      redirectToApps,
      revokeConsent,
      revokeConsentStatus,
      revokeModalVisible,
      route,
      vehicles,
    };
  },
  computed: {
    activationStatus() {
      if (this.consent) {
        const { isActivated, isPending, isRejected } = getVehicleActivationStatusForApp(
          this.vehicle,
          this.consent
        );
        const connectedOn =
          isPending || isRejected
            ? false
            : isActivated
            ? `Connected on ${format(new Date(isActivated), 'dd.MM.yyyy')}`
            : 'Not connected';

        return {
          isActivated,
          isPending,
          isRejected,
          connectedOn,
        };
      }

      return undefined;
    },
    canRevoke() {
      const status = this.activationStatus;
      if (status) {
        return (
          this.vehicle.provider.type !== OEMProviderType.Porsche &&
          (status.isActivated || status.isPending)
        );
      }

      return false;
    },
    consent(): Consent | undefined {
      return this.vehicle.consents.find(
        (consent) => consent.applicationVersion.id === (this.route.params.app as string)
      );
    },
    vehicle(): Vehicle {
      return this.vehicles.getVehicle(this.route.params.vehicleId as string) as Vehicle;
    },
  },
  methods: {
    async onRevokeConsent() {
      try {
        await this.revokeConsent((this.consent as Consent).id, (this.vehicle as Vehicle).id);
      } catch (e) {}
    },
    hideRevokeModal() {
      this.revokeModalVisible = false;
    },
    showRevokeModal() {
      this.revokeModalVisible = true;
    },
    setMobileTab(tab: 'details' | 'shared-data') {
      this.mobileTab = tab;
    },
  },
  watch: {
    consent(current: Consent | undefined) {
      if (current === undefined) {
        this.redirectToApps();
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.apps-details {
  display: flex;
  flex-direction: column;

  @include mixins.media(lg) {
    .row {
      display: flex;
    }

    .left {
      width: 255px;
      flex-shrink: 0;
      margin-right: 30px;
    }

    .header-row {
      h1 {
        margin-bottom: 16px;
      }

      .left {
        width: auto;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .content-row {
      margin-top: 55px;
    }

    .right {
      flex-grow: 1;
    }

    .hide-on-desktop {
      display: none;
    }
  }

  @include mixins.media(md, max) {
    .hide-on-mobile {
      display: none;
    }
  }
}

.content-row {
  margin-top: 30px;
}

.details {
  display: grid;

  @include mixins.media(md, max) {
    margin-bottom: 40px;
  }
}

.active-car {
  display: inline-block;
  color: vars.$gray-text;
  text-transform: uppercase;
  font-size: 11px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  padding: 4px 6px;
  align-self: center;
  min-width: 255px;
}

.revoke-section {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: 60px;

  .revoke-button {
    align-self: center;
    margin-top: 40px;
  }
}

.title {
  margin-bottom: 16px;
}
</style>
