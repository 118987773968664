import {
  AccessGrant,
  Capabilities,
  Consent,
  OEMProviderType,
  Vehicle,
  VehicleActivationStatus,
  VehicleVerificationState,
} from '@/types';

export function getAppSharedCapabilities(consent: Consent): Capabilities {
  const capabilities: Capabilities = [];
  consent.applicationVersion.scopes.forEach((scope) => {
    const [capabilityName, permissionType, propertyName] = scope.split('.');
    const permission = `${permissionType} ${propertyName.replace(/_/g, ' ')}`;
    const parsedCapabilityName = capabilityName.replace(/_/g, ' ');

    const existingCapability = capabilities.find(
      (capability) => capability.name === parsedCapabilityName
    );
    if (!existingCapability) {
      capabilities.push({
        name: parsedCapabilityName,
        permissions: [permission],
        type: 'app',
        consents: [consent],
      });
      return;
    }

    existingCapability.permissions.push(permission);
  });

  return capabilities.sort((a, b) => a.name.localeCompare(b.name));
}

export function getIsGrantApproved(grant: AccessGrant): boolean {
  return grant.status === 'approved';
}

export function getIsGrantPendingApproval(grant: AccessGrant): boolean {
  return grant.status === 'pending';
}

export function getIsGrantRejected(grant: AccessGrant): boolean {
  return grant.status === 'rejected';
}

export function getConsentHasApprovedGrants(consent: Consent): boolean {
  return consent.accessGrants.some(getIsGrantApproved);
}

export function getConsentHasPendingGrants(consent: Consent): boolean {
  return consent.accessGrants.some(getIsGrantPendingApproval);
}

export function getConsentHasRejectedGrants(consent: Consent): boolean {
  return consent.accessGrants.some(getIsGrantRejected);
}

export function getIsVehicleActivationPending(vehicle: Vehicle): boolean {
  const { consents, provider, verification } = vehicle;

  if (getVehicleRequiresVerification(vehicle)) {
    return !!verification && verification.status === 'created';
  }

  if (provider.type === OEMProviderType.BMW) {
    return consents.some(getConsentHasPendingGrants);
  }

  return false;
}

export function getIsVehicleVerificationFailed(verification?: VehicleVerificationState): boolean {
  return !!verification && ['failed', 'timed_out', 'expired'].includes(verification.status);
}

export function getIsVehicleVerificationPending(verification?: VehicleVerificationState): boolean {
  return !!verification && verification.status === 'pending';
}

export function getIsVehicleVerified(verification?: VehicleVerificationState): boolean {
  return !!verification && verification.status === 'verified';
}

export function getVehicleActivationStatus(vehicle: Vehicle): VehicleActivationStatus {
  const { consents, provider } = vehicle;

  const approvedConsents = [...consents]
    .filter((consent) => consent.accessGrants.some(getIsGrantApproved))
    .sort((a: Consent, b: Consent) => {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    });

  // PSA brands
  if (getVehicleRequiresVerification(vehicle)) {
    const { verification } = vehicle;

    const vehicleVerificationPending = getIsVehicleVerificationPending(verification);
    const vehicleVerificationFailed = getIsVehicleVerificationFailed(verification);

    const isPending = vehicleVerificationPending;
    const isActivated = !isPending && !vehicleVerificationFailed && approvedConsents.length > 0;

    return {
      isActivated: isActivated ? approvedConsents[0].updatedAt : false,
      isPending,
      isRejected: vehicleVerificationFailed,
    };
  }

  // BMW
  if (provider.type === OEMProviderType.BMW) {
    const isPending = vehicle.consents.some(getConsentHasPendingGrants);
    const isActivated = !isPending && approvedConsents.length > 0;
    const isRejected =
      !isActivated && !isPending && vehicle.consents.some(getConsentHasRejectedGrants);

    return {
      isActivated: isActivated ? approvedConsents[0].updatedAt : false,
      isPending,
      isRejected,
    };
  }

  // All other brands
  return {
    isActivated: approvedConsents.length ? approvedConsents[0].updatedAt : false,
    isPending: false,
    isRejected: false,
  };
}

export function getVehicleActivationStatusForApp(
  vehicle: Vehicle,
  consent: Consent
): VehicleActivationStatus {
  if (getVehicleRequiresVerification(vehicle)) {
    const { verification } = vehicle;
    const vehicleVerificationPending = getIsVehicleVerificationPending(verification);
    const vehicleVerificationFailed = getIsVehicleVerificationFailed(verification);

    if (vehicleVerificationPending || vehicleVerificationFailed) {
      return {
        isActivated: false,
        isPending: vehicleVerificationPending,
        isRejected: vehicleVerificationFailed,
      };
    }
  }

  const isActivated = getConsentHasApprovedGrants(consent) ? consent.updatedAt : false;
  return {
    isActivated,
    isPending: !isActivated && getConsentHasPendingGrants(consent),
    isRejected: false,
  };
}

export function getVehicleRequiresVerification({ provider: { features } }: Vehicle): boolean {
  return features.includes('require_consent_flow_control_measure');
}

export function getVehicleSharedCapabilities(vehicle: Vehicle): Capabilities {
  let allScopes: Array<string> = [];
  vehicle.consents.forEach(({ applicationVersion: { scopes } }) => {
    allScopes = [...allScopes, ...scopes];
  });
  const capabilities: Capabilities = [];
  allScopes.forEach((scope) => {
    const [capabilityName, permissionType, propertyName] = scope.split('.');
    const permission = `${permissionType} ${propertyName.replace(/_/g, ' ')}`;
    const parsedCapabilityName = capabilityName.replace(/_/g, ' ');

    const existingCapability = capabilities.find(
      (capability) => capability.name === parsedCapabilityName
    );

    if (!existingCapability) {
      capabilities.push({
        name: parsedCapabilityName,
        permissions: [permission],
        type: 'vehicle',
        consents: vehicle.consents.filter((consent) => {
          return consent.applicationVersion.scopes.some((scope) => scope.includes(capabilityName));
        }),
      });

      return;
    }

    if (!existingCapability.permissions.includes(permission)) {
      existingCapability.permissions.push(permission);
    }
  });

  return capabilities.sort((a, b) => a.name.localeCompare(b.name));
}

export const getVehicleVerificationConsent = (vehicle: Required<Vehicle>): Consent => {
  return vehicle.consents.find(
    (consent) => consent.id === vehicle.verification.consentId
  ) as Consent;
};
