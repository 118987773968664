import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextTitle = _resolveComponent("TextTitle")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['delete-account'])
  }, [
    _createVNode(_component_Card, {
      class: _normalizeClass(_ctx.$style.card)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TextTitle, {
          size: "small",
          class: _normalizeClass(_ctx.$style.title)
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Delete account ")
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.$style.text)
        }, [
          _createTextVNode(" In order to delete your account, please contact us at "),
          _createElementVNode("u", null, [
            _createElementVNode("a", {
              href: `mailto:${_ctx.supportEmail}`
            }, _toDisplayString(_ctx.supportEmail), 9, _hoisted_1),
            _createTextVNode(". ")
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}