<template>
  <div :class="[$style.menu, navigation ? $style['no-marker'] : '']">
    <MenuItem
      v-for="({ as, asProps, label }, index) in items"
      :as="as"
      :asProps="asProps"
      :label="label"
      :selected="isSelected(index)"
      :key="`${index}${label}`"
      @click="(e: MouseEvent) => selectItem(index, e)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import MenuItem from './MenuItem.vue';
import { MenuItems, MenuItemSelectEvent } from './types';

export default defineComponent({
  emits: ['select'],
  components: {
    MenuItem,
  },
  setup(props) {
    const selectedItemIndex = ref(props.selectedIndex || 0);

    return {
      selectedItemIndex,
    };
  },
  props: {
    items: {
      type: Array as PropType<MenuItems>,
      default: () => [],
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    selectedIndex: {
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
  },
  methods: {
    isSelected(index: number) {
      return !this.navigation && this.selectedItemIndex === index;
    },
    selectItem(itemIndex: number, e: MouseEvent) {
      const { onSelect } = this.items[itemIndex];

      if (!this.navigation) {
        this.selectedItemIndex = itemIndex;
      }

      if (onSelect) {
        onSelect(e);
      }

      const event: MenuItemSelectEvent = {
        itemIndex,
        originalEvent: e,
      };
      this.$emit('select', event);
    },
  },
  watch: {
    selectedIndex(value?: number) {
      this.selectedItemIndex = value || 0;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

@import '~styles/mixins';
@import '~styles/typography';
@import '~styles/variables';

.menu {
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background: $gray;
  width: 340px;
  padding: 5px;
  border-radius: 6px;

  &.no-marker {
    .label {
      color: #fff;
    }
  }
}
</style>
