<template>
  <ColumnGrid :columnClass="$style['permissions-column']" :items="capabilities">
    <template v-slot="props">
      <PermissionsCard v-bind="props" />
    </template>
  </ColumnGrid>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Capabilities } from '@/types';

import ColumnGrid from '../../atoms/column-grid/ColumnGrid.vue';
import PermissionsCard from '../permissions-card/PermissionsCard.vue';

export default defineComponent({
  components: {
    ColumnGrid,
    PermissionsCard,
  },
  props: {
    capabilities: {
      type: Array as PropType<Capabilities>,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" module>
.permissions-column {
  gap: 20px 0;
}
</style>
