import { defineStore } from 'pinia';
import { uniqueId } from 'lodash-es';

import { ToastData } from '@/types';

export interface ToastsState {
  toasts: ToastData[];
}

export const useToastsStore = defineStore('toasts', {
  state: (): ToastsState => {
    return {
      toasts: [],
    };
  },
  actions: {
    showToast({ id, message, type }: Omit<ToastData, 'id'> & Partial<Pick<ToastData, 'id'>>) {
      this.toasts = [
        ...this.toasts,
        {
          id: id || uniqueId(),
          message,
          type,
        },
      ];
    },
    hideToast(id: string) {
      this.toasts = this.toasts.filter((toast) => toast.id !== id);
    },
  },
});
