<template>
  <div :class="[$style['page-container'], margins ? $style.margins : '']">
    <slot />
    <span>
      <ToastContainer :toasts="toasts.toasts" @hide:toast="toasts.hideToast" />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { ToastContainer } from '@/components/base';
import { useToastsStore } from '@/store';
import { useViewHeight } from '@/components/composables';

export default defineComponent({
  components: { ToastContainer },
  setup() {
    const route = useRoute();
    const toasts = useToastsStore();

    useViewHeight();

    onMounted(() => {
      document.documentElement.setAttribute('data-page', (route!.name as string) || '');
    });

    return {
      toasts,
    };
  },
  props: {
    margins: {
      default: false,
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/functions' as fn;
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;

$margin: 2 * grid.$gutter;

.page-container {
  display: flex;

  @include mixins.media(xs) {
    &,
    > div {
      min-height: fn.vh(100);
    }
  }

  @include mixins.media(sm) {
    &.margins {
      margin: $margin 0;

      &,
      > div {
        min-height: calc(#{fn.vh(100)} - 2 * #{$margin});
      }
    }
  }
}
</style>
