import IconArrowDown from './IconArrowDown.vue';
import IconArrowLeftAlt from './IconArrowLeftAlt.vue';
import IconArrowRight from './IconArrowRight.vue';
import IconCar from './IconCar.vue';
import IconCross from './IconCross.vue';
import IconEye from './IconEye.vue';
import IconEyeBlind from './IconEyeBlind.vue';
import IconHM from './IconHM.vue';
import IconPen from './IconPen.vue';
import IconUser from './IconUser.vue';

import { IconName } from './types';

export const components = {
  ['arrow-down']: IconArrowDown,
  ['arrow-right']: IconArrowRight,
  ['arrow-left-alt']: IconArrowLeftAlt,
  ['car']: IconCar,
  ['cross']: IconCross,
  ['eye']: IconEye,
  ['eye-blind']: IconEyeBlind,
  ['hm']: IconHM,
  ['user']: IconUser,
  ['pen']: IconPen,
} satisfies Record<IconName, any>;
