<template>
  <component :is="as" :class="[$style.title, $style[size]]"> <slot /></component>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type TitleSize = 'small' | 'medium' | 'large' | 'large-alt';

type TitleComponent = 'h1' | 'h2' | 'h3';

export default defineComponent({
  props: {
    size: {
      default: 'medium',
      type: String as PropType<TitleSize>,
    },
  },
  computed: {
    as(): TitleComponent {
      switch (this.size) {
        case 'small':
          return 'h3';
        case 'medium':
          return 'h2';
        default:
          return 'h1';
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;

.title {
  display: flex;

  &.small {
    @include typo.title-small();
  }

  &.medium {
    @include typo.title-medium();
  }

  &.large,
  &.large-alt {
    @include typo.title-large-xs();

    @include mixins.media(md) {
      @include typo.title-large-medium();
    }
  }

  &.large-alt {
    @include mixins.media(md) {
      @include typo.title-large-alt();
    }
  }
}
</style>
