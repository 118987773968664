import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_TextTitle = _resolveComponent("TextTitle")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_CustomForm = _resolveComponent("CustomForm")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['change-password'])
  }, [
    _createVNode(_component_Card, {
      class: _normalizeClass(_ctx.$style.card)
    }, {
      default: _withCtx(() => [
        (_ctx.changePasswordStatus === 'pending')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$style.loading)
            }, [
              _createVNode(_component_Spinner)
            ], 2))
          : _createCommentVNode("", true),
        _createVNode(_component_TextTitle, {
          size: "small",
          class: _normalizeClass(_ctx.$style.title)
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Change password ")
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_CustomForm, {
          class: _normalizeClass(_ctx.$style.form),
          errorMessages: _ctx.errorMessages,
          setErrors: _ctx.setErrors,
          onSubmit: _ctx.submit
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, ({ name, placeholder, ...attrs }, index) => {
              return (_openBlock(), _createBlock(_component_PasswordInput, _mergeProps({ required: "" }, attrs, {
                class: { [_ctx.$style.input]: _ctx.applyItemClass(index) },
                error: _ctx.errors[name],
                name: name,
                placeholder: placeholder,
                value: _ctx.values[name],
                key: name,
                "onUpdate:value": _ctx.setInputValue
              }), null, 16, ["class", "error", "name", "placeholder", "value", "onUpdate:value"]))
            }), 128)),
            _createVNode(_component_TextButton, {
              class: _normalizeClass(_ctx.$style.button),
              disabled: _ctx.submitButtonDisabled,
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Save changes ")
              ]),
              _: 1
            }, 8, ["class", "disabled"])
          ]),
          _: 1
        }, 8, ["class", "errorMessages", "setErrors", "onSubmit"])
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}