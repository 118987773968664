<template>
  <Fade appear>
    <Backdrop v-if="isVisible" :class="$style['modal-container']" @click="hide">
      <div :class="$style['modal-wrapper']">
        <div :class="$style.modal" @click.stop role="dialog">
          <div :class="$style['modal-inner']">
            <CloseButton v-if="closable" :class="$style['close-button']" @click="hide" />

            <TextTitle v-if="hasTitle" size="small" :class="$style.title">
              <slot name="title" />
            </TextTitle>

            <component
              v-if="hasBody"
              :is="hasTextBody ? 'TextBody' : 'div'"
              :class="$style[hasTextBody ? 'text' : 'body']"
            >
              <slot />
            </component>

            <div v-if="hasFooter" :class="$style.footer">
              <div :class="$style['footer-inner']">
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  </Fade>
</template>

<script lang="ts">
import { defineComponent, ref, Slot } from 'vue';

import Backdrop from '../../atoms/backdrop/Backdrop.vue';
import CloseButton from '../../molecules/close-button/CloseButton.vue';
import Fade from '../../atoms/transitions/Fade.vue';
import TextBody from '../../atoms/text-body/TextBody.vue';
import TextTitle from '../../atoms/title/Title.vue';

export default defineComponent({
  emits: ['hide'],
  components: {
    Backdrop,
    CloseButton,
    Fade,
    TextBody,
    TextTitle,
  },
  setup(props) {
    const isVisible = ref(props.visible);

    const hasSlot = (slot?: Slot<any>) =>
      !!slot && !!slot().length && slot().every((e) => e.children !== 'v-if');

    const hasTextSlot = (slot?: Slot<any>) =>
      !!slot && !!slot().length && slot().every((e) => typeof e.children === 'string');

    return {
      hasSlot,
      hasTextSlot,
      isVisible,
    };
  },
  props: {
    closable: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasBody(): boolean {
      return this.hasSlot(this.$slots.default);
    },
    hasTextBody(): boolean {
      return this.hasTextSlot(this.$slots.default);
    },
    hasTitle(): boolean {
      return this.hasSlot(this.$slots['title']);
    },
    hasFooter(): boolean {
      return this.hasSlot(this.$slots['footer']);
    },
  },
  methods: {
    hide() {
      if (this.closable) {
        this.isVisible = false;
        this.$emit('hide');
      }
    },
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.isVisible = true;
      } else {
        this.hide();
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.modal-container {
  overflow-y: auto;
}

.modal-wrapper {
  width: 100%;
  min-height: calc(100% - 2 * #{grid.$gutter});
  padding: 0 grid.$grid-gap;

  @include mixins.media(xs, max) {
    min-height: calc(100% - 2 * #{grid.$xs-gutter});
    padding: 0 grid.$xs-grid-gap;
  }
}

.modal {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  padding: 30px 20px;
  background: vars.$gray-light;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  @include mixins.media(xs, max) {
    padding: 30px 30px 40px;
  }

  .close-button {
    position: absolute;
    top: -7px;
    right: -7px;

    svg {
      color: vars.$gray-darker;
    }
  }
}

.modal-inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  > *:not(:only-child):not(:last-child) {
    margin-bottom: 20px;
  }

  .text,
  .title {
    word-wrap: break-word;
  }

  .text {
    color: vars.$gray-text;
  }

  .body,
  .footer {
    display: flex;
  }

  .footer-inner {
    @include mixins.gap(10px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .footer-inner > button:only-child {
    flex: 0 1 200px;
  }

  .footer-inner > button:not(:only-child) {
    min-width: 140px;

    @include mixins.media(xs, max) {
      flex-grow: 1;
      min-width: auto;
    }
  }
}
</style>
