<template>
  <div :class="$style['vehicle-details']">
    <TextTitle :class="[$style['card-title'], $style['hide-on-desktop']]" size="medium">
      Details
    </TextTitle>

    <Card :class="$style.card">
      <TextTitle size="small" :class="$style['section-title']"> VIN </TextTitle>

      <div :class="$style.row">
        <div :class="$style.left">
          <div :class="$style.vin">{{ vehicle.vin }}</div>
        </div>
        <div :class="$style.right">The unique identification number of your car.</div>
      </div>

      <TextTitle size="small" :class="$style['section-title']"> Active region </TextTitle>

      <div :class="$style.row">
        <div :class="$style.left">
          <img :src="detailsImage" alt="European Economic Region" />
        </div>
        <div :class="$style.right">
          Your vehicle can share data in the European Economic Region. The following countries are
          supported: Austria, Belgium, Bulgaria, Cyprus, Czech Republic, Denmark, Estonia, Finland,
          France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta,
          Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden,
          Switzerland, and the United Kingdom.
        </div>
      </div>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Card, TextTitle } from '@/components/base';
import { Vehicle } from '@/types';

import DetailsImage from '@/assets/images/european_economic_region.png';

export default defineComponent({
  components: { Card, TextTitle },
  setup() {
    return {
      detailsImage: DetailsImage,
    };
  },
  props: {
    vehicle: {
      required: true,
      type: Object as PropType<Vehicle>,
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.vehicle-details {
  display: flex;
  flex-direction: column;

  .card-title {
    margin-bottom: 12px;
  }

  .section-title {
    color: vars.$blue;
    margin-bottom: 14px;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 25px;
    }
  }
}

.card {
  flex-direction: column;
  padding-bottom: 30px;
  margin-bottom: 28px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }

  + .card-title {
    margin-top: 12px;
  }
}

.vin {
  padding: 16px 12px;
  text-align: center;
  width: 100%;
  font-size: 13px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.left {
  margin-bottom: 20px;
}

.right {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.01em;
}

@include mixins.media(lg) {
  .row {
    display: flex;
    align-items: center;

    .left {
      padding-right: 30px;
      width: 50%;
      margin-bottom: 0;
    }

    .right {
      padding-left: 30px;
      width: 50%;
    }
  }

  .hide-on-desktop {
    display: none;
  }
}
</style>
