<template>
  <div>
    <CardSection :class="[$style.section, $style[step]]" aria-label="Confirm Email">
      <HMTitle>Email confirmation</HMTitle>

      <Spinner v-if="step === 'loading'" />

      <div v-if="step === 'link-expired'" :class="$style['confirm-email']">
        <p :class="$style.text">The confirmation link has expired. Please request a new link.</p>
      </div>

      <div v-if="step === 'invalid-token'" :class="$style['confirm-email']">
        <p :class="$style.text">
          The token used for confirmation is invalid. Please ensure you're using the correct link.
        </p>
      </div>

      <div v-if="step === 'error'" :class="$style['confirm-email']">
        <p :class="$style.text">
          An error occurred during the email confirmation process. Please try again later.
        </p>
      </div>

      <div v-if="step === 'success'" :class="$style['confirm-email']">
        <p :class="$style.text">Your email address has been successfully updated.</p>
      </div>
    </CardSection>

    <div :class="$style['hide-on-mobile']">
      <p :class="$style['bottom-text']">
        In case it was not you who requested the email change, you can contact us at
        <u>
          <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a
          >.
        </u>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import axios, { AxiosError } from 'axios';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { ApiErrorResponse } from '@/types';
import { CardSection } from '@/components/page';
import { DRIVER_SUPPORT_EMAIL, FAKE_LOADER_TIMEOUT } from '@/constants';
import { getQueryParam } from '@/utils';
import { HMTitle, Spinner } from '@/components/base';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';

type ConfirmEmailStep = 'invalid-token' | 'link-expired' | 'loading' | 'error' | 'success';

const hasInvalidTokenError = (e: unknown): e is Required<AxiosError<ApiErrorResponse>> =>
  axios.isAxiosError(e) &&
  e.response &&
  e.response.data &&
  Array.isArray(e.response.data.errors) &&
  e.response.data.errors.some((error: any) => 'source' in error && error['source'] === 'token');

const hasExpiredTokenError = (e: unknown): e is Required<AxiosError<ApiErrorResponse>> =>
  axios.isAxiosError(e) &&
  e.response &&
  e.response.data &&
  Array.isArray(e.response.data.errors) &&
  e.response.data.errors.some(
    (error: any) => 'source' in error && error['source'] === 'token_expired'
  );

export default defineComponent({
  components: {
    CardSection,
    HMTitle,
    Spinner,
  },
  setup() {
    const { activationEmail: email, verifiedName: name } = window.__DRIVER_DATA__;

    const step = ref<ConfirmEmailStep>('loading');

    const route = useRoute();
    const token = getQueryParam(route, 'token');

    const users = useUserStore();
    const [confirmEmail] = useAsyncFunction(users.confirmEmail, FAKE_LOADER_TIMEOUT);

    onMounted(async () => {
      if (!token) {
        step.value = 'invalid-token';
        return;
      }

      try {
        await confirmEmail(token);
        step.value = 'success';
      } catch (e) {
        if (hasInvalidTokenError(e)) {
          step.value = 'invalid-token';
          return;
        }

        if (hasExpiredTokenError(e)) {
          step.value = 'link-expired';
          return;
        }

        step.value = 'error';
      }
    });

    return {
      email,
      name,
      step,
      supportEmail: DRIVER_SUPPORT_EMAIL,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='confirm-email']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.confirm-email {
  display: flex;
  flex-direction: column;
}

.section {
  min-height: 530px;
}

.text {
  @include typo.text-semi-large;
  margin: 20px 0 40px;
  text-align: center;

  @include mixins.media(xs, max) {
    align-self: center;
    width: 100%;
    max-width: 316px;
  }
}

.bottom-text {
  @include typo.text-small;
  text-align: center;
  color: vars.$gray-lighter;

  @include mixins.media(sm) {
    margin: 20px 0;
  }

  a {
    white-space: nowrap;
  }
}

.link-expired {
  .button {
    margin-top: 0;
  }
}

.invalid-token {
  //
}

.loading {
  //
}

.success {
  //
}

.error {
  //
}

.card {
  flex-direction: column;
  padding-bottom: 30px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.hide-on-mobile {
  @include mixins.media(xs, max) {
    display: none;
  }
}

.hide-on-desktop {
  @include mixins.media(sm) {
    display: none;
  }
}
</style>
