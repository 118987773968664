import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarSelector = _resolveComponent("CarSelector")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['car-selector-wrapper'])
  }, [
    _createVNode(_component_CarSelector, {
      class: _normalizeClass(_ctx.$style['car-selector']),
      buildPath: _ctx.buildPath,
      vehicles: _ctx.vehicles,
      selectedVehicleId: _ctx.selectedVehicleId,
      onDropdownVisible: _ctx.toggleScroll
    }, null, 8, ["class", "buildPath", "vehicles", "selectedVehicleId", "onDropdownVisible"])
  ], 2))
}