<template>
  <div :class="[$style['text-input'], className]">
    <div
      :class="{
        [$style['wrapper-outer']]: true,
        [$style.focused]: isFocused,
        [$style['has-error']]: hasError,
        [$style['has-right-component']]: hasComponent,
      }"
      @mousedown="triggerFocus"
    >
      <div
        :class="[$style['wrapper-inner'], showAltPlaceholder ? $style['with-placeholder'] : '']"
        @mousedown="triggerFocus"
      >
        <span v-if="showAltPlaceholder" :class="$style['placeholder']">{{ placeholder }}</span>

        <input
          v-bind="attrs"
          :disabled="disabled"
          :placeholder="placeholder"
          :type="type"
          :value="value"
          ref="inputRef"
          @blur="onBlur"
          @focus="onFocus"
          @input="onInput"
        />
      </div>
      <span v-if="hasComponent" :class="$style.right"><slot /></span>
    </div>
    <span v-if="hasError" :class="$style.error">{{ error }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

type TextInputType = 'email' | 'password' | 'text';

export default defineComponent({
  emits: ['update:value', 'focus', 'blur'],
  inheritAttrs: false,
  setup(_, ctx) {
    const inputRef = ref<HTMLInputElement | null>(null);
    const isFocused = ref(false);

    const { class: className, ...attrs } = ctx.attrs;

    return {
      className,
      attrs,
      inputRef,
      isFocused,
    };
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    error: {
      default: '',
      type: String,
    },
    focused: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: '',
      type: String,
    },
    type: {
      default: 'text',
      type: String as PropType<TextInputType>,
    },
    value: {
      default: '',
      type: String,
    },
  },
  computed: {
    hasError() {
      return !!this.error.length;
    },
    showAltPlaceholder() {
      return !!this.value.length;
    },
    hasComponent(): boolean {
      return (
        !!this.$slots.default &&
        !!this.$slots.default().length &&
        this.$slots.default().every((e) => e.children !== 'v-if')
      );
    },
  },
  methods: {
    onBlur(e: FocusEvent) {
      this.isFocused = false;
      this.$emit('blur', e);
    },
    onFocus(e: FocusEvent) {
      this.isFocused = true;
      this.$emit('focus', e);
    },
    triggerFocus(event?: Event) {
      if (event) {
        if (event.target !== event.currentTarget) {
          return;
        }
        event.preventDefault();
      }

      (this.inputRef as HTMLInputElement).focus();
    },
    triggerBlur() {
      (this.inputRef as HTMLInputElement).blur();
    },
    onInput(e: Event) {
      const { name, value } = e.target as HTMLInputElement;
      this.$emit('update:value', value, name);
    },
  },
  mounted() {
    if (this.focused) {
      this.$nextTick(() => this.triggerFocus());
    }
  },
  watch: {
    focused(value: boolean) {
      if (value) {
        this.triggerFocus();
      } else {
        this.triggerBlur();
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.text-input {
  display: flex;
  flex-direction: column;

  .error {
    @include typo.text-medium();
    margin: 5px 15px 0;
    color: #ff7373;
  }
}

.wrapper-outer {
  display: flex;
  height: 60px;
  padding: 0 18px;
  background: vars.$gray-darkest;
  border: 2px solid transparent;
  border-radius: 6px;

  &.focused {
    border-color: vars.$gray-lighter;

    .placeholder {
      color: vars.$gray-lighter;
    }
  }

  &.has-error {
    border-color: #ff7373;
  }

  &.has-right-component {
    padding-right: 0;
  }

  .wrapper-inner {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    &.with-placeholder {
      justify-content: flex-start;
      margin-top: 10px;

      input {
        color: #fff;
        -webkit-text-fill-color: #fff;
      }

      input::-webkit-credentials-auto-fill-button {
        margin-top: -17px;
      }
    }
  }

  .placeholder {
    @include mixins.text-ellipsis();
    @include typo.text-small();
    display: flex;
    align-self: flex-start;
    margin-bottom: 3px;
    color: vars.$gray-light;
  }

  .right {
    display: flex;
    align-items: center;
  }

  input {
    @include mixins.text-ellipsis();
    display: flex;
    width: 100%;
    min-width: 0;
    color: vars.$gray-light;
    caret-color: #fff;
    font-size: 100%;
    line-height: 19px;
    background-color: vars.$gray-darkest;
    box-shadow: 0 0 0 1000px vars.$gray-darkest inset;
    -webkit-text-fill-color: vars.$gray-light;

    &::-webkit-credentials-auto-fill-button {
      background-color: vars.$gray-lighter;
    }
  }
}
</style>
