import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createBlock(_component_Alert, null, {
    default: _withCtx(() => [
      _createTextVNode(" Vehicle activation is pending. An email from BMW was sent to your email address to complete the activation. Check your spam folder. ")
    ]),
    _: 1
  }))
}