import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_ctx.isFirstVerification)
    ? (_openBlock(), _createBlock(_component_Alert, { key: 0 }, {
        default: _withCtx(() => [
          _createTextVNode(" Vehicle activation is in progress. Please "),
          _createVNode(_component_router_link, { to: _ctx.routeProps }, {
            default: _withCtx(() => [
              _createTextVNode("enter the current mileage")
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" of your car to complete activation. ")
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_Alert, { key: 1 }, {
        default: _withCtx(() => [
          _createTextVNode(" Mileage value needs to be updated in order to check you are in possession of the car. Please "),
          _createVNode(_component_router_link, { to: _ctx.routeProps }, {
            default: _withCtx(() => [
              _createTextVNode("enter the current mileage value")
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(". ")
        ]),
        _: 1
      }))
}