import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_TextBody = _resolveComponent("TextBody")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass(_ctx.$style['permissions-card'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title)
      }, "Permission", 2),
      _createVNode(_component_Tag, {
        class: _normalizeClass(_ctx.$style.tag),
        size: "small",
        text: _ctx.name
      }, null, 8, ["class", "text"]),
      _createVNode(_component_TextBody, {
        class: _normalizeClass(_ctx.$style.text)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.description) + " ", 1),
          _createElementVNode("strong", null, _toDisplayString(_ctx.sortedPermissions.join(', ')), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.type === 'app')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style['footer'])
          }, _toDisplayString(_ctx.getLastTelematicsUsageLabel(_ctx.consents?.[0]?.latestTelematicsUsageTimestamp)), 3))
        : _createCommentVNode("", true),
      (_ctx.type === 'vehicle')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style['footer'])
          }, [
            _createTextVNode(" Shared with "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.consentedApps.apps), 1),
            (_ctx.consentedApps.last)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" and "),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.consentedApps.last), 1)
                ], 64))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}