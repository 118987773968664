<template>
  <transition
    :enter-active-class="enterActiveClass || $style['enter-active']"
    :leave-active-class="leaveActiveClass || $style['leave-active']"
    :enter-from-class="enterFromClass || $style['enter-fade']"
    :enter-to-class="enterToClass || $style['enter-to-fade']"
    :leave-from-class="leaveFromClass || $style['leave-fade']"
    :leave-to-class="leaveToClass || $style['leave-to-fade']"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    enterActiveClass: {
      type: String,
    },
    leaveActiveClass: {
      type: String,
    },
    enterFromClass: {
      type: String,
    },
    enterToClass: {
      type: String,
    },
    leaveFromClass: {
      type: String,
    },
    leaveToClass: {
      type: String,
    },
  },
});
</script>

<style lang="scss" module>
.enter-active,
.leave-active {
  transition: opacity 0.125s ease-in;
}

.enter-fade,
.leave-to-fade {
  opacity: 0;
}

.enter-to-fade,
.leave-fade {
  opacity: 1;
}
</style>
