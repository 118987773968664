import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), _mergeProps({
    class: [_ctx.$style['menu-item'], _ctx.selected ? _ctx.$style.selected : '']
  }, _ctx.asProps), {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style.label)
      }, _toDisplayString(_ctx.label), 3),
      (_ctx.selected)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.$style.marker)
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["class"]))
}