<template>
  <CardSection
    :class="[$style.section, currentStep === 'link-sent' ? $style['email-sent'] : '']"
    aria-label="Forgot password"
  >
    <div :class="$style['forgot-password']">
      <div v-if="currentStep === 'enter-email'">
        <HMTitle>Forgot your password?</HMTitle>

        <FormError v-if="showLinkExpiredError" :class="$style.error"
          >Your password reset link has expired</FormError
        >

        <CustomForm
          :class="$style.form"
          :errorMessages="errorMessages"
          :setErrors="setErrors"
          @submit="submit"
        >
          <p :class="$style.text">
            Please enter the email you have signed up with. We will email you with a link to reset
            your password.
          </p>

          <component
            v-for="{ component, name, placeholder, type, ...attrs } in fields"
            v-bind="attrs"
            :is="component"
            :error="errors[name]"
            :name="name"
            :placeholder="placeholder"
            :type="type"
            :value="values[name]"
            :key="name"
            @update:value="setInputValue"
          />

          <div :class="$style.cta">
            <router-link :to="signInRoute">
              <TextButton variant="secondary">Back</TextButton>
            </router-link>

            <TextButton type="submit" :disabled="submitButtonDisabled">
              Send reset link
            </TextButton>
          </div>
        </CustomForm>
      </div>

      <div v-if="currentStep === 'link-sent'">
        <HMTitle>Reset link sent!</HMTitle>

        <p :class="$style.text">
          We have sent you an email with a link to reset your password. If you haven't received it
          yet, please check your spam folder. If it still hasn't arrived please wait a few minutes
          and then refresh the page.
        </p>
      </div>

      <div v-if="currentStep === 'loading'" :class="$style.loader">
        <Spinner />
      </div>
    </div>
  </CardSection>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

import { CardSection } from '@/components/page';
import {
  CustomForm,
  FormError,
  FormErrorType,
  HMTitle,
  Spinner,
  TextButton,
  TextInput,
  useFormErrors,
} from '@/components/base';
import { EMAIL_PATTERN, FAKE_LOADER_TIMEOUT, INVALID_EMAIL_ERROR_MESSAGE } from '@/constants';
import { RouteNames } from '@/router';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';

type Step = 'enter-email' | 'link-sent' | 'loading';

const fields = [
  {
    component: TextInput,
    name: 'email' as const,
    placeholder: 'Enter your email',
    type: 'email' as const,
    pattern: EMAIL_PATTERN.source,
    required: true,
  },
];

export default defineComponent({
  components: {
    CardSection,
    CustomForm,
    FormError,
    HMTitle,
    Spinner,
    TextButton,
    TextInput,
  },
  setup() {
    const step = ref<Step>('enter-email');
    const values = ref({
      email: '',
    });

    const { errors, errorMessages, setErrors } = useFormErrors({
      email: {
        [FormErrorType.Valid]: INVALID_EMAIL_ERROR_MESSAGE,
      },
    });

    const users = useUserStore();
    const [forgotPassword, forgotPasswordStatus] = useAsyncFunction(
      users.forgotPassword,
      FAKE_LOADER_TIMEOUT
    );

    const route = useRoute();
    const showLinkExpiredError = !!route.query['expired'];

    return {
      errors,
      errorMessages,
      fields,
      forgotPassword,
      forgotPasswordStatus,
      setErrors,
      showLinkExpiredError,
      step,
      values,
    };
  },
  methods: {
    async submit() {
      try {
        await this.forgotPassword(this.values.email);
        this.setStep('link-sent');
      } catch (e) {}
    },
    setInputValue(value: string, name: (typeof fields)[number]['name']) {
      this.values[name] = value;
    },
    setStep(step: Step) {
      this.step = step;
    },
  },
  computed: {
    currentStep() {
      if (this.forgotPasswordStatus === 'pending') {
        return 'loading';
      }

      return this.step;
    },
    signInRoute() {
      return {
        name: RouteNames.SIGN_IN,
      };
    },
    submitButtonDisabled(): boolean {
      return this.fields.some(({ name }) => !this.values[name].length);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='forgot-password']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.section {
  @include mixins.media(sm) {
    min-height: 519px;

    &.email-sent {
      min-height: auto;
    }
  }
}

.forgot-password {
  display: flex;
  flex-direction: column;

  @include mixins.media(xs, max) {
    width: 100%;
    max-width: 346px;
    margin: 0 auto;
  }

  @include mixins.media(sm) {
    margin: auto 0;

    .email-sent & {
      margin-top: 40px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.text {
  @include typo.text-semi-large;
  margin: 20px 0 40px;
  text-align: center;

  @include mixins.media(xs, max) {
    align-self: center;
    width: 100%;
    max-width: 316px;
  }
}

.cta {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  a {
    width: 38.11%;
    margin-right: 10px;

    button {
      width: 100%;
    }
  }

  button {
    width: 61.89%;
    text-transform: uppercase;
  }
}

.loader {
  @include mixins.media(sm, max) {
    margin-top: 100px;
  }
}

.error {
  margin-top: 20px;
  justify-content: center;
  display: flex;
}
</style>
