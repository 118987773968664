<template>
  <div :class="$style['mileage-verification']">
    <TextTitle :class="[$style.title, 'hide-on-desktop']" size="medium">
      Mileage verification
    </TextTitle>

    <Card v-if="!verificationFailed" :class="$style.card">
      <div :class="[$style['card-title'], 'hide-on-mobile']">Mileage verification</div>

      <CustomForm
        v-if="!nextMileageVerificationAt"
        :class="$style['card-content']"
        :errorMessages="errorMessages"
        :setErrors="setErrors"
        :onSubmit="onSubmit"
      >
        <p>
          Enter the current mileage value of your car with VIN <strong>{vin}</strong> in order to
          check that you are in possession of the car.
        </p>

        <div :class="$style.input">
          <TextInput
            name="mileage-value"
            :placeholder="`Enter the odometer reading in ${mileageUnit}`"
            :value="mileage"
            @update:value="setMileage"
            required
          />
        </div>

        <p>
          You have <strong>{{ attemptsLeft }}</strong> attempts to do it. After that the connection
          will be lost.
        </p>

        <TextButton :class="$style['submit-button']" :disabled="submitButtonDisabled" type="submit">
          {{ verifyMileageStatus === 'pending' ? 'Verifying...' : 'Verify' }}
        </TextButton>
      </CustomForm>

      <p v-if="nextMileageVerificationAt">
        Your mileage value was successfully verified. You will be requested for a new verification
        on {{ nextMileageVerificationAt }}.
      </p>

      <VerificationModal
        :consent="verificationConsent"
        :firstVerification="isFirstVerification"
        :visible="verificationSuccessModalVisible"
        @dismiss="showHideVerificationSuccessModal(false)"
      />
    </Card>

    <PSAActivationError v-if="verificationFailed" />
  </div>
</template>

<script lang="ts">
import { add, format } from 'date-fns';
import { defineComponent, PropType, ref } from 'vue';

import {
  Card,
  CustomForm,
  TextButton,
  TextInput,
  TextTitle,
  useFormErrors,
  VerificationModal,
} from '@/components/base';
import {
  getIsVehicleVerificationFailed,
  getIsVehicleVerificationPending,
  getVehicleVerificationConsent,
} from '@/utils';
import { MAX_VEHICLE_VERIFICATION_ATTEMPTS } from '@/constants';
import { useAsyncFunction } from '@/components/composables';
import { useToastsStore, useUserStore, useVehiclesStore } from '@/store';
import { Vehicle, VehicleVerificationState } from '@/types';

import PSAActivationError from '../common/PSAActivationError.vue';

export default defineComponent({
  components: {
    Card,
    CustomForm,
    PSAActivationError,
    TextButton,
    TextInput,
    TextTitle,
    VerificationModal,
  },
  setup() {
    const isFirstVerification = ref(false);
    const mileage = ref('');
    const verificationSuccessModalVisible = ref(false);

    const toasts = useToastsStore();
    const users = useUserStore();
    const vehicles = useVehiclesStore();

    const { errors, errorMessages, setErrors } = useFormErrors({});

    const [verifyMileage, verifyMileageStatus] = useAsyncFunction(vehicles.verifyMileage);

    return {
      errors,
      errorMessages,
      isFirstVerification,
      mileage,
      setErrors,
      toasts,
      users,
      vehicles,
      verificationSuccessModalVisible,
      verifyMileage,
      verifyMileageStatus,
    };
  },
  props: {
    vehicle: {
      required: true,
      type: Object as PropType<Vehicle>,
    },
  },
  computed: {
    attemptsLeft() {
      return MAX_VEHICLE_VERIFICATION_ATTEMPTS - this.verificationState!.attempts;
    },
    mileageUnit() {
      return !!this.users.user && this.users.user.countryCode.toLocaleLowerCase() === 'gb'
        ? 'miles'
        : 'kilometers';
    },
    nextMileageVerificationAt() {
      return this.verificationState!.verifiedAt
        ? format(add(new Date(this.verificationState!.verifiedAt), { months: 3 }), 'MMMM do, y')
        : undefined;
    },
    submitButtonDisabled() {
      return !this.mileage.length || this.verifyMileageStatus === 'pending';
    },
    verificationConsent() {
      return getVehicleVerificationConsent(this.vehicle as Required<Vehicle>);
    },
    verificationFailed() {
      return getIsVehicleVerificationFailed(this.verificationState);
    },
    verificationState() {
      return this.vehicle.verification as VehicleVerificationState;
    },
  },
  methods: {
    async onSubmit() {
      const {
        mileage: odometer,
        mileageUnit: unit,
        vehicle: { id: vehicleId },
        verificationState: { id },
      } = this;

      await this.verifyMileage({ id, odometer, unit, vehicleId });
    },
    setMileage(mileage: string) {
      this.mileage = mileage;
    },
    showHideVerificationSuccessModal(visible: boolean) {
      this.verificationSuccessModalVisible = visible;
    },
  },
  watch: {
    verificationState(current?: VehicleVerificationState, previous?: VehicleVerificationState) {
      if (current && previous) {
        if (!previous.verifiedAt && current.verifiedAt) {
          this.isFirstVerification = !current.previouslyVerified;
          this.showHideVerificationSuccessModal(true);
        }

        if (
          current.attempts > previous.attempts &&
          current.attempts < MAX_VEHICLE_VERIFICATION_ATTEMPTS &&
          getIsVehicleVerificationPending(current)
        ) {
          this.toasts.showToast({
            id: 'vehicle_verification_failure',
            message: `The mileage value didn’t match. You have ${this.attemptsLeft} more attempts.`,
            type: 'error',
          });
        }
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.mileage-verification {
  display: flex;
  flex-direction: column;
}

.card-title {
  color: vars.$blue;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 19px;
}

.card {
  display: block !important;
  background-color: vars.$gray;

  @include mixins.media(xs, max) {
    padding: 24px 10px 30px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }
}

.card-content {
  max-width: 535px;
}

.input {
  margin: 16px 0;
}

.submit-button {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;

  @include mixins.media(sm) {
    width: 140px;
  }
}

.title {
  margin-bottom: 12px;
}

@include mixins.media(md, max) {
  :global(.hide-on-mobile) {
    display: none;
  }

  .card-content {
    max-width: initial;
    width: 100%;

    > * {
      width: 100%;
    }
  }
}

@include mixins.media(lg) {
  :global(.hide-on-desktop) {
    display: none;
  }
}
</style>
