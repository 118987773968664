import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PermissionsCard = _resolveComponent("PermissionsCard")!
  const _component_ColumnGrid = _resolveComponent("ColumnGrid")!

  return (_openBlock(), _createBlock(_component_ColumnGrid, {
    columnClass: _ctx.$style['permissions-column'],
    items: _ctx.capabilities
  }, {
    default: _withCtx((props) => [
      _createVNode(_component_PermissionsCard, _normalizeProps(_guardReactiveProps(props)), null, 16)
    ]),
    _: 1
  }, 8, ["columnClass", "items"]))
}