<template>
  <component :is="as" :to="to" :class="$style['back-button']">
    <Icon name="arrow-left-alt" />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Icon from '../../atoms/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    to: {
      type: String,
      required: false,
    },
    as: {
      type: String,
      default: 'router-link',
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.back-button {
  width: 44px;
  height: 44px;
  background-color: vars.$gray;
  border-radius: 5px;
  color: vars.$gray-icon;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
