<template>
  <div :class="$style.card"><slot /></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.card {
  display: flex;
  padding: 28px;
  background: vars.$gray;
  border-radius: 12px;
  overflow: hidden;
}
</style>
