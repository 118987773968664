<template>
  <div :class="$style['mobile-menu']">
    <template v-if="isVehicleSelectionOpen">
      <header :class="$style['vehicle-header']">
        <div class="title">
          <BackButton as="button" @click="toggleVehicleSelection" :class="$style.back" />
          <span>My vehicles</span>
        </div>
        <div class="subtitle">
          <strong>{{ vehicles.length }}</strong> connected
        </div>
      </header>

      <simplebar>
        <div :class="$style['car-selector']">
          <CarVin
            v-for="{ id, provider, vin } in sortedVehicles"
            :class="{
              [$style['car-vin']]: true,
              selected: selectedVehicleId === id,
            }"
            :brand="provider.name"
            :vehicleId="id"
            :vin="vin"
            :path="getPath(id)"
            :key="vin"
            @select="$emit('close')"
          />
        </div>
      </simplebar>
    </template>

    <template v-else>
      <header>
        <span>Driver&nbsp;</span>
        <strong>{{ name }}</strong>
      </header>
      <div :class="$style['current-vehicle']">
        <div>
          <Icon :class="$style['active-car-icon']" name="car" />
          <text-button @click="toggleVehicleSelection">
            Switch vehicles
            <Icon :class="$style['icon-arrow-right']" name="arrow-right" />
          </text-button>
        </div>

        <div v-if="selectedVehicle" :class="$style['active-car-title']">
          My <strong>{{ selectedVehicle.provider.name }}</strong>
        </div>
      </div>
      <custom-menu
        :class="$style.links"
        :items="items"
        navigation
        @select="(e) => $emit('select', e)"
      />
    </template>

    <text-button :class="$style['close-button']" variant="primary" @click="() => $emit('close')">
      Done
    </text-button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import Simplebar from 'simplebar-vue';

import { Vehicle, Vehicles } from '@/types';

import BackButton from '../../molecules/back-button/BackButton.vue';
import CarVin from '../../molecules/car-vin/CarVin.vue';
import CustomMenu from '../../atoms/menu/Menu.vue';
import Icon from '../../atoms/icon/Icon.vue';
import { MenuItems } from '../../atoms/menu/types';
import TextButton from '../../atoms/button/Button.vue';

export default defineComponent({
  emits: ['close', 'select'],
  components: {
    BackButton,
    CarVin,
    CustomMenu,
    Icon,
    Simplebar,
    TextButton,
  },
  setup() {
    const isVehicleSelectionOpen = ref(false);

    return {
      isVehicleSelectionOpen,
    };
  },
  props: {
    buildPath: {
      type: Function as PropType<(vin: string) => string>,
    },
    items: {
      default: () => [],
      type: Array as PropType<MenuItems | undefined>,
    },
    name: {
      type: String,
    },
    selectedVehicleId: {
      type: String,
      required: false,
    },
    vehicles: {
      type: Array as PropType<Vehicles>,
      default: () => [],
    },
  },
  computed: {
    selectedVehicle(): Vehicle | undefined {
      if (this.sortedVehicles.length && this.selectedVehicleId) {
        return this.sortedVehicles.find((vehicle) => vehicle.id === this.selectedVehicleId);
      }
      return undefined;
    },
    sortedVehicles(): Vehicle[] {
      return [...(this.vehicles || [])].sort();
    },
  },
  methods: {
    getPath(id: string): string | undefined {
      const { buildPath } = this;
      if (buildPath) {
        return buildPath(id);
      }
    },
    toggleVehicleSelection() {
      this.isVehicleSelectionOpen = !this.isVehicleSelectionOpen;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.mobile-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: vars.$gray-icon;

  @include mixins.media(sm) {
    display: none;
  }

  .car-selector {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  .current-vehicle {
    border: 1px solid #494d52;
    border-radius: 5px;
    margin-top: 50px;
    padding: 20px;

    button {
      border-radius: 5px;
      min-width: 60%;
      justify-content: space-between;
      background: vars.$gray;
      color: #fff;
    }

    > *:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  header {
    text-align: center;
    padding: 20px 15px;
    font-size: 18px;
    color: vars.$gray-2;

    strong {
      color: vars.$gray-3;
    }
  }

  .active-car-icon {
    color: #494d52;
    border: 1px solid #494d52;
    border-radius: 5px;
    width: 44px;
    height: 44px;

    svg {
      width: 26px;
      color: inherit;
    }
  }

  .active-car-title {
    margin-top: 15px;
    font-size: 24px;
    line-height: 28px;
    color: #cdced0;

    strong {
      color: vars.$gray-3;
    }
  }

  .links {
    background: none;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;

    > * {
      margin-bottom: 10px;
      justify-content: flex-start;
    }
  }

  .close-button {
    margin-top: auto;
    align-self: center;
    min-width: 140px;
  }

  .icon-arrow-right {
    svg {
      color: vars.$gray-icon;
    }
  }

  .car-vin {
    border: initial;
  }

  :global(.selected) {
    background-color: vars.$gray;
  }

  .vehicle-header {
    color: #fff;

    .back {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
    }

    :global(.title) {
      font-size: 20px;
      font-weight: 500;
      position: relative;
    }

    :global(.subtitle) {
      color: vars.$gray-2;
      font-size: 14px;
      margin-top: 15px;
    }

    strong {
      color: inherit;
    }
  }

  [data-simplebar='init'] {
    overflow-y: auto;
    height: 100%;
    margin-bottom: 10px;
  }
}
</style>
