import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["disabled", "placeholder", "type", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style['text-input'], _ctx.className])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        [_ctx.$style['wrapper-outer']]: true,
        [_ctx.$style.focused]: _ctx.isFocused,
        [_ctx.$style['has-error']]: _ctx.hasError,
        [_ctx.$style['has-right-component']]: _ctx.hasComponent,
      }),
      onMousedown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.triggerFocus && _ctx.triggerFocus(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style['wrapper-inner'], _ctx.showAltPlaceholder ? _ctx.$style['with-placeholder'] : '']),
        onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerFocus && _ctx.triggerFocus(...args)))
      }, [
        (_ctx.showAltPlaceholder)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.$style['placeholder'])
            }, _toDisplayString(_ctx.placeholder), 3))
          : _createCommentVNode("", true),
        _createElementVNode("input", _mergeProps(_ctx.attrs, {
          disabled: _ctx.disabled,
          placeholder: _ctx.placeholder,
          type: _ctx.type,
          value: _ctx.value,
          ref: "inputRef",
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
        }), null, 16, _hoisted_1)
      ], 34),
      (_ctx.hasComponent)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.$style.right)
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true)
    ], 34),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style.error)
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 2))
}