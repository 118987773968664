<template>
  <div :class="$style['vehicle-data']">
    <TextTitle :class="[$style.title, 'hide-on-desktop']" size="medium"> Shared data </TextTitle>

    <PermissionsGrid :capabilities="capabilities" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { getVehicleSharedCapabilities } from '@/utils';
import { PermissionsGrid, TextTitle } from '@/components/base';
import { Vehicle } from '@/types';

export default defineComponent({
  components: { PermissionsGrid, TextTitle },
  props: {
    vehicle: {
      required: true,
      type: Object as PropType<Vehicle>,
    },
  },
  computed: {
    capabilities() {
      return getVehicleSharedCapabilities(this.vehicle);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;

.vehicle-data {
  display: flex;
  margin-bottom: 28px;

  @include mixins.media(md, max) {
    flex-direction: column;
  }

  .title {
    margin-bottom: 12px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    margin-bottom: 80px;
  }
}

@include mixins.media(lg) {
  :global(.hide-on-desktop) {
    display: none;
  }
}
</style>
