import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HMTitle = _resolveComponent("HMTitle")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CustomForm = _resolveComponent("CustomForm")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_CardSection = _resolveComponent("CardSection")!

  return (_openBlock(), _createBlock(_component_CardSection, {
    class: _normalizeClass([_ctx.$style.section, _ctx.currentStep === 'link-sent' ? _ctx.$style['email-sent'] : '']),
    "aria-label": "Forgot password"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['forgot-password'])
      }, [
        (_ctx.currentStep === 'enter-email')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_HMTitle, null, {
                default: _withCtx(() => [
                  _createTextVNode("Forgot your password?")
                ]),
                _: 1
              }),
              (_ctx.showLinkExpiredError)
                ? (_openBlock(), _createBlock(_component_FormError, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.error)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Your password reset link has expired")
                    ]),
                    _: 1
                  }, 8, ["class"]))
                : _createCommentVNode("", true),
              _createVNode(_component_CustomForm, {
                class: _normalizeClass(_ctx.$style.form),
                errorMessages: _ctx.errorMessages,
                setErrors: _ctx.setErrors,
                onSubmit: _ctx.submit
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style.text)
                  }, " Please enter the email you have signed up with. We will email you with a link to reset your password. ", 2),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, ({ component, name, placeholder, type, ...attrs }) => {
                    return (_openBlock(), _createBlock(_resolveDynamicComponent(component), _mergeProps(attrs, {
                      error: _ctx.errors[name],
                      name: name,
                      placeholder: placeholder,
                      type: type,
                      value: _ctx.values[name],
                      key: name,
                      "onUpdate:value": _ctx.setInputValue
                    }), null, 16, ["error", "name", "placeholder", "type", "value", "onUpdate:value"]))
                  }), 128)),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.cta)
                  }, [
                    _createVNode(_component_router_link, { to: _ctx.signInRoute }, {
                      default: _withCtx(() => [
                        _createVNode(_component_TextButton, { variant: "secondary" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Back")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    _createVNode(_component_TextButton, {
                      type: "submit",
                      disabled: _ctx.submitButtonDisabled
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Send reset link ")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ], 2)
                ]),
                _: 1
              }, 8, ["class", "errorMessages", "setErrors", "onSubmit"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep === 'link-sent')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_HMTitle, null, {
                default: _withCtx(() => [
                  _createTextVNode("Reset link sent!")
                ]),
                _: 1
              }),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.text)
              }, " We have sent you an email with a link to reset your password. If you haven't received it yet, please check your spam folder. If it still hasn't arrived please wait a few minutes and then refresh the page. ", 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentStep === 'loading')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(_ctx.$style.loader)
            }, [
              _createVNode(_component_Spinner)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}