import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ [_ctx.$style.container]: true, [_ctx.$style.overlay]: _ctx.overlay })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.spinner)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['bounce-a'])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['bounce-b'])
      }, null, 2)
    ], 2)
  ], 2))
}