import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['hm-badge'])
  }, [
    _createVNode(_component_Icon, {
      class: _normalizeClass(_ctx.$style.icon),
      name: "hm"
    }, null, 8, ["class"])
  ], 2))
}