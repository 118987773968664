import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), {
    to: _ctx.to,
    class: _normalizeClass(_ctx.$style['back-button'])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, { name: "arrow-left-alt" })
    ]),
    _: 1
  }, 8, ["to", "class"]))
}