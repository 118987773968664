<template>
  <div :class="$style['car-selector-wrapper']">
    <CarSelector
      :class="$style['car-selector']"
      :buildPath="buildPath"
      :vehicles="vehicles"
      :selectedVehicleId="selectedVehicleId"
      @dropdown-visible="toggleScroll"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { CarSelector } from '@/components/base';
import { Vehicles } from '@/types';

export default defineComponent({
  components: {
    CarSelector,
  },
  props: {
    buildPath: {
      type: Function as PropType<(id: string) => string>,
    },
    vehicles: {
      type: Array as PropType<Vehicles>,
      default: () => [],
    },
    selectedVehicleId: {
      type: String,
      required: false,
    },
  },
  methods: {
    toggleScroll(disable: boolean) {
      document.body.classList[disable ? 'add' : 'remove']((this as any).$style['hide-overflow']);
    },
  },
  unmounted() {
    this.toggleScroll(false);
  },
});
</script>

<style lang="scss" module>
@use '~styles/functions' as fn;
@use '~styles/mixins' as mixins;

.car-selector,
.car-selector-wrapper {
  display: none;

  @include mixins.media(sm) {
    display: flex;
  }
}

.car-selector-wrapper {
  flex: 1;

  @include mixins.media(sm) {
    max-width: 530px;

    [role='menu'] {
      max-height: calc(#{fn.vh(100)} - 2 * 20px);

      :global(.simplebar-content) {
        position: relative;

        /* stylelint-disable-next-line max-nesting-depth, selector-max-compound-selectors */
        > div:last-child {
          overflow-x: hidden;
        }
      }
    }
  }
}

.hide-overflow {
  @include mixins.media(xs, max) {
    > div {
      overflow: hidden;
    }
  }

  @include mixins.media(sm) {
    overflow: hidden;
  }
}
</style>
