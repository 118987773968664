<template>
  <Modal :visible="visible" @hide="onCancel">
    <template #title>Forget vehicle</template>

    <template #default>
      <div :class="$style.text">
        <TextBody size="large">
          <strong>
            When the car is forgotten all active links to applications will be removed. Are you sure
            you want to continue?
          </strong>
        </TextBody>
      </div>
    </template>

    <template #footer>
      <TextButton variant="secondary" @click="onCancel">Cancel</TextButton>
      <TextButton variant="danger" @click="onDelete">Forget vehicle</TextButton>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Modal from '../modal/Modal.vue';
import TextBody from '../../atoms/text-body/TextBody.vue';
import TextButton from '../../atoms/button/Button.vue';

export default defineComponent({
  emits: ['hide', 'delete'],
  components: {
    Modal,
    TextBody,
    TextButton,
  },
  setup() {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCancel() {
      this.$emit('hide');
    },
    onDelete() {
      this.$emit('delete');
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.text {
  display: flex;
  flex-direction: column;
}

.text {
  color: vars.$gray-text;

  > :first-child {
    margin-bottom: 20px;
  }

  > :last-child {
    margin-bottom: 10px;
  }
}

@include mixins.media(xs, max) {
  .hide-xs {
    display: none;
  }
}
</style>
