<template>
  <Card :class="{ [$style['vehicle-card']]: true, [$style['revoked']]: vehicleRevoked }">
    <header :class="$style['header']">
      <div>
        <div :class="$style['title']">{{ vehicle.provider.name }}</div>
        <div :class="$style['vin']">
          VIN <strong>{{ vehicle.vin }}</strong>
        </div>
      </div>

      <router-link v-if="!vehicleRevoked" :to="vehicleDetailsRoute" :class="$style['edit-button']">
        <Icon name="pen" />
      </router-link>
    </header>

    <footer :class="$style['footer']">
      <div>
        Shared with <strong>{{ applications }}</strong>
      </div>

      <div v-if="vehicleRevoked" :class="$style['tag']">Revoked</div>
    </footer>
  </Card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Card, Icon } from '@/components/base';
import { RouteNames } from '@/router';
import { Vehicle } from '@/types';

export default defineComponent({
  components: { Card, Icon },
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
  },
  computed: {
    applications() {
      return this.vehicle.consents.map((consent) => consent.application.name).join(', ');
    },
    vehicleDetailsRoute() {
      return {
        name: RouteNames.VEHICLE_DETAILS,
        params: {
          vehicleId: this.vehicle.id,
        },
      };
    },
    vehicleRevoked() {
      return false;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.vehicle-card {
  display: block;
  padding: 22px 30px;

  &.revoked .header {
    color: vars.$gray-darkest;

    .vin {
      color: inherit;
    }

    strong {
      color: inherit;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 24px;
  text-transform: capitalize;
}

.vin {
  color: vars.$gray-lighter;
  font-size: 14px;
  text-transform: uppercase;

  strong {
    color: #fff;
  }
}

.edit-button {
  color: vars.$gray-darkest;
  background-color: vars.$gray-icon;
  border-radius: 6px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: vars.$gray-lighter;
  }

  svg {
    transition: color 150ms;
  }
}

.footer {
  color: #98989e;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.tag {
  display: inline-block;
  color: vars.$gray-darkest;
  font-size: 11px;
  text-transform: uppercase;
  border: 1px solid currentColor;
  border-radius: 5px;
  letter-spacing: 0.05em;
  padding: 3px 5px;
  min-width: 170px;
  text-align: center;
}
</style>
