<template>
  <text-input
    v-bind="$attrs"
    :disabled="disabled"
    :error="error"
    :focused="focused"
    :placeholder="placeholder"
    :value="value"
    :type="currentType"
    @blur="onBlur"
    @focus="onFocus"
    @update:value="onInput"
    ref="textInput"
  >
    <icon v-if="showIcon" :name="iconName" :class="$style.icon" @mousedown="toggleType" />
  </text-input>
</template>

<script lang="ts">
import { ComponentInstance, defineComponent, ref } from 'vue';

import Icon from '../../atoms/icon/Icon.vue';
import TextInput from '../../atoms/text-input/TextInput.vue';

export default defineComponent({
  inheritAttrs: false,
  emits: ['update:value', 'focus', 'blur'],
  components: {
    Icon,
    TextInput,
  },
  setup() {
    const currentType = ref<'password' | 'text'>('password');
    const showIcon = ref(false);

    return {
      currentType,
      showIcon,
    };
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    error: {
      default: '',
      type: String,
    },
    focused: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: '',
      type: String,
    },
    value: {
      default: '',
      type: String,
    },
  },
  methods: {
    onBlur(e: Event) {
      this.showIcon = false;
      this.currentType = 'password';
      this.$emit('blur', e);
    },
    onFocus(e: Event) {
      this.showIcon = true;
      this.$emit('focus', e);
    },
    onInput(value: string, name: string) {
      this.$emit('update:value', value, name);
    },
    toggleType(e: Event) {
      e.preventDefault();
      this.currentType = this.currentType === 'password' ? 'text' : 'password';
    },
    triggerBlur() {
      this.inputRef.blur();
    },
    triggerFocus() {
      this.inputRef.focus();
    },
  },
  computed: {
    iconName() {
      return this.currentType === 'password' ? 'eye' : 'eye-blind';
    },
    inputRef() {
      return (this.$refs.textInput as ComponentInstance<any>).$refs.input as HTMLInputElement;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.icon {
  margin: 0 10px;
  padding: 3px;
  cursor: pointer;

  svg {
    color: vars.$gray-lighter;
  }
}
</style>
