import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationThumbnail = _resolveComponent("ApplicationThumbnail")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), {
    class: _normalizeClass(_ctx.$style['app-list-item'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.header, _ctx.$style.text])
      }, [
        _createVNode(_component_ApplicationThumbnail, {
          class: _normalizeClass(_ctx.$style.thumbnail),
          size: 46,
          appName: _ctx.name,
          src: _ctx.iconUrl
        }, null, 8, ["class", "appName", "src"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.details)
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style.name)
          }, _toDisplayString(_ctx.capitalize(_ctx.name)), 3)
        ], 2)
      ], 2),
      _createVNode(_component_Card, {
        class: _normalizeClass(_ctx.$style.card)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['card-header'])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.$style.text, _ctx.$style['card-header-title']])
            }, "Permissions", 2),
            _createVNode(_component_Icon, { name: "arrow-right" })
          ], 2),
          _createElementVNode("div", null, [
            _createTextVNode(" Your car shares the following information with "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.name), 1),
            _createTextVNode(". ")
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.permissions)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.capabilityPermissions, (permission, index) => {
              return (_openBlock(), _createBlock(_component_Tag, {
                text: permission,
                key: `${permission}_${index}`
              }, null, 8, ["text"]))
            }), 128))
          ], 2),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$style['time-label']),
            title: _ctx.timeLabelText
          }, _toDisplayString(_ctx.timeLabelText), 11, _hoisted_1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["class"]))
}