<template>
  <CardSection :class="$style['not-found']" aria-label="Page not found!">
    <HMTitle heading:class="$style.title">Ooops! Page not found.</HMTitle>

    <p :class="$style.text">Nothing is here (Error 404).</p>

    <router-link :to="dashboardUrl">
      <TextButton>Return home</TextButton>
    </router-link>
  </CardSection>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { CardSection } from '@/components/page';
import { HMTitle, TextButton } from '@/components/base';
import { RouteNames } from '@/router';

export default defineComponent({
  components: {
    CardSection,
    HMTitle,
    TextButton,
  },
  computed: {
    dashboardUrl() {
      return {
        name: RouteNames.DASHBOARD,
      };
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='404']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }

  @include mixins.media(sm) {
    background: url('../../../assets/images/background/not-found.jpg') center center no-repeat;
    background-size: cover;
  }
}

.not-found {
  align-items: center;
  background: vars.$gray-darker;

  @include mixins.media(sm) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .text {
    display: flex;
    margin: 20px auto;
    line-height: 22px;
    text-align: center;

    @include mixins.media(xs, max) {
      max-width: 316px;
      margin-bottom: 40px;
    }
  }

  button {
    width: 200px;
  }

  .title {
    @include typo.text-large;
    white-space: nowrap;

    @include mixins.media(md) {
      @include typo.text-xxlarge;
    }

    @include mixins.media(lg) {
      @include typo.text-ultra-large;
    }
  }
}
</style>
