<template>
  <div :class="$style['toast-container']">
    <div :class="$style.wrapper">
      <SlideFade
        appear
        mode="out-in"
        :enter-active-class="$style['slide-fade-enter-active']"
        :leave-active-class="$style['slide-fade-leave-active']"
        type="bottom"
      >
        <div v-if="currentToast" :class="$style['toast-wrapper']" :key="currentToast.id">
          <Toast :class="$style.toast" :message="currentToast.message" :type="currentToast.type" />
        </div>
      </SlideFade>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';

import { ToastData } from '@/types';

import SlideFade from '../../atoms/transitions/SlideFade.vue';
import Toast from '../../atoms/toast/Toast.vue';

const TOAST_HIDE_TIMEOUT = 5000;

export default defineComponent({
  emits: ['hide:toast'],
  components: {
    SlideFade,
    Toast,
  },
  setup(props, context) {
    const currentToast = ref<ToastData | null>(props.toasts.length ? props.toasts[0] : null);
    const currentToastHideTimeout = ref<number | undefined>(undefined);

    const clearCurrentToast = () => {
      if (currentToast.value) {
        clearTimeout(currentToastHideTimeout.value);
        context.emit('hide:toast', currentToast.value.id);
        currentToast.value = null;
      }
    };

    const hideToastAfterTimeout = () => {
      currentToastHideTimeout.value = window.setTimeout(clearCurrentToast, TOAST_HIDE_TIMEOUT);
    };

    const setToast = (toast: ToastData) => {
      clearTimeout(currentToastHideTimeout.value);
      currentToast.value = toast;
      hideToastAfterTimeout();
    };

    onMounted(() => {
      if (currentToast.value) {
        hideToastAfterTimeout();
      }
    });

    return {
      currentToast,
      setToast,
    };
  },
  props: {
    toasts: {
      type: Array as PropType<ToastData[]>,
      default: () => [],
    },
  },
  watch: {
    toasts(toasts: ToastData[]) {
      if (!this.currentToast) {
        const [nextToast] = toasts;

        if (nextToast) {
          this.setToast(nextToast);
        }
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.toast-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: vars.$z-index-toasts;
}

.wrapper {
  position: relative;
  pointer-events: none;

  @include mixins.media(sm, max) {
    margin: 0 10px 10px;
  }

  @include mixins.media(md) {
    margin-bottom: 30px;
  }
}

.toast-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.toast {
  pointer-events: all;
}

.slide-fade-enter-active {
  transition: transform ease-in-out 0.2s, opacity ease-in 0.2s;
}

.slide-fade-leave-active {
  transition: transform ease-out 0.3s, opacity ease-out 0.3s;
}
</style>
