<template>
  <Modal :visible="visible" @hide="onCancel">
    <template #title> Are you sure you want to remove your car? </template>
    If you continue, all of the applications connected to your car will also be removed.
    <template #footer>
      <TextButton variant="secondary" @click="onCancel" data-role="cancel">
        No, go back
      </TextButton>
      <TextButton variant="danger" @click="onUnlink" data-role="confirm">
        Yes, I’m sure
      </TextButton>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Modal from '../modal/Modal.vue';
import TextButton from '../../atoms/button/Button.vue';

export default defineComponent({
  emits: ['hide', 'unlink'],
  components: { Modal, TextButton },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCancel() {
      this.$emit('hide');
    },
    onUnlink() {
      this.$emit('unlink');
    },
  },
});
</script>
