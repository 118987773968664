import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_button = _resolveComponent("text-button")!

  return (_openBlock(), _createBlock(_component_text_button, {
    as: _ctx.as,
    disabled: _ctx.disabled,
    class: _normalizeClass({
      [_ctx.$style['tab-button']]: true,
      [_ctx.$style['is-active']]: _ctx.isActive,
    }),
    type: "button",
    variant: "clear"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["as", "disabled", "class"]))
}