<template>
  <ColumnGrid :columnClass="$style['apps-column']" :items="consents">
    <template
      v-slot="{
        key,
        index,
        application: { iconUrl, name },
        applicationVersion: { id: applicationId, scopes },
        latestTelematicsUsageTimestamp: lastFetchedAt,
      }"
    >
      <AppListItem
        as="router-link"
        :to="getApplicationRoute(applicationId)"
        :name="name"
        :iconUrl="iconUrl"
        :lastFetchedAt="lastFetchedAt"
        :capabilityPermissions="getPermissions(scopes)"
        :key="key"
        :index="index"
      />
    </template>
  </ColumnGrid>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { AppListItem, ColumnGrid } from '@/components/base';
import { Consents } from '@/types';
import { RouteNames } from '@/router';

export default defineComponent({
  components: {
    AppListItem,
    ColumnGrid,
  },
  props: {
    consents: {
      type: Array as PropType<Consents>,
      default: () => [],
    },
    vehicleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    getApplicationRoute(applicationId: string) {
      return {
        name: RouteNames.APP,
        params: {
          vehicleId: this.vehicleId,
          app: applicationId,
        },
      };
    },
    getPermissions(scopes: string[]) {
      return [...new Set(scopes.map((scope) => scope.split('.')[0].replace('_', ' ')))];
    },
  },
});
</script>

<style lang="scss" module>
.apps-column {
  gap: 38px 0;
}
</style>
