<template>
  <div>
    <div>
      <div>
        <PersonalInformation :name="users.user!.fullName" :email="users.user!.email" />
      </div>
      <div>
        <ChangePassword />
      </div>
      <div>
        <DeleteAccount />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useUserStore } from '@/store';

import ChangePassword from './ChangePassword.vue';
import DeleteAccount from './DeleteAccount.vue';
import PersonalInformation from './PersonalInformation.vue';

export default defineComponent({
  components: {
    ChangePassword,
    DeleteAccount,
    PersonalInformation,
  },
  setup() {
    const users = useUserStore();

    return {
      users,
    };
  },
});
</script>
