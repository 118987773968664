import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastContainer = _resolveComponent("ToastContainer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$style['page-container'], _ctx.margins ? _ctx.$style.margins : ''])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("span", null, [
      _createVNode(_component_ToastContainer, {
        toasts: _ctx.toasts.toasts,
        "onHide:toast": _ctx.toasts.hideToast
      }, null, 8, ["toasts", "onHide:toast"])
    ])
  ], 2))
}