import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_TextLabel = _resolveComponent("TextLabel")!
  const _component_ActivationAlert = _resolveComponent("ActivationAlert")!
  const _component_TabButton = _resolveComponent("TabButton")!
  const _component_VehicleDetails = _resolveComponent("VehicleDetails")!
  const _component_VehicleData = _resolveComponent("VehicleData")!
  const _component_MileageVerification = _resolveComponent("MileageVerification")!
  const _component_ForgetVehicleModal = _resolveComponent("ForgetVehicleModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.row, _ctx.$style['header-row']])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style['page-title']]),
        size: "large"
      }, [
        _createTextVNode(" My "),
        _createElementVNode("strong", null, _toDisplayString(_ctx.vehicle.provider.name), 1)
      ], 2),
      false
        ? (_openBlock(), _createBlock(_component_TextButton, {
            key: 0,
            class: _normalizeClass(_ctx.$style['hide-on-mobile']),
            variant: "danger",
            onClick: _ctx.toggleForgetVehicleModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Forget vehicle ")
            ]),
            _: 1
          }, 8, ["class", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.row)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.left)
      }, [
        (_ctx.activationStatus.connectedOn)
          ? (_openBlock(), _createBlock(_component_TextLabel, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.activationStatus.connectedOn), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.activationStatus.isPending)
          ? (_openBlock(), _createBlock(_component_TextLabel, {
              key: 1,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Connection is pending ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.activationStatus.isRejected)
          ? (_openBlock(), _createBlock(_component_TextLabel, {
              key: 2,
              type: "error"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Connection is denied ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.isVehicleActivationPending)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.$style.row, _ctx.$style.alerts])
        }, [
          _createVNode(_component_ActivationAlert, { provider: "vehicle.provider.type" })
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.row, _ctx.$style['content-row']])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.left, _ctx.$style['hide-on-mobile']])
      }, [
        _createVNode(_component_TabButton, {
          as: "router-link",
          to: _ctx.getRoute('vehicle-details'),
          isActive: _ctx.isRoute('vehicle-details')
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Details ")
          ]),
          _: 1
        }, 8, ["to", "isActive"]),
        _createVNode(_component_TabButton, {
          as: "router-link",
          to: _ctx.getRoute('vehicle-data'),
          isActive: _ctx.isRoute('vehicle-data')
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Shared data ")
          ]),
          _: 1
        }, 8, ["to", "isActive"]),
        (_ctx.hasVerificationState)
          ? (_openBlock(), _createBlock(_component_TabButton, {
              key: 0,
              as: "router-link",
              class: _normalizeClass({
            [_ctx.$style['with-notification']]: _ctx.isVehicleVerificationPending,
          }),
              to: _ctx.getRoute('vehicle-verification'),
              isActive: _ctx.isRoute('vehicle-verification')
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Mileage verification "),
                (_ctx.isVehicleVerificationPending)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.icons.alert
                    }, null, 8, _hoisted_1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["class", "to", "isActive"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.right)
      }, [
        (_ctx.isRoute('vehicle-details') || _ctx.isMobileBreakpoint)
          ? (_openBlock(), _createBlock(_component_VehicleDetails, {
              key: 0,
              vehicle: _ctx.vehicle
            }, null, 8, ["vehicle"]))
          : _createCommentVNode("", true),
        (_ctx.isRoute('vehicle-data') || _ctx.isMobileBreakpoint)
          ? (_openBlock(), _createBlock(_component_VehicleData, {
              key: 1,
              vehicle: _ctx.vehicle
            }, null, 8, ["vehicle"]))
          : _createCommentVNode("", true),
        ((_ctx.isRoute('vehicle-verification') || _ctx.isMobileBreakpoint) && _ctx.hasVerificationState)
          ? (_openBlock(), _createBlock(_component_MileageVerification, {
              key: 2,
              class: _normalizeClass({
            [_ctx.$style.first]: _ctx.isVehicleVerificationPending || _ctx.isVehicleVerificationFailed,
          }),
              vehicle: _ctx.vehicle
            }, null, 8, ["class", "vehicle"]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    (_ctx.forgetVehicleModalVisible)
      ? (_openBlock(), _createBlock(_component_ForgetVehicleModal, {
          key: 1,
          onDelete: _ctx.toggleForgetVehicleModal,
          onHide: _ctx.toggleForgetVehicleModal,
          visible: ""
        }, null, 8, ["onDelete", "onHide"]))
      : _createCommentVNode("", true)
  ]))
}