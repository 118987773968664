<template>
  <div :class="$style['column-grid']" v-if="!!$slots.default">
    <div
      v-for="(items, i) in columns"
      :class="[$style['column-grid-column'], columnClass]"
      :key="i"
    >
      <slot
        v-for="(item, j) in items"
        v-bind="item"
        :index="getItemIndex(columnCount, i, j)"
        :key="j"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import { BREAKPOINT_LG } from '@/constants';
import { useEvents } from '@/components/composables';

export default defineComponent({
  setup() {
    const getColumnCount = () => {
      return window.innerWidth < BREAKPOINT_LG ? 1 : 2;
    };
    const getItemIndex = (columns: number, column: number, itemIndex: number) => {
      return columns > 1 ? column + 2 * itemIndex : itemIndex;
    };

    const columnCount = ref(getColumnCount());
    const updateColumnCount = () => {
      columnCount.value = getColumnCount();
    };

    useEvents(['resize', 'orientationchange'])(updateColumnCount);

    return {
      columnCount,
      getColumnCount,
      getItemIndex,
      updateColumnCount,
    };
  },
  props: {
    columnClass: {
      type: String,
      required: false,
    },
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  computed: {
    columns(): any[][] {
      return this.items
        .reduce<any[][]>(
          ([colA, colB], item, index) => {
            if (this.columnCount === 1 || index % 2 === 0) {
              return [colA.concat([item]), colB];
            }
            return [colA, colB.concat([item])];
          },
          [[], []]
        )
        .filter((column) => column.length);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.column-grid {
  display: grid;
  @each $breakpoint in map-keys(vars.$breakpoints) {
    $columns: if(null == index(lg xl, $breakpoint), 1, 2);
    $gap-horizontal: if($breakpoint == xs, grid.$xs-gutter, grid.$gutter);

    @include mixins.media($breakpoint) {
      grid-template-columns: repeat($columns, 1fr);
      gap: 0 $gap-horizontal;
    }
  }
}

.column-grid-column {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;

  @include mixins.media(xs) {
    gap: grid.$xs-gutter 0;
  }

  @include mixins.media(sm) {
    gap: grid.$gutter 0;
  }
}
</style>
