import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    visible: _ctx.visible,
    onHide: _ctx.onCancel
  }, {
    title: _withCtx(() => [
      _createTextVNode(" Are you sure you want to remove your car? ")
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_TextButton, {
        variant: "secondary",
        onClick: _ctx.onCancel,
        "data-role": "cancel"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" No, go back ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_TextButton, {
        variant: "danger",
        onClick: _ctx.onUnlink,
        "data-role": "confirm"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Yes, I’m sure ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createTextVNode(" If you continue, all of the applications connected to your car will also be removed. ")
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}