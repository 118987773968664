<template>
  <div :class="$style['account-dropdown']" ref="container">
    <div :class="$style.trigger">
      <span>Driver&nbsp;</span>
      <strong>{{ name }}</strong>
      <div :class="$style['button-wrapper']">
        <button @click="toggleDropdown">
          <Icon :class="$style['user-icon']" name="user" />
        </button>
        <custom-menu
          v-if="dropdownVisible"
          :items="items"
          @select="onSelect"
          :class="$style.dropdown"
          navigation
        />
      </div>
    </div>

    <MobileMenu
      v-if="dropdownVisible"
      :buildPath="buildPath"
      :name="name"
      :items="items"
      :vehicles="vehicles"
      :selected-vehicle-id="selectedVehicleId"
      @select="onSelect"
      @close="toggleDropdown"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

import { BREAKPOINT_SM } from '@/constants';
import { Vehicles } from '@/types';

import CustomMenu from '../../atoms/menu/Menu.vue';
import Icon from '../../atoms/icon/Icon.vue';
import { MenuItems, MenuItemSelectEvent } from '../../atoms/menu/types';
import MobileMenu from '../mobile-menu/MobileMenu.vue';

export default defineComponent({
  emits: ['signout'],
  components: { Icon, CustomMenu, MobileMenu },
  setup() {
    const container = ref(null);
    const dropdownVisible = ref(false);

    onClickOutside(container, () => {
      if (dropdownVisible.value && window.innerWidth >= BREAKPOINT_SM) {
        dropdownVisible.value = false;
      }
    });

    return {
      container,
      dropdownVisible,
    };
  },
  props: {
    accountPath: {
      type: String,
    },
    buildPath: {
      type: Function as PropType<(vin: string) => string>,
    },
    name: {
      type: String,
    },
    selectedVehicleId: {
      type: String,
      required: false,
    },
    vehicles: {
      type: Array as PropType<Vehicles>,
      default: () => [],
    },
  },
  computed: {
    items(): MenuItems {
      return [
        {
          label: 'My account',
          ...(this.accountPath ? { as: 'RouterLink', asProps: { to: this.accountPath } } : {}),
        },
        {
          label: 'Sign out',
        },
      ];
    },
  },
  methods: {
    onSelect(e: MenuItemSelectEvent) {
      const { itemIndex } = e;
      if (itemIndex) {
        this.$emit('signout');
      }
      this.toggleDropdown();
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.account-dropdown {
  position: relative;
  display: flex;

  .dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: vars.$z-index-backdrop-above;
    display: none;

    @include mixins.media(sm) {
      display: block;
      top: 100%;
      left: 50%;
      width: 180px;
      transform: translate(-50%, 20px);

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        width: 24px;
        height: 28px;
        transform: translate(-50%, -50%);
        background: url('../../../../assets/images/icons/rounded-triangle.svg') center no-repeat;
        z-index: -1;
        background-size: contain;
        content: '';
      }
    }
  }

  &[aria-expanded='true'] {
    .avatar {
      z-index: vars.$z-index-backdrop-above;
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    color: #a6acb2;

    button {
      font-size: 0;
    }

    strong {
      color: #fff;
      font-weight: 700;
    }
  }

  .hm-icon {
    color: vars.$gray-darker;
  }

  .user-icon {
    margin-left: 10px;
    color: vars.$gray-darker;
  }

  .button-wrapper {
    position: relative;
  }
}
</style>
