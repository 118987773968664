<template>
  <button :class="$style['close-button']">
    <icon name="cross" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Icon from '../../atoms/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: transparent;

  svg {
    color: vars.$gray;
  }

  &:hover,
  &:active {
    svg {
      color: vars.$gray-lighter;
    }
  }
}
</style>
