<template>
  <PageContainer>
    <Container :class="$style.container" fluid>
      <PageHeader />
      <Container as="main" :class="$style['vehicle-layout']" aria-role="main">
        <Router-view vehicle="{vehicle}" />

        <div :class="$style['footer-wrapper']">
          <PageFooter />
        </div>
      </Container>
    </Container>
  </PageContainer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Container } from '@/components/base';
import { PageContainer, Footer as PageFooter, Header as PageHeader } from '@/components/page';

export default defineComponent({
  components: {
    Container,
    PageHeader,
    PageFooter,
    PageContainer,
  },
});
</script>

<style lang="scss" module>
@use '~styles/grid' as grid;
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.container {
  @include mixins.media(sm) {
    padding-top: vars.$header-height + vars.$header-margin;
  }
}

.container,
.vehicle-layout {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.vehicle-layout {
  @include mixins.media(xs, max) {
    @include grid.grid-column-xs();
  }

  @include mixins.media(sm) {
    @include grid.grid-column();
  }
}

.footer-wrapper {
  display: flex;
  margin-top: auto;
  padding: grid.$xs-grid-gap 0 unquote('max(#{grid.$xs-grid-gap}, var(--safe-area-inset-bottom))');

  @include mixins.media(sm) {
    padding: 50px 0 20px;

    footer {
      letter-spacing: -0.02em;
    }
  }
}
</style>
