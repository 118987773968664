import 'simplebar-vue/dist/simplebar.min.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import { camelizeKeys } from './utils';
import { router } from './router';
import { User, Vehicles } from './types';
import { useUserStore, useVehiclesStore } from './store';

import './styles/index.scss';

// Plugins
const pinia = createPinia();

// App
const app = createApp(App);

// Sentry
Sentry.init({
  app,
  dsn: window.__DRIVER_DATA__.sentry.dsn,
  enabled: process.env.NODE_ENV === 'production',
  environment: window.__DRIVER_DATA__.sentry.environment,
  integrations: [Sentry.browserTracingIntegration({ router })],
  release: window.__DRIVER_DATA__.release,
  tracesSampleRate: 1,
});

// Register plugins
app.use(pinia);
app.use(router);

// Hydrate store
useUserStore().setUser(camelizeKeys(window.__DRIVER_DATA__.user) as User);
useVehiclesStore().setVehicles(camelizeKeys(window.__DRIVER_DATA__.vehicles) as Vehicles);

app.mount('#app');
