import { NavigationGuard } from 'vue-router';

import { useUserStore } from '@/store';

import { RouteNames } from './types';

export const beforeEachGuard: NavigationGuard = async (to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    const users = useUserStore();

    if (!users.user) {
      if (to.name === RouteNames.DASHBOARD) {
        next({ name: RouteNames.SIGN_IN });
        return;
      }

      next({
        name: RouteNames.SIGN_IN,
        query: { redirect: to.fullPath },
      });

      return;
    }
  }

  next();
};
