<template>
  <text-button
    :as="as"
    :disabled="disabled"
    :class="{
      [$style['tab-button']]: true,
      [$style['is-active']]: isActive,
    }"
    type="button"
    variant="clear"
  >
    <slot />
  </text-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import TextButton from '../../atoms/button/Button.vue';

export default defineComponent({
  components: {
    TextButton,
  },
  props: {
    as: {
      type: String,
      default: 'button',
    },
    disabled: {
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.tab-button {
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  justify-content: flex-start;
  font-size: 13px;
  letter-spacing: 0.06em;

  &:hover {
    background-color: vars.$gray;
  }

  &.is-active {
    background-color: vars.$gray;

    &:hover {
      background-color: vars.$gray-darker;
    }
  }
}
</style>
