<template>
  <form v-bind="$attrs" @submit.prevent="onSubmit" ref="form" novalidate>
    <slot />
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { noop } from 'lodash-es';

import { FormErrorMessages, FormErrors, FormErrorType } from './types';

export default defineComponent({
  inheritAttrs: false,
  emits: ['submit'],
  props: {
    errorMessages: {
      type: Object as PropType<FormErrorMessages>,
      default: () => ({}),
    },
    setErrors: {
      type: Function as PropType<(errors: FormErrors) => void>,
      default: noop,
    },
  },
  data: () => ({
    errors: {} as FormErrors,
  }),
  computed: {
    formFields() {
      return Array.from((this.$refs.form as HTMLFormElement).querySelectorAll('input'));
    },
    supportsValidation() {
      return !!HTMLFormElement.prototype.checkValidity;
    },
  },
  methods: {
    clearError(e: Event) {
      const target = e.target as HTMLInputElement;
      /* istanbul ignore else  */
      if (this.errors[target.name]) {
        delete this.errors[target.name];
      }
      this.setErrors(this.errors);
    },
    onSubmit(e: Event) {
      if (this.validate()) {
        this.$emit('submit', e);
      } else {
        this.setErrors(this.errors);
      }
    },
    toggleListeners(on: boolean) {
      this.formFields.forEach((field) =>
        field[on ? 'addEventListener' : 'removeEventListener']('focus', this.clearError)
      );
    },
    validate() {
      if (!this.supportsValidation) {
        return true;
      }

      for (const field of this.formFields) {
        const fieldErrorMessages = this.errorMessages[field.name];

        if (fieldErrorMessages) {
          for (const errorType of Object.keys(fieldErrorMessages) as FormErrorType[]) {
            const isInvalid =
              errorType === FormErrorType.Valid
                ? !field.validity[errorType]
                : field.validity[errorType];

            if (isInvalid) {
              const initialMessage = fieldErrorMessages[errorType];
              const message =
                initialMessage === true
                  ? field.validationMessage // Native HTML5 validation message
                  : typeof initialMessage === 'function'
                  ? initialMessage(field.value) // Produce message
                  : initialMessage; // Use custom message

              if (message) {
                this.errors[field.name] = message;
              }
              break;
            }
          }
        }
      }

      return !Object.keys(this.errors).length;
    },
  },
  mounted() {
    this.toggleListeners(true);
  },
  unmounted() {
    this.toggleListeners(false);
  },
});
</script>
