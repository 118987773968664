import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["aria-expanded"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarVin = _resolveComponent("CarVin")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_simplebar = _resolveComponent("simplebar")!
  const _component_Backdrop = _resolveComponent("Backdrop")!
  const _component_Fade = _resolveComponent("Fade")!
  const _component_UnlinkVehicleModal = _resolveComponent("UnlinkVehicleModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['car-selector']),
    "aria-expanded": _ctx.dropdownVisible ? 'true' : 'false'
  }, [
    (_ctx.selectedVehicle)
      ? (_openBlock(), _createBlock(_component_CarVin, {
          key: 0,
          class: _normalizeClass(_ctx.$style.selector),
          brand: _ctx.selectedVehicle?.provider.name,
          vehicleId: _ctx.selectedVehicle?.id,
          vin: _ctx.selectedVehicle?.vin,
          selector: "",
          onClick: _ctx.toggleDropdown
        }, null, 8, ["class", "brand", "vehicleId", "vin", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.dropdownVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.dropdown),
          role: "menu"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['dropdown-header'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['dropdown-title'])
            }, [
              _createTextVNode(" My vehicles "),
              _createElementVNode("span", null, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.sortedVehicles.length), 1),
                _createTextVNode(" connected ")
              ])
            ], 2),
            _createVNode(_component_Icon, {
              name: "cross",
              class: _normalizeClass(_ctx.$style.caret),
              onClick: _ctx.toggleDropdown
            }, null, 8, ["class", "onClick"])
          ], 2),
          _createVNode(_component_simplebar, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style['dropdown-inner'])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['vehicle-list'])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedVehicles, ({ id, provider, vin }) => {
                    return (_openBlock(), _createBlock(_component_CarVin, {
                      class: _normalizeClass({
                [_ctx.$style['car-vin']]: true,
                selected: _ctx.selectedVehicle && _ctx.selectedVehicle.id === id,
              }),
                      brand: provider.name,
                      vehicleId: id,
                      vin: vin,
                      path: _ctx.getPath(id),
                      key: vin,
                      onSelect: _ctx.onSelectVehicle
                    }, null, 8, ["class", "brand", "vehicleId", "vin", "path", "onSelect"]))
                  }), 128))
                ], 2)
              ], 2)
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.dropdownVisible)
      ? (_openBlock(), _createBlock(_component_Fade, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_Backdrop, { onClick: _ctx.toggleDropdown }, null, 8, ["onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.modalVisible)
      ? (_openBlock(), _createBlock(_component_UnlinkVehicleModal, {
          key: 3,
          class: _normalizeClass(_ctx.$style.modal),
          onCancel: _ctx.toggleModal,
          onHide: _ctx.toggleModal,
          onUnlink: _ctx.onUnlinkVehicle,
          visible: ""
        }, null, 8, ["class", "onCancel", "onHide", "onUnlink"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}