<template>
  <div :class="[$style.toast, $style[type]]">{{ message }}</div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { ToastData } from '@/types';

export default defineComponent({
  props: {
    message: String,
    type: {
      type: String as PropType<ToastData['type']>,
      default: 'success',
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.toast {
  @include typo.text-medium-alt;
  display: inline-flex;
  padding: 16px 20px;
  color: #fff;
  border-radius: 6px;

  @include mixins.media(sm, max) {
    width: 100%;
  }

  @include mixins.media(md) {
    max-width: 500px;
    text-align: center;
  }

  &.error {
    background: vars.$red;
  }

  &.success {
    background: vars.$green;
  }
}
</style>
