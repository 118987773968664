export const BREAKPOINT_LG = 992;
export const BREAKPOINT_SM = 576;

export const DRIVER_SUPPORT_EMAIL = 'driver-support@high-mobility.com';

export const FAKE_LOADER_TIMEOUT = 1000;

export const EMAIL_PATTERN = /^.+@.+\..+$/;
export const EMAIL_EXISTS_ERROR_MESSAGE = 'A user with that email already exists.';

export const EXPIRED_PASSWORD_RESET_LINK_ERROR_MESSAGE = 'Your password reset link has expired';
export const INEXISTENT_EMAIL_ERROR_MESSAGE = 'The email is not registered on Driver';
export const INVALID_EMAIL_ERROR_MESSAGE = 'Please provide a valid email address';
export const INVALID_CREDENTIALS_ERROR_MESSAGE = 'The email and/or password are invalid';

export const MAX_VEHICLE_VERIFICATION_ATTEMPTS = 3;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
export const PASSWORD_PATTERN_LOWERCASE = /^(?=.*[a-z]).*$/;
export const PASSWORD_PATTERN_NO_SPACES = /^(?!.*\s).*$/;
export const PASSWORD_PATTERN_NUMBERS = /^(?=.*\d).*$/;
export const PASSWORD_PATTERN_UPPERCASE = /^(?=.*[A-Z]).*$/;

export const PASSWORD_MATCH_ERROR_MESSAGE = 'Passwords need to match';
export const PASSWORD_PATTERN_ERROR_MESSAGE_LOWERCASE =
  'Include at least one lowercase letter (a-z)';
export const PASSWORD_PATTERN_ERROR_MESSAGE_NO_SPACES =
  'Password should not contain spaces or line-breaks';
export const PASSWORD_PATTERN_ERROR_MESSAGE_NUMBER = 'Include at least one number (0-9)';
export const PASSWORD_PATTERN_ERROR_MESSAGE_UPPERCASE =
  'Include at least one uppercase letter (A-Z)';
export const PASSWORD_REUSED_ERROR_MESSAGE = 'Old and new password cannot be the same';
export const PASSWORD_TOO_SHORT_ERROR_MESSAGE = `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`;
