export enum RouteNames {
  HOME = 'Home',
  SIGN_IN = 'sign-in',
  VEHICLE_DETAILS = 'vehicle-details',
  VEHICLE_DATA = 'vehicle-data',
  VEHICLE_VERIFICATION = 'vehicle-verification',
  DASHBOARD = 'dashboard',
  APPS = 'apps',
  APP = 'app',
  ACCOUNT_INFO = 'account-info',
  ACCOUNT_VEHICLES = 'account-vehicles',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  COMPLETE_ACCOUNT = 'complete-account',
  CONFIRM_EMAIL = 'confirm-email',
  NOT_FOUND = '404',
}
