<template>
  <div :class="$style['hm-badge']">
    <Icon :class="$style.icon" name="hm" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Icon from '../../atoms/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
});
</script>

<style lang="scss" module>
@use '~styles/variables' as vars;

.hm-badge {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon svg {
    color: vars.$gray-darker;
  }
}
</style>
