<template>
  <div v-if="show" :class="$style['dashboard-info']">
    <div :class="$style.message">
      The applications and your selected car appear here. Applications connected to your car display
      the permissions they have access to.
    </div>
    <Icon name="cross" :class="$style.close" @click="onAccept" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { Icon } from '@/components/base';
import { isNotificationSeen, Notification, setNotificationAsSeen } from '@/utils';

export default defineComponent({
  components: {
    Icon,
  },
  setup() {
    const show = ref(!isNotificationSeen(Notification.DASHBOARD_FIRST_USE));

    const onAccept = () => {
      setNotificationAsSeen(Notification.DASHBOARD_FIRST_USE);
      show.value = false;
    };

    return {
      show,
      onAccept,
    };
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.dashboard-info {
  background: vars.$gray;
  border-radius: 10px;
  position: relative;
  align-self: flex-start;
}

.message {
  @include typo.text-medium;
  color: vars.$gray-2;
  padding: 11px 46px 14px 20px;
}

.close {
  color: vars.$gray-icon;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: color 250ms ease;

  @include mixins.media(sm) {
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &:hover {
    color: vars.$gray-lightest;
  }
}
</style>
