<template>
  <footer :class="$style.footer" aria-role="contentinfo">
    <div>
      <p>Driver by <a href="https://high-mobility.com" target="_blank"> HIGH MOBILITY </a></p>
    </div>
    <div :class="$style.legal">
      <a href="https://high-mobility.com/legal/imprint" target="_blank"> Imprint </a>
      <a href="https://high-mobility.com/legal/end-customer-car-data-terms" target="_blank">
        Terms
      </a>
      <a href="https://high-mobility.com/legal/end-customer-car-data-privacy" target="_blank">
        Privacy Policy
      </a>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.footer {
  @include typo.text-medium;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  color: vars.$gray-lighter;

  @include mixins.media(xs, max) {
    @include typo.text-small(17px);
  }

  a {
    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #fff;
      font-weight: bold;
    }
  }

  > div {
    display: flex;

    &:first-child {
      margin-right: auto;
    }
  }
}

.legal {
  a {
    margin-right: 8px;

    @include mixins.media(sm) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
