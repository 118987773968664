import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_SlideFade = _resolveComponent("SlideFade")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['toast-container'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.wrapper)
    }, [
      _createVNode(_component_SlideFade, {
        appear: "",
        mode: "out-in",
        "enter-active-class": _ctx.$style['slide-fade-enter-active'],
        "leave-active-class": _ctx.$style['slide-fade-leave-active'],
        type: "bottom"
      }, {
        default: _withCtx(() => [
          (_ctx.currentToast)
            ? (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['toast-wrapper']),
                key: _ctx.currentToast.id
              }, [
                _createVNode(_component_Toast, {
                  class: _normalizeClass(_ctx.$style.toast),
                  message: _ctx.currentToast.message,
                  type: _ctx.currentToast.type
                }, null, 8, ["class", "message", "type"])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["enter-active-class", "leave-active-class"])
    ], 2)
  ], 2))
}