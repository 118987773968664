<template>
  <div :class="$style.apps">
    <ActivationAlert v-if="isVehicleActivationPending" :provider="provider.type" />

    <PSAVerificationAlert
      v-if="verification && isVehicleVerificationPending"
      :vehicleId="vehicle.id"
      :isFirstVerification="!verification.previouslyVerified"
    />

    <PSAActivationError v-if="isVehicleVerificationFailed" />

    <DashboardInfo />

    <div :class="$style['apps-list']">
      <AppsGrid
        v-if="consents && consents.length > 0"
        :consents="consents"
        :vehicleId="vehicle.id"
      />

      <NoData v-else title="Nothing here">
        There are currently no apps connected to this car. Applications will appear here once you
        have linked them via your Driver account.
      </NoData>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import {
  getIsVehicleActivationPending,
  getIsVehicleVerificationFailed,
  getIsVehicleVerificationPending,
} from '@/utils';
import { useVehiclesStore } from '@/store';
import { Vehicle } from '@/types';

import { ActivationAlert, PSAActivationError, PSAVerificationAlert } from '../common';

import AppsGrid from './AppsGrid.vue';
import DashboardInfo from './DashboardInfo.vue';
import NoData from './NoData.vue';

export default defineComponent({
  components: {
    ActivationAlert,
    AppsGrid,
    DashboardInfo,
    NoData,
    PSAActivationError,
    PSAVerificationAlert,
  },
  setup() {
    const route = useRoute();
    const vehicles = useVehiclesStore();

    return {
      route,
      vehicles,
    };
  },
  computed: {
    consents(): Vehicle['consents'] {
      return this.vehicle.consents;
    },
    isVehicleActivationPending() {
      return getIsVehicleActivationPending(this.vehicle);
    },
    isVehicleVerificationPending() {
      return getIsVehicleVerificationPending(this.vehicle.verification);
    },
    isVehicleVerificationFailed() {
      return getIsVehicleVerificationFailed(this.vehicle.verification);
    },
    provider() {
      return this.vehicle.provider;
    },
    vehicle(): Vehicle {
      return this.vehicles.vehicles.find(
        (vehicle) => vehicle.id === (this.route.params.vehicleId as string)
      ) as Vehicle;
    },
    verification(): Vehicle['verification'] {
      return this.vehicle.verification;
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;

.apps {
  display: flex;
  flex-direction: column;
}

.apps-list {
  display: grid;
  margin: 30px 0 35px;

  @include mixins.media(sm) {
    margin: 48px 0 15px;
  }
}
</style>
