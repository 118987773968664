import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextTitle = _resolveComponent("TextTitle")!
  const _component_PermissionsGrid = _resolveComponent("PermissionsGrid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.permissions)
  }, [
    _createVNode(_component_TextTitle, {
      class: _normalizeClass([_ctx.$style.title, 'hide-on-desktop']),
      size: "medium"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Shared data ")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_PermissionsGrid, { capabilities: _ctx.capabilities }, null, 8, ["capabilities"])
  ], 2))
}