<template>
  <component
    :is="path ? 'router-link' : 'div'"
    :class="[$style['car-vin'], selector ? $style.selector : $style['list-item']]"
    v-bind="path ? { to: path } : {}"
    @click="onSelect"
  >
    <div :class="$style['details-wrapper']">
      <Icon name="car" />
      <div :class="$style.details">
        <span :class="$style.brand">{{ brand }}</span>
        <span :class="$style.vin">
          VIN <span>{{ vin }}</span>
        </span>
      </div>
    </div>

    <Icon v-if="selector" name="arrow-right" />
    <router-link v-else :to="`/dashboard/${vehicleId}`" :class="$style['select-label']">
      <Icon name="pen" />
    </router-link>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Icon from '../../atoms/icon/Icon.vue';

export default defineComponent({
  emits: ['click', 'select'],
  components: {
    Icon,
  },
  props: {
    brand: {
      type: String,
    },
    vehicleId: {
      type: String,
    },
    vin: {
      type: String,
    },
    path: {
      type: String,
    },
    selector: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelect(e: MouseEvent) {
      if (!this.selector) {
        this.$emit('select', this.vin);
      }
      this.$emit('click', e);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.car-vin {
  display: flex;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  border: 1px solid #494d52;
  border-radius: 6px;
  cursor: pointer;

  :global(.icon-car) {
    svg {
      width: 25px;
      height: auto;
    }
  }
}

.details-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-left: 7px;
  padding-right: 7px;

  .brand {
    @include mixins.text-ellipsis;
    @include typo.title-small;
    color: vars.$gray-3;
    text-align: center;

    @include mixins.media(md) {
      @include typo.title-medium;
    }
  }
}

.vin {
  @include mixins.text-ellipsis;
  @include typo.text-medium;
  color: vars.$gray-lighter;
  text-align: center;

  span {
    font-weight: bold;
    color: #fff;
  }
}

.list-item {
  padding: 14px;
  transition: background-color 150ms;

  &:hover,
  &:active {
    background-color: vars.$gray;
  }

  :global(.icon-car),
  :global(.icon-pen) {
    background-color: #676c73;
    width: 60px;
    height: 60px;
    border-radius: 5px;

    svg {
      color: vars.$gray-darkest;
    }
  }

  .select-label {
    @include typo.text-medium-alt;
    color: vars.$gray-darkest;

    &:hover {
      color: vars.$gray-lighter;
    }

    svg {
      color: inherit;
      transition: color 150ms, fill 150ms;
    }
  }
}

.selector {
  padding: 20px 20px 20px 11px;
  transition: background-color 150ms, border-color 150ms;

  :global(.icon-car) {
    width: 44px;
    height: 44px;
    background-color: #494d52;
    border-radius: 5px;

    svg {
      color: vars.$gray-icon;
    }
  }

  :global(.icon-arrow-right) {
    margin-left: 10px;

    svg {
      color: vars.$gray-darker;
      transform: rotate(90deg);
    }
  }

  &:active,
  &:hover {
    background: vars.$gray;
    border-color: transparent;
  }
}
</style>
