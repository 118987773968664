import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!!_ctx.$slots.default)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['column-grid'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (items, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([_ctx.$style['column-grid-column'], _ctx.columnClass]),
            key: i
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, j) => {
              return _renderSlot(_ctx.$slots, "default", _mergeProps(item, {
                index: _ctx.getItemIndex(_ctx.columnCount, i, j),
                key: j
              }))
            }), 128))
          ], 2))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}