<template>
  <Alert>Vehicle activation is in progress. It might take up to 24 hs.</Alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Alert } from '@/components/base';

export default defineComponent({
  components: {
    Alert,
  },
});
</script>
