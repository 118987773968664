import axios from 'axios';
import { defineStore } from 'pinia';

import { camelizeKeys } from '@/utils';
import { Vehicle, Vehicles, VehicleVerificationState } from '@/types';

import { useToastsStore } from './toasts';

export interface VehiclesState {
  vehicles: Vehicles;
}

interface VerifyMileagePayload {
  id: string;
  odometer: string;
  unit: 'miles' | 'kilometers';
  vehicleId: string;
}

export const useVehiclesStore = defineStore('vehicles', {
  state: (): VehiclesState => {
    return {
      vehicles: [],
    };
  },
  actions: {
    async revokeConsent(consentId: string, vehicleId: string) {
      const toasts = useToastsStore();

      try {
        await axios({
          method: 'delete',
          url: `/api/consents/${consentId}`,
        });

        const vehicles = this.vehicles.reduce<Vehicles>(
          (allVehicles, vehicle) => [
            ...allVehicles,
            vehicle.id === vehicleId
              ? {
                  ...vehicle,
                  consents: vehicle.consents.filter(({ id }) => id !== consentId),
                }
              : vehicle,
          ],
          []
        );
        this.setVehicles(vehicles);

        toasts.showToast({
          id: 'revoke_consent_success',
          message: 'The application has been revoked',
          type: 'error',
        });
      } catch (e) {
        toasts.showToast({
          id: 'revoke_consent_failure',
          message: (e as any).response.data.message || 'Unknown error',
          type: 'error',
        });
      }
    },
    async verifyMileage({ id, odometer, unit, vehicleId }: VerifyMileagePayload) {
      const toasts = useToastsStore();

      try {
        const response = await axios({
          method: 'post',
          url: `/api/vehicles/${vehicleId}/vehicle_verification`,
          data: {
            id,
            odometer: {
              value: odometer,
              unit,
            },
          },
        });

        const verification = camelizeKeys(response.data) as VehicleVerificationState;
        const vehicles = this.vehicles.reduce<Vehicles>(
          (allVehicles, vehicle) => [
            ...allVehicles,
            vehicle.id === vehicleId
              ? {
                  ...vehicle,
                  verification: {
                    ...vehicle.verification,
                    ...verification,
                  },
                }
              : vehicle,
          ],
          []
        );
        this.setVehicles(vehicles);
      } catch (e) {
        toasts.showToast({
          id: 'vehicle_verification_failure',
          message: (e as any).response.data.message || 'Unknown error',
          type: 'error',
        });
      }
    },
    setVehicles(vehicles: Vehicles) {
      this.vehicles = [...vehicles];
    },
  },
  getters: {
    getVehicle({ vehicles }) {
      return (id: Vehicle['id']) => vehicles.find((vehicle) => vehicle.id === id);
    },
    getVehicles({ vehicles }) {
      return [...vehicles].sort((a, b) => a.provider.name.localeCompare(b.provider.name));
    },
  },
});
