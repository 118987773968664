<template>
  <svg width="24" height="24" viewBox="0 0 24 24" color="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 12.279C5.875 7.54072 13.5 0.907113 21 12.279C18 16.4909 10.9412 22.7124 3 12.279Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <circle cx="12" cy="12" r="3" fill="currentColor" />
  </svg>
</template>
