import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HMTitle = _resolveComponent("HMTitle")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CardSection = _resolveComponent("CardSection")!

  return (_openBlock(), _createBlock(_component_CardSection, {
    class: _normalizeClass(_ctx.$style['not-found']),
    "aria-label": "Page not found!"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HMTitle, { "heading:class": "$style.title" }, {
        default: _withCtx(() => [
          _createTextVNode("Ooops! Page not found.")
        ]),
        _: 1
      }),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.$style.text)
      }, "Nothing is here (Error 404).", 2),
      _createVNode(_component_router_link, { to: _ctx.dashboardUrl }, {
        default: _withCtx(() => [
          _createVNode(_component_TextButton, null, {
            default: _withCtx(() => [
              _createTextVNode("Return home")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _: 1
  }, 8, ["class"]))
}