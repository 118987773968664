import { RouteLocationNormalized } from 'vue-router';

import { RouteNames } from '@/router';
import { useVehiclesStore } from '@/store';

export function beforeEnterVehicleGuard(requiresConsent = false) {
  return function ({ params }: RouteLocationNormalized) {
    const vehicles = useVehiclesStore();
    const vehicleId = params.vehicleId as string | undefined;

    const vehicle = vehicles.vehicles.find((vehicle) => vehicle.id === vehicleId);

    if (!vehicle) {
      return {
        name: RouteNames.DASHBOARD,
        replace: true,
      };
    }

    if (requiresConsent) {
      const consent = vehicle.consents.find(
        (consent) => consent.applicationVersion.id === (params.app as string)
      );

      if (!consent) {
        return {
          name: RouteNames.APPS,
          params: {
            vehicleId,
          },
          replace: true,
        };
      }
    }

    return true;
  };
}
