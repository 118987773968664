<template>
  <svg width="44" height="44" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="5" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8528 15.693C23.1487 15.693 24.1993 14.6424 24.1993 13.3465C24.1993 12.0506 23.1487 11 21.8528 11C20.5568 11 19.5063 12.0506 19.5063 13.3465C19.5063 14.6424 20.5568 15.693 21.8528 15.693ZM24.4339 24.9288V25.0457L25.0881 33.194C25.1398 33.8377 24.6571 34.4052 24.0129 34.4613C23.3674 34.5176 22.7984 34.0426 22.7418 33.398L22.0874 25.2793C22.0874 25.1503 21.9824 25.0457 21.8528 25.0457C21.7232 25.0457 21.6181 25.1503 21.6181 25.2793L20.9462 33.398C20.8897 34.0415 20.3193 34.5174 19.6751 34.4613C19.0296 34.4051 18.5491 33.8388 18.6021 33.194L19.2716 25.0457V24.9288V20.3723C19.2716 19.8561 18.8514 19.4376 18.333 19.4376H13.641C12.9925 19.4376 12.4667 18.9132 12.4667 18.2693C12.4667 17.624 12.9933 17.1009 13.641 17.1009H30.0645C30.7131 17.1009 31.2388 17.6253 31.2388 18.2693C31.2388 18.9145 30.7122 19.4376 30.0645 19.4376H25.3725C24.8542 19.4376 24.4339 19.8561 24.4339 20.3723V24.9288Z"
      fill="#676C73"
    />
    <path
      d="M33.4008 10.4863L33.3663 10.452C33.1172 10.2049 32.7139 10.2048 32.4653 10.452L31.5499 11.3609C31.5496 11.3612 31.5493 11.3613 31.5491 11.3616L29.823 13.0759L28.1095 11.3743C27.8607 11.1271 27.4572 11.1271 27.2084 11.3743L27.1739 11.4084C26.9251 11.6556 26.9251 12.0562 27.1739 12.3032L29.3674 14.4816C29.5743 14.6871 29.8876 14.7211 30.1305 14.585C30.1884 14.5557 30.2429 14.5171 30.2913 14.4689L32.4849 12.2907C32.4852 12.2903 32.4857 12.2898 32.4861 12.2894L33.4008 11.381C33.6495 11.134 33.6494 10.7333 33.4008 10.4863Z"
      fill="#676C73"
    />
  </svg>
</template>
