<template>
  <PSAActivationAlert v-if="isPSA" />
  <BMWActivationAlert v-else />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { OEMProviderType } from '@/types';

import BMWActivationAlert from './BMWActivationAlert.vue';
import PSAActivationAlert from './PSAActivationAlert.vue';

export default defineComponent({
  components: { BMWActivationAlert, PSAActivationAlert },
  props: {
    provider: {
      type: String as PropType<OEMProviderType>,
      required: true,
    },
  },
  computed: {
    isPSA() {
      return this.provider !== OEMProviderType.BMW;
    },
  },
});
</script>
