import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_HMBadge = _resolveComponent("HMBadge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CarSelectorWrapper = _resolveComponent("CarSelectorWrapper")!
  const _component_AccountDropdown = _resolveComponent("AccountDropdown")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass({
      [_ctx.$style.background]: _ctx.hasBackground,
      [_ctx.$style.header]: true,
    }),
    "aria-role": "banner"
  }, [
    _createVNode(_component_Container, {
      class: _normalizeClass(_ctx.$style['header-container'])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.route.meta?.backButton)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['back-button-wrapper'])
              }, [
                _createVNode(_component_BackButton, {
                  to: _ctx.route.meta.backButton.getLink(_ctx.route)
                }, null, 8, ["to"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.route.meta.backButton.label), 1)
              ], 2))
            : (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: { name: _ctx.dashboardRoute },
                class: _normalizeClass(_ctx.$style.badge)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_HMBadge)
                ]),
                _: 1
              }, 8, ["to", "class"]))
        ]),
        (_ctx.route.meta.carSelector !== false)
          ? (_openBlock(), _createBlock(_component_CarSelectorWrapper, {
              key: 0,
              buildPath: (vehicleId) => `/dashboard/${vehicleId}/apps`,
              "selected-vehicle-id": _ctx.selectedVehicleId,
              vehicles: _ctx.vehicles.vehicles
            }, null, 8, ["buildPath", "selected-vehicle-id", "vehicles"]))
          : _createCommentVNode("", true),
        _createVNode(_component_AccountDropdown, {
          buildPath: (vehicleId) => `/dashboard/${vehicleId}/apps`,
          accountPath: "/account",
          class: _normalizeClass(_ctx.$style.avatar),
          name: _ctx.users.user ? _ctx.users.user.fullName : '',
          "selected-vehicle-id": _ctx.selectedVehicleId,
          vehicles: _ctx.vehicles.vehicles,
          onDropdownVisible: _ctx.toggleScroll,
          onSignout: _ctx.signOut
        }, null, 8, ["buildPath", "class", "name", "selected-vehicle-id", "vehicles", "onDropdownVisible", "onSignout"])
      ]),
      _: 1
    }, 8, ["class"])
  ], 2))
}