<template>
  <Modal :class="$style.modal" :visible="visible" @hide="onDismiss">
    <div
      :class="{
        [$style['verification-modal']]: true,
        [$style['first-verification']]: firstVerification,
      }"
    >
      <TextTitle :class="$style.title" size="small"> Verification succeeded! </TextTitle>

      <div v-if="firstVerification">
        <div :class="[$style.image, $style.icons]">
          <ApplicationThumbnail
            :appName="consent.application.name"
            :size="80"
            :src="consent.application.iconUrl"
          />
          <img :src="icons.arrow" alt="" />
          <Icon :class="$style['car-icon']" name="car" />
        </div>

        <div>Your car is now connected to {{ consent.application.name }}</div>
      </div>

      <div v-if="!firstVerification">
        <div :class="[$style.image, $style['checkmark-wrapper']]">
          <img :src="icons.speedometer" alt="" />
        </div>
        <div>Your mileage verification is updated</div>
      </div>

      <div :class="$style.actions">
        <TextButton variant="dark" @click="onDismiss"> Ok, got it! </TextButton>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Consent } from '@/types';

import ApplicationThumbnail from '../../atoms/application-thumbnail/ApplicationThumbnail.vue';
import Icon from '../../atoms/icon/Icon.vue';
import Modal from '../modal/Modal.vue';
import TextButton from '../../atoms/button/Button.vue';
import TextTitle from '../../atoms/title/Title.vue';

import ArrowsImage from '@/assets/images/icons/ico-arrows.svg';
import SpeedometerImage from '@/assets/images/icons/speedometer.svg';

export default defineComponent({
  emits: ['dismiss'],
  components: {
    ApplicationThumbnail,
    Icon,
    Modal,
    TextButton,
    TextTitle,
  },
  setup() {
    const icons = {
      arrow: ArrowsImage,
      speedometer: SpeedometerImage,
    };

    return {
      icons,
    };
  },
  props: {
    consent: {
      type: Object as PropType<Consent>,
      required: true,
    },
    firstVerification: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onDismiss() {
      this.$emit('dismiss');
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.verification-modal {
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  width: 100%;
  text-align: center;

  .title {
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  .image {
    margin-top: 50px;
    margin-bottom: 35px;

    &.checkmark-wrapper {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        width: 40px;
        height: 40px;
        background: url('../../../../assets/images/icons/ico-check-circle-green-full.svg') center
          no-repeat;
        background-size: contain;
      }
    }

    &.icons {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        margin: 0 13px;
      }
    }
  }

  .app-icon {
    display: flex;
  }

  .car-icon {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: vars.$gray;
    border-radius: 12px;

    svg {
      color: vars.$gray-lighter;
    }
  }

  .actions {
    text-align: center;
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
}

.verification-modal:not(.first-verification) {
  .image {
    margin-top: 62px;
  }
}

.modal {
  [role='dialog'] {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    @include mixins.media(xs, max) {
      max-width: 356px;
    }
  }
}
</style>
