import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", _mergeProps(_ctx.$attrs, {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
    ref: "form",
    novalidate: ""
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}