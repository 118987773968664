<template>
  <div :class="$style['personal-information']">
    <Card :class="$style.card">
      <Spinner v-if="updateProfileStatus === 'pending'" overlay />

      <TextTitle size="small" :class="$style.title"> Personal information </TextTitle>

      <CustomForm
        :class="$style.form"
        :errorMessages="errorMessages"
        :setErrors="setErrors"
        @submit="submit"
      >
        <component
          v-for="({ component, name, placeholder, type, ...attrs }, index) in fields"
          v-bind="attrs"
          :is="component"
          :class="{ [$style.input]: applyItemClass(index) }"
          :error="errors[name]"
          :name="name"
          :placeholder="placeholder"
          :type="type"
          :value="values[name]"
          :key="name"
          @update:value="setInputValue"
        />

        <TextButton :class="$style.button" :disabled="submitButtonDisabled" type="submit">
          Save changes
        </TextButton>
      </CustomForm>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import {
  Card,
  CustomForm,
  FormErrorType,
  Spinner,
  TextButton,
  TextInput,
  TextTitle,
  useFormErrors,
} from '@/components/base';
import { EMAIL_PATTERN, FAKE_LOADER_TIMEOUT, INVALID_EMAIL_ERROR_MESSAGE } from '@/constants';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';

const fields = [
  {
    component: TextInput,
    name: 'name' as const,
    type: 'text' as const,
    placeholder: 'Your name',
  },
  {
    component: TextInput,
    name: 'email' as const,
    placeholder: 'Your email',
    type: 'email' as const,
    pattern: EMAIL_PATTERN.source,
    required: true,
  },
];

export default defineComponent({
  components: {
    Card,
    CustomForm,
    Spinner,
    TextButton,
    TextInput,
    TextTitle,
  },
  setup(props) {
    const { errors, errorMessages, setErrors } = useFormErrors({
      email: {
        [FormErrorType.Valid]: INVALID_EMAIL_ERROR_MESSAGE,
      },
    });

    const values = ref({
      name: props.name,
      email: props.email,
    });

    const users = useUserStore();
    const [updateProfile, updateProfileStatus] = useAsyncFunction(
      users.updateProfile,
      FAKE_LOADER_TIMEOUT
    );

    return {
      errors,
      errorMessages,
      fields,
      setErrors,
      updateProfile,
      updateProfileStatus,
      values,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    applyItemClass(index: number) {
      return index < this.fields.length - 1;
    },
    async submit() {
      await this.updateProfile(this.values.name, this.values.email);
    },
    setInputValue(value: string, name: (typeof fields)[number]['name']) {
      this.values[name] = value;
    },
  },
  computed: {
    submitButtonDisabled(): boolean {
      return this.fields.some(({ name }) => !this.values[name].length);
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/variables' as vars;

.personal-information {
  display: flex;
  flex-direction: column;

  .title {
    color: vars.$blue;
    margin-bottom: 20px;
  }
}

.card {
  position: relative;
  flex-direction: column;
  padding-bottom: 30px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;

  @include mixins.media(sm) {
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: 10px;

  @include mixins.media(sm) {
    margin-top: 20px;
  }
}

.button {
  align-self: flex-start;
  margin-top: 28px;

  @include mixins.media(xs, max) {
    margin-top: 20px;
  }
}
</style>
