<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7056 2.32227L21.5518 6.16851L6.93608 20.7842L1.93596 21.9381L3.08983 16.938L17.7056 2.32227Z"
      fill="currentColor"
    />
    <rect
      width="5.43941"
      height="2.17576"
      transform="matrix(0.707103 0.707111 -0.707103 0.707111 20.0137 0.013916)"
      fill="currentColor"
    />
  </svg>
</template>
