<template>
  <svg width="18" height="18" viewBox="0 0 18 18" color="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 3.375L12.375 9"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.75 14.625L12.375 9"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
