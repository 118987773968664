<template>
  <div>
    <CardSection :class="[$style.section, $style[step]]" aria-label="Complete Account">
      <Spinner v-if="step === 'loading'" />

      <div v-if="step === 'enter-data'" :class="$style['complete-account']">
        <HMTitle>Complete your driver registration</HMTitle>

        <CustomForm
          :class="$style.form"
          :errorMessages="errorMessages"
          :setErrors="setErrors"
          @submit="submit"
        >
          <p :class="$style.text">
            You previously signed up using <br />
            <strong>{{ email }}</strong
            >. <br />
            Please complete your name and add a password
          </p>

          <component
            v-for="({ component, name, placeholder, type, ...attrs }, index) in fields"
            v-bind="attrs"
            :is="component"
            :class="{ [$style.input]: applyItemClass(index) }"
            :error="errors[name]"
            :name="name"
            :placeholder="placeholder"
            :type="type"
            :value="values[name]"
            :key="name"
            @update:value="setInputValue"
          />

          <TextButton
            :class="$style.button"
            :disabled="submitButtonDisabled"
            variant="secondary"
            type="submit"
          >
            Complete registration
          </TextButton>

          <div :class="$style['hide-on-desktop']">
            <ContactRow />
          </div>
        </CustomForm>
      </div>

      <div v-if="step === 'link-expired'" :class="$style['complete-account']">
        <HMTitle>The link has expired</HMTitle>
        <p :class="$style.text">
          Request a new link to your email in order to complete the registration
        </p>
        <TextButton :class="$style.button" variant="primary" @click="resetToken">
          Send a new link
        </TextButton>
      </div>

      <div v-if="step === 'reset-link-sent'" :class="$style['complete-account']">
        <HMTitle>Email sent</HMTitle>

        <p :class="$style.text">
          We have sent you an e-mail to <strong>{{ email }}</strong> to complete your registration
          on Driver
        </p>
      </div>
    </CardSection>

    <div :class="$style['hide-on-mobile']">
      <ContactRow />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { CardSection } from '@/components/page';
import {
  CustomForm,
  FormErrorType,
  HMTitle,
  PasswordInput,
  Spinner,
  TextButton,
  TextInput,
  useFormErrors,
} from '@/components/base';
import { FAKE_LOADER_TIMEOUT, PASSWORD_MIN_LENGTH, PASSWORD_PATTERN } from '@/constants';
import { getQueryParam, validatePassword } from '@/utils';
import { RouteNames } from '@/router';
import { useAsyncFunction } from '@/components/composables';
import { useUserStore } from '@/store';

import ContactRow from './ContactRow.vue';

type CompleteAccountStep = 'enter-data' | 'link-expired' | 'reset-link-sent' | 'loading';

const fields = [
  {
    component: TextInput,
    name: 'name' as const,
    type: 'text' as const,
    placeholder: 'Enter your full name',
    required: true,
  },
  {
    autocomplete: 'new-password',
    component: PasswordInput,
    name: 'password' as const,
    placeholder: 'Enter a password, at least 8 characters',
    type: 'password',
    minlength: PASSWORD_MIN_LENGTH,
    pattern: PASSWORD_PATTERN.source,
  },
];

export default defineComponent({
  components: {
    CardSection,
    ContactRow,
    CustomForm,
    HMTitle,
    PasswordInput,
    Spinner,
    TextButton,
    TextInput,
  },
  setup() {
    const { activationEmail: email, verifiedName: name } = window.__DRIVER_DATA__;

    const { errors, errorMessages, setErrors } = useFormErrors({
      password: {
        [FormErrorType.Valid]: validatePassword,
      },
    });

    const step = ref<CompleteAccountStep>(email ? 'enter-data' : 'link-expired');
    const values = ref({
      name: name || '',
      password: '',
    });

    const route = useRoute();
    const router = useRouter();
    const token = getQueryParam(route, 'token');
    const users = useUserStore();

    const [activateUser] = useAsyncFunction(users.activateUser, FAKE_LOADER_TIMEOUT);
    const [resetActivationToken] = useAsyncFunction(
      users.resetActivationToken,
      FAKE_LOADER_TIMEOUT
    );

    return {
      activateUser,
      email,
      errors,
      errorMessages,
      fields,
      resetActivationToken,
      router,
      setErrors,
      step,
      token,
      users,
      values,
    };
  },
  computed: {
    submitButtonDisabled(): boolean {
      return this.fields.some(({ name }) => !this.values[name].length);
    },
  },
  methods: {
    applyItemClass(index: number) {
      return index < this.fields.length - 1;
    },
    setInputValue(value: string, name: (typeof fields)[number]['name']) {
      this.values[name] = value;
    },
    async resetToken() {
      try {
        this.step = 'loading';
        await this.resetActivationToken(this.token || '', this.email || '');
        this.step = 'reset-link-sent';
      } catch (e) {
        this.step = 'link-expired';
      }
    },
    async submit() {
      try {
        this.step = 'loading';

        await this.activateUser(this.values.name, this.values.password, this.token || '');
        await this.users.logIn(this.email || '', this.values.password);

        this.router.push({
          name: RouteNames.DASHBOARD,
        });
      } catch (e) {
        this.step = 'link-expired';
      }
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/mixins' as mixins;
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

:global([data-page='complete-account']) body {
  @include mixins.media(xs, max) {
    background-color: vars.$gray-darker;
  }
}

.complete-account {
  display: flex;
  flex-direction: column;
}

.section {
  min-height: 530px;
}

.text {
  @include typo.text-semi-large;
  margin: 20px 0 40px;
  text-align: center;

  @include mixins.media(xs, max) {
    align-self: center;
    width: 100%;
    max-width: 316px;
  }
}

.enter-data {
  //
}

.link-expired {
  .button {
    margin-top: 0;
  }
}

.reset-link-sent {
  //
}

.loading {
  //
}

.card {
  flex-direction: column;
  padding-bottom: 30px;

  @include mixins.media(xs, max) {
    padding: 20px 10px 30px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;

  @include mixins.media(sm) {
    margin-bottom: 20px;
  }
}

.form-error {
  margin-top: 10px;

  @include mixins.media(sm) {
    margin-top: 20px;
  }
}

.button {
  margin-top: 28px;
  margin-bottom: 40px;
  align-self: center;
  text-transform: uppercase;

  @include mixins.media(xs, max) {
    margin-top: 40px;
  }
}

.hide-on-mobile {
  @include mixins.media(xs, max) {
    display: none;
  }
}

.hide-on-desktop {
  @include mixins.media(sm) {
    display: none;
  }
}
</style>
