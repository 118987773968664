<template>
  <transition
    :enter-active-class="enterActiveClass || $style['enter-active']"
    :leave-active-class="leaveActiveClass || $style['leave-active']"
    :enter-from-class="enterFromClass || $style[`enter-${type}`]"
    :enter-to-class="enterToClass || $style['enter-to']"
    :leave-from-class="leaveFromClass || $style['leave']"
    :leave-to-class="leaveToClass || $style[`leave-to-${type}`]"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
    @before-enter="beforeEnter"
    @before-leave="beforeLeave"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

type TransitionType = 'top' | 'left' | 'bottom' | 'right';

export default defineComponent({
  props: {
    enterActiveClass: {
      type: String,
    },
    leaveActiveClass: {
      type: String,
    },
    enterFromClass: {
      type: String,
    },
    enterToClass: {
      type: String,
    },
    leaveFromClass: {
      type: String,
    },
    leaveToClass: {
      type: String,
    },
    hideOverflow: {
      default: false,
    },
    type: {
      type: String as PropType<TransitionType>,
      default: 'right',
    },
  },
  methods: {
    afterEnter(el: Element) {
      this.toggleOverflowVisible(true);
      this.$emit('after-enter', el);
    },
    afterLeave(el: Element) {
      this.toggleOverflowVisible(true);
      this.$emit('after-leave', el);
    },
    beforeEnter(el: Element) {
      this.toggleOverflowVisible(false);
      this.$emit('before-enter', el);
    },
    beforeLeave(el: Element) {
      this.toggleOverflowVisible(false);
      this.$emit('before-leave', el);
    },
    toggleOverflowVisible(visible: boolean) {
      if (this.hideOverflow) {
        document.body.classList[visible ? 'remove' : 'add']('no-scroll');
      }
    },
  },
});
</script>

<style lang="scss" module>
.enter-active,
.leave-active {
  transition: transform ease-in 0.3s, opacity ease-in 0.3s;
}

.enter-top,
.leave-to-top {
  transform: translateY(-100%);
  opacity: 0;
}

.enter-right,
.leave-to-right {
  transform: translateX(100%);
  opacity: 0;
}

.enter-left,
.leave-to-left {
  transform: translateX(-100%);
  opacity: 0;
}

.enter-bottom,
.leave-to-bottom {
  transform: translateY(100%);
  opacity: 0;
}

.enter-to,
.leave {
  transform: translateX(0) translateY(0);
  opacity: 1;
}
</style>
