import { RouteLocationNormalized, type RouteRecordRaw } from 'vue-router';

import {
  Account,
  AppDetails,
  Apps,
  CompleteAccount,
  ConfirmEmail,
  Dashboard,
  ForgotPassword,
  NotFound,
  ResetPassword,
  SignIn,
  Vehicle,
  Vehicles,
} from '@/components/views';
import { AccountLayout, CardLayout, MainLayout, VehicleLayout } from '@/components/layout';
import { BREAKPOINT_LG } from '@/constants';

import { beforeEnterVehicleGuard } from './beforeEnterVehicleGuard';
import { RouteNames } from './types';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RouteNames.HOME,
    meta: { auth: true },
    redirect: { name: RouteNames.DASHBOARD },
  },
  {
    path: '/dashboard/:vehicleId',
    component: VehicleLayout,
    meta: { auth: true },
    beforeEnter: [beforeEnterVehicleGuard()],
    children: [
      {
        path: '',
        name: RouteNames.VEHICLE_DETAILS,
        component: Vehicle,
        meta: {
          carSelector: false,
          backButton: {
            getLink: (): string => '/dashboard',
            label: 'Home',
          },
        },
      },
      {
        path: 'shared-data',
        name: RouteNames.VEHICLE_DATA,
        component: Vehicle,
        meta: {
          carSelector: false,
          backButton: {
            getLink: (): string => '/dashboard',
            label: 'Home',
          },
        },
      },
      {
        path: 'vehicle-verification',
        name: RouteNames.VEHICLE_VERIFICATION,
        component: Vehicle,
        meta: {
          carSelector: false,
          backButton: {
            getLink: (): string => '/dashboard',
            label: 'Home',
          },
        },
      },
    ],
  },
  {
    path: '/dashboard',
    name: RouteNames.DASHBOARD,
    component: Dashboard,
    meta: {
      layout: MainLayout,
      auth: true,
    },
    children: [
      {
        path: ':vehicleId/apps',
        name: RouteNames.APPS,
        component: Apps,
        meta: {
          layout: MainLayout,
        },
        beforeEnter: [beforeEnterVehicleGuard()],
      },
      {
        path: ':vehicleId/apps/:app',
        name: RouteNames.APP,
        component: AppDetails,
        meta: {
          carSelector: false,
          backButton: {
            getLink: (route: RouteLocationNormalized): string =>
              `/${RouteNames.DASHBOARD}/${route.params.vehicleId}/${RouteNames.APPS}`,
            label: 'Home',
          },
          layout: MainLayout,
        },
        beforeEnter: [beforeEnterVehicleGuard(true)],
      },
    ],
  },
  {
    path: '/account',
    component: AccountLayout,
    meta: {
      carSelector: false,
      auth: true,
    },
    children: [
      {
        path: '',
        name: RouteNames.ACCOUNT_INFO,
        component: Account,
        meta: {
          backButton: {
            getLink: (): string => `/dashboard`,
            label: 'Home',
          },
        },
      },
      {
        path: 'vehicles',
        name: RouteNames.ACCOUNT_VEHICLES,
        component: Vehicles,
        meta: {
          backButton: {
            // On mobile, "back" goes to the main account page. On desktop, it goes to the dashboard.
            // This could be handled better in the future with media queries, but then we'd need the
            // ability to render both buttons at the same time.
            getLink: (): string => (window.innerWidth < BREAKPOINT_LG ? '/account' : `/dashboard`),
            label: 'Home',
          },
        },
      },
    ],
  },
  {
    path: '/sign-in',
    name: RouteNames.SIGN_IN,
    component: SignIn,
    meta: {
      layout: CardLayout,
      staticFooter: true,
    },
  },
  {
    path: '/forgot-password',
    name: RouteNames.FORGOT_PASSWORD,
    component: ForgotPassword,
    meta: {
      layout: CardLayout,
    },
  },
  {
    path: '/reset-password',
    name: RouteNames.RESET_PASSWORD,
    component: ResetPassword,
    meta: {
      layout: CardLayout,
    },
  },
  {
    path: '/complete-account',
    name: RouteNames.COMPLETE_ACCOUNT,
    component: CompleteAccount,
    meta: {
      layout: CardLayout,
    },
  },
  {
    path: '/confirm-email',
    name: RouteNames.CONFIRM_EMAIL,
    component: ConfirmEmail,
    meta: {
      layout: CardLayout,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: RouteNames.NOT_FOUND,
    component: NotFound,
    meta: {
      layout: CardLayout,
    },
  },
];
