<template>
  <div
    :class="{
      [$style['form-error']]: true,
      [$style['center']]: center,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" module>
@use '~styles/typography' as typo;
@use '~styles/variables' as vars;

.form-error {
  @include typo.text-medium();
  display: inline-block;
  align-items: center;
  width: 100%;
  padding: 16px 20px;
  background: vars.$red;
  border-radius: 6px;

  &.center {
    text-align: center;
  }
}
</style>
