import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoData = _resolveComponent("NoData")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.dashboard)
  }, [
    (!_ctx.vehicles.vehicles.length)
      ? (_openBlock(), _createBlock(_component_NoData, {
          key: 0,
          title: "There are currently no cars in your account"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" You will be able to add your car once you have connected it to any third party application. ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 2))
}